import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  UrlTree,
} from '@angular/router';
import { Observable, of, tap } from 'rxjs';

import { PermissionService } from '@shared/rbac';
import { AuthService, NotifierService } from '@services/core';
import { MSG } from '@shared/consts';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {
  userProfile: any;

  constructor(
    private permissionService: PermissionService,
    private authService: AuthService,
    private notifierService: NotifierService,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.canActivateThis(next);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivateThis(next);
  }

  private canActivateThis(next: ActivatedRouteSnapshot): Observable<boolean> {
    if (this.authService.isAuthenticated) {
      if (
        next.hasOwnProperty('data') &&
        next.data.hasOwnProperty('permissionRequired')
      ) {
        return this.permissionService
          .hasPermission(next.data.permissionRequired)
          .pipe(
            tap((hp) => {
              if (!hp) {
                this.notifierService.error(
                  MSG.ERROR_HAS_NOT_PERMISSION_FOR_THIS_PAGE,
                );
              }
            }),
          );
      } else {
        // Si no tiene la propiedad permissionRequired entonces se deja pasar
        // Es obligatorio definir un permiso si se quiere proteger la ruta
        // de lo contrario se asume que todos tienen acceso
        return of(true);
      }
    }
    return of(false);
  }
}
