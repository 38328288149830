import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StarterService {
  starterMenuItem: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}
}
