import { RoleSchema } from '../role/role.schema';

export interface UserSchema {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  role: RoleSchema;
  firstname: string;
  lastname: string;
  password: string;
  avatar_b64: string;
  cell_phone_number: string;
  position: string;
  passwordConfirmation: string;
  code: string;
}

export class UserNewSchema implements UserSchema {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  role: RoleSchema;
  password: string;
  firstname: string;
  lastname: string;
  avatar_b64: string;
  cell_phone_number: string;
  position: string;
  passwordConfirmation: string;
  code: string;
}
