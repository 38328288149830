export class ErrorMessages {
  id: string;
  message?: string;
  field?: string[];
}

export class ErrorMessage {
  message: ErrorMessages[];
}

export class ErrorSchema {
  statusCode: number;
  error: string;
  message: ErrorMessage[];
  data: ErrorMessage[];
}
