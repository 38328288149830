import { BaseSchema, VehicleSchema } from '../';

export class TransportAgreementSchema extends BaseSchema {
  company_name: string;
  nit: string;
  address: string;
  phone: string;
  contact_firstname: string;
  contact_lastname: string;
  contact_phone: string;
  email: string;
  vehicles: string[] | VehicleSchema[];

  constructor(params: {
    company_name: string;
    nit: string;
    address: string;
    phone: string;
    contact_firstname: string;
    contact_lastname: string;
    contact_phone: string;
    email: string;
    vehicles?: string[] | VehicleSchema[];
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.company_name = params.company_name;
    this.nit = params.nit;
    this.address = params.address;
    this.phone = params.phone;
    this.contact_firstname = params.contact_firstname;
    this.contact_lastname = params.contact_lastname;
    this.contact_phone = params.contact_phone;
    this.email = params.email;
    this.vehicles = params.vehicles;
  }
}
