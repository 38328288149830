import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import { MunicipalitySchema, DepartmentSchema } from '@schemas/core';
import { IMunicipalityDatatable, IToSelect } from '@shared/interfaces';
import { map } from 'rxjs/operators';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class MunicipalityService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<MunicipalitySchema[]> {
    let params = new HttpParams().set('_sort', 'name:ASC').set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<MunicipalitySchema[]>(
      `${this.API_URL}/municipalities`,
      {
        params,
      },
    );
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams();

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/municipalities/count`, {
      params,
    });
  }

  findOne(id: string): Observable<MunicipalitySchema> {
    return this.http.get<MunicipalitySchema>(
      `${this.API_URL}/municipalities/${id}`,
    );
  }

  /**
   * Find duplicate municipaliy by code.
   * Can exclude registers for find duplicate
   * Return array boolean on this order code
   *
   */
  findDuplicate(
    data: {
      code: string;
    },
    exclude: string[],
  ): Observable<Array<boolean>> {
    return combineLatest([
      this.findDuplicateField('code', data.code, exclude),
    ]).pipe(map(([code]) => [code]));
  }

  /**
   * Return true if the field is duplicate
   * Can exclude registers for find duplicate
   *
   * En la actualidad Strapi no tiene una forma de buscar
   * un campo igual que no sea case sensitive, por eso se usa contains.
   *
   */
  private findDuplicateField(
    field: string,
    data: string,
    exclude: string[],
  ): Observable<boolean> {
    return this.http
      .get<MunicipalitySchema[]>(
        `${this.API_URL}/municipalities?${field}=${data}`,
      )
      .pipe(
        map((r) =>
          r.find(
            (x) =>
              !exclude.includes(x.id.toString()) &&
              x[field].toUpperCase() === data.toUpperCase(),
          )
            ? true
            : false,
        ),
      );
  }

  create(municipality: MunicipalitySchema): Observable<MunicipalitySchema> {
    return this.http.post<MunicipalitySchema>(
      `${this.API_URL}/municipalities`,
      municipality,
    );
  }

  update(municipality: MunicipalitySchema): Observable<MunicipalitySchema> {
    return this.http.put<MunicipalitySchema>(
      `${this.API_URL}/municipalities/${municipality?.id}`,
      municipality,
    );
  }

  delete(id: string): Observable<MunicipalitySchema> {
    return this.http.delete<MunicipalitySchema>(
      `${this.API_URL}/municipalities/${id}`,
    );
  }

  toDataTable(
    municipality: Array<MunicipalitySchema>,
  ): Array<IMunicipalityDatatable> {
    return municipality.map((d) => ({
      code: d.code,
      name: d.name,
      department: (d.department as DepartmentSchema).name,
      id: d.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'code_contains',
        field: 'Código',
        default: false,
      },
      {
        APIField: 'name_contains',
        field: 'Nombre',
        default: true,
      },
      {
        APIField: 'department.name_contains',
        field: 'Departamento',
        default: false,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of(['Código', 'Nombre', 'Departamento']);
  }

  toSelect(municipality: MunicipalitySchema[]): IToSelect[] {
    return municipality.map((a) => ({
      id: a.id,
      description: `${a.name} (${(a.department as DepartmentSchema).name})`,
    }));
  }

  /**
   * Transform municipality data to select with
   * field `code`
   *
   * @param {MunicipalitySchema[]} municipality
   * @return {*}  {IToSelect[]}
   * @memberof MunicipalityService
   */
  toSelectWithCode(municipality: MunicipalitySchema[]): IToSelect[] {
    return municipality.map((a) => ({
      id: a.code,
      description: `${a.name} (${(a.department as DepartmentSchema).name})`,
    }));
  }
}
