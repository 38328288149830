<div class="row mt-3">
  <ng-container *ngIf="enableSearch">
    <div class="col-3 pr-0">
      <ng-multiselect-dropdown
        [(ngModel)]="filterOptionSelected"
        [placeholder]="'Seleccione...   '"
        [settings]="{
          singleSelection: true,
          idField: 'id',
          textField: 'description',
          closeDropDownOnSelection: true
        }"
        [data]="filterOptions"
      ></ng-multiselect-dropdown>
    </div>
    <div class="col-3 pl-0">
      <input
        type="text"
        placeholder="Buscar..."
        class="form-control w-auto"
        #searchInput
      />
    </div>
  </ng-container>
  <div class="col">
    <button
      type="button"
      class="btn btn-primary ml-auto float-right"
      (click)="add(true)"
      *ngIf="showAddButton"
    >
      + Agregar {{ title }}
    </button>
  </div>
</div>
<div class="table-responsive">
  <table class="table table-striped no-wrap border mt-4">
    <thead>
      <!-- <tr>
                <th>ID</th>
                <th sortable="Name" (sort)="onSort($event)">Name</th>
                <th sortable="UserName" (sort)="onSort($event)">User Name</th>
                <th sortable="Email" (sort)="onSort($event)">Email</th>
                <th>Action</th>
            </tr> -->
      <tr>
        <th *ngFor="let title of tableHead">
          {{ title }}
        </th>
        <ng-container *ngTemplateOutlet="additionalColumnsHead"></ng-container>
        <th *ngIf="showActions">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <ng-container
        *ngIf="
          tableDataFilter?.length > 0 && (loading$ | async) === false;
          else empty
        "
      >
        <tr *ngFor="let row of tableDataFilter">
          <td
            *ngFor="
              let item of row
                | keyvalue: preserveOrder
                | slice: 0:tableHead?.length
            "
            [innerHTML]="item.value"
          ></td>
          <!-- <td>
                    <img [src]='client.imagePath' class="mr-2 rounded-circle" style="width: 30px">
                    <ngb-highlight [result]="client.Name" [term]="csearchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="client.UserName" [term]="csearchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="client.Email" [term]="csearchTerm"></ngb-highlight>
                </td> -->

          <ng-container
            *ngTemplateOutlet="additionalColumns; context: { $implicit: row }"
          ></ng-container>
          <ng-container *ngIf="showActions">
            <ng-container
              *ngTemplateOutlet="
                actionsTemplate || actionsTemplateDefault;
                context: { $implicit: row }
              "
            ></ng-container>
          </ng-container>
        </tr>
      </ng-container>
      <ng-template #empty>
        <tr>
          <td [attr.colspan]="tableHead?.length + 1" class="text-center">
            <app-loading></app-loading>
            <h4 *ngIf="(loading$ | async) === false">
              No hay datos para mostrar
            </h4>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-2">
    <ngb-pagination
      [(page)]="page"
      [pageSize]="pageSize"
      [collectionSize]="totalLengthTableData"
      [maxSize]="maxSize"
    ></ngb-pagination>
    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize">
      <option [ngValue]="5">5 registros por página</option>
      <option [ngValue]="10">10 registros por página</option>
      <option [ngValue]="50">50 registros por página</option>
    </select>
  </div>
</div>

<ng-template #actionsTemplateDefault let-row>
  <td>
    <i
      class="fa fa-edit text-info mr-3 cursor-pointer"
      (click)="update(row?.id?.toString())"
      title="Editar registro"
      *ngIf="showEditButton"
    ></i>
    <i
      class="fa fa-trash text-danger cursor-pointer"
      (click)="delete(row?.id?.toString())"
      title="Borrar registro"
      *ngIf="showDelButton"
    ></i>
  </td>
</ng-template>
