<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
  id="main-wrapper"
  [ngClass]="{ 'show-sidebar': showMobileMenu }"
  [dir]="options.dir"
  [attr.data-theme]="options.theme"
  [attr.data-layout]="options.layout"
  [attr.data-sidebartype]="options.sidebartype"
  [attr.data-sidebar-position]="options.sidebarpos"
  [attr.data-header-position]="options.headerpos"
  [attr.data-boxed-layout]="options.boxed"
>
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header class="topbar" [attr.data-navbarbg]="options.navbarbg">
    <nav
      class="d-flex top-navbar navbar-expand-lg"
      [ngClass]="options.navbarbg === 'skin6' ? 'navbar-light' : 'navbar-dark'"
    >
      <div
        class="navbar-header"
        [ngClass]="expandLogo ? 'expand-logo' : ''"
        [attr.data-logobg]="options.logobg"
      >
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a
          (click)="showMobileMenu = !showMobileMenu"
          class="nav-toggler waves-effect waves-light d-block d-lg-none"
          href="javascript:void(0)"
        >
          <i [ngClass]="showMobileMenu ? 'ti-close' : 'ti-menu'"></i>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand" href="javascript:void(0)">
          <!-- Logo icon -->
          <b class="logo-icon" *ngIf="false">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <img
              src="assets/images/logo-icon.svg"
              alt="homepage"
              class="dark-logo"
            />
            <!-- Light Logo icon -->
            <img
              src="assets/images/logo-light-icon.svg"
              alt="homepage"
              class="light-logo"
            />
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->
          <span class="logo-text">
            <!-- dark Logo text -->
            <img
              src="assets/images/logo-text.svg"
              alt="homepage"
              class="dark-logo"
              style="width: 140px"
            />
            <!-- Light Logo text -->
            <img
              src="assets/images/logo-light-text.svg"
              class="light-logo"
              alt="homepage"
              style="width: 140px"
            />
          </span>
        </a>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a
          class="topbartoggler d-block d-lg-none waves-effect waves-light"
          href="javascript:void(0)"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
        >
          <i class="ti-more"></i>
        </a>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div
        class="navbar-collapse collapse"
        id="navbarSupportedContent"
        [attr.data-navbarbg]="options.navbarbg"
        [ngbCollapse]="!isCollapsed"
      >
        <!-- Horizontal Header -->
        <div
          *ngIf="options.layout === 'horizontal'; else vheader"
          class="w-100"
        >
          <app-horizontal-navigation
            class="w-100 d-flex align-items-center justify-content-between"
          ></app-horizontal-navigation>
        </div>

        <!-- Vertical Header -->
        <ng-template #vheader>
          <div class="w-100 text-center">
            <app-environment-info></app-environment-info>
            <app-vertical-navigation
              (toggleSidebar)="toggleSidebarType()"
              (logout)="logout()"
              [userProfile]="userProfile$ | async"
            ></app-vertical-navigation>
          </div>
        </ng-template>
      </div>
    </nav>
  </header>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <aside
    class="left-sidebar"
    [attr.data-sidebarbg]="options.sidebarbg"
    (mouseover)="Logo()"
    (mouseout)="Logo()"
  >
    <!-- Sidebar scroll-->
    <div *ngIf="options.layout === 'horizontal'; else vlayout">
      <app-horizontal-sidebar></app-horizontal-sidebar>
    </div>

    <!-- Horizontal Header -->
    <ng-template #vlayout>
      <div class="scroll-sidebar" [perfectScrollbar]="config">
        <app-vertical-sidebar
          [showClass]="showMobileMenu"
          (notify)="handleClick($event)"
        ></app-vertical-sidebar>
      </div>
    </ng-template>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <app-breadcrumb></app-breadcrumb>

    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div
      class="container-fluid"
      [ngClass]="router.url === '/starter' ? 'content-element' : ''"
    >
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <!-- <footer class="footer">
      ©
      <img src="assets/images/logo-light-text.svg" alt="logo" />
      {{ today | date : 'yyyy' }}
    </footer> -->
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
  <aside class="customizer" [ngClass]="{ 'show-service-panel': showSettings }">
    <a
      href="javascript:void(0)"
      class="btn-dark btn btn-circle btn-lg service-panel-toggle"
      (click)="showSettings = !showSettings"
      *ngIf="env.showWidgets"
    >
      <i class="fa fa-spin fa-cog"></i>
    </a>
    <div class="customizer-body custom-pills" [perfectScrollbar]="config"></div>
  </aside>
  <div class="chat-windows"></div>
</div>
