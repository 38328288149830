import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService, NotifierService } from '@services/core';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  readonly FIELD_USERNAME = 'username';
  readonly FIELD_PASSWORD = 'password';

  form: FormGroup;
  loading = false;
  showPassword = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private notifier: NotifierService,
  ) {}

  ngOnInit() {
    this.authService.logout();
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.fb.group({
      [this.FIELD_USERNAME]: ['', [Validators.required, Validators.email]],
      [this.FIELD_PASSWORD]: ['', Validators.required],
    });
  }

  loginform = true;
  recoverform = false;

  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    const values = this.form.getRawValue();
    this.loading = true;
    this.authService
      .login({
        identifier: values.username,
        password: values.password,
      })
      .subscribe({
        complete: () => {
          this.router.navigate(['/starter']);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.notifier.error('Verifique su correo electrónico o contraseña');
        },
      });
  }
}
