import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import { TransportAgreementSchema } from '@schemas/core';
import { IToSelect, ITransportAgreementDatatable } from '@shared/interfaces';
import { TransportAgreementTypeEnum } from '@shared/enums';
import { map } from 'rxjs/operators';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class TransportAgreementService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<TransportAgreementSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'company_name:ASC')
      .set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<TransportAgreementSchema[]>(
      `${this.API_URL}/transport-agreements`,
      {
        params,
      },
    );
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams().set('_sort', 'company_name:ASC');

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/transport-agreements/count`, {
      params,
    });
  }

  findOne(id: string): Observable<TransportAgreementSchema> {
    return this.http.get<TransportAgreementSchema>(
      `${this.API_URL}/transport-agreements/${id}`,
    );
  }

  /**
   * Find duplicate Transport Agreement by nit.
   * Can exclude registers for find duplicate
   * Return array boolean on this order nit
   *
   */
  findDuplicate(
    data: {
      nit: string;
    },
    exclude: string[],
  ): Observable<Array<boolean>> {
    return combineLatest([
      this.findDuplicateField('nit', data.nit, exclude),
    ]).pipe(map(([nit]) => [nit]));
  }

  /**
   * Return true if the field is duplicate
   * Can exclude registers for find duplicate
   *
   * En la actualidad Strapi no tiene una forma de buscar
   * un campo igual que no sea case sensitive, por eso se usa contains.
   *
   */
  private findDuplicateField(
    field: string,
    data: string,
    exclude: string[],
  ): Observable<boolean> {
    return this.http
      .get<TransportAgreementSchema[]>(
        `${this.API_URL}/transport-agreements?${field}_contains=${data}`,
      )
      .pipe(
        map((r) =>
          r.find(
            (x) =>
              !exclude.includes(x.id.toString()) &&
              x[field].toUpperCase() === data.toUpperCase(),
          )
            ? true
            : false,
        ),
      );
  }
  create(
    transportAgreement: TransportAgreementSchema,
  ): Observable<TransportAgreementSchema> {
    return this.http.post<TransportAgreementSchema>(
      `${this.API_URL}/transport-agreements`,
      transportAgreement,
    );
  }

  update(
    transport_agreement: TransportAgreementSchema,
  ): Observable<TransportAgreementSchema> {
    return this.http.put<TransportAgreementSchema>(
      `${this.API_URL}/transport-agreements/${transport_agreement?.id}`,
      transport_agreement,
    );
  }

  delete(id: string): Observable<TransportAgreementSchema> {
    return this.http.delete<TransportAgreementSchema>(
      `${this.API_URL}/transport-agreements/${id}`,
    );
  }

  toDataTable(
    transportAgreements: Array<TransportAgreementSchema>,
  ): Array<ITransportAgreementDatatable> {
    return transportAgreements.map((ta) => ({
      nit: ta.nit,
      company_name: ta.company_name,
      id: ta.id,
    }));
  }

  toSelect(transportAgreements: TransportAgreementSchema[]): IToSelect[] {
    return transportAgreements.map((ta) => ({
      id: ta.id,
      description: ta.company_name,
    }));
  }

  typeTransportAgreementsToSelect(): Observable<IToSelect[]> {
    const transportAgreementType = Object.keys(TransportAgreementTypeEnum).map(
      (tat) => ({
        id: tat,
        description: TransportAgreementTypeEnum[tat],
      }),
    );
    transportAgreementType.shift();
    transportAgreementType.sort((a, b) =>
      a.description.localeCompare(b.description),
    );
    transportAgreementType.unshift({
      id: Object.keys(TransportAgreementTypeEnum)[
        TransportAgreementTypeEnum.NINGUNO.indexOf(
          TransportAgreementTypeEnum.NINGUNO,
        )
      ],
      description: TransportAgreementTypeEnum.NINGUNO,
    });

    return of(transportAgreementType);
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'nit_contains',
        field: 'Identificación',
        default: true,
      },
      {
        APIField: 'company_name_contains',
        field: 'Razón social',
        default: false,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of(['Identificación', 'Razón Social']);
  }
}
