import {
  BaseSchema,
  AffiliateSchema,
  DriverSchema,
  ContractorSchema,
} from '../';

export class DocumentTypeSchema extends BaseSchema {
  code: string;
  name: string;
  affiliates: Array<AffiliateSchema> | Array<string>;
  drivers: Array<DriverSchema> | Array<string>;
  contractors: Array<ContractorSchema> | Array<string>;
  contractors_legal_repesentative: Array<ContractorSchema> | Array<string>;

  constructor(
    code: string,
    name: string,
    affiliates: Array<AffiliateSchema> | Array<string>,
    drivers: Array<DriverSchema> | Array<string>,
    contractors: Array<ContractorSchema> | Array<string>,
    contractors_legal_repesentative: Array<ContractorSchema> | Array<string>,
    published_at: Date,
    id?: string,
    created_by?: string,
    updated_by?: string,
  ) {
    super(published_at, id, created_by, updated_by);
    this.code = code;
    this.name = name;
    affiliates = affiliates;
  }
}
