import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import { ContractObjectSchema } from '@schemas/core';
import { IContractObjectDatatable, IToSelect } from '@shared/interfaces';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class ContractObjectService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<ContractObjectSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'description:ASC')
      .set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<ContractObjectSchema[]>(
      `${this.API_URL}/contract-objects`,
      {
        params,
      },
    );
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams();

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/contract-objects/count`, {
      params,
    });
  }

  findOne(id: string): Observable<ContractObjectSchema> {
    return this.http.get<ContractObjectSchema>(
      `${this.API_URL}/contract-objects/${id}`,
    );
  }

  create(
    contractObject: ContractObjectSchema,
  ): Observable<ContractObjectSchema> {
    return this.http.post<ContractObjectSchema>(
      `${this.API_URL}/contract-objects`,
      contractObject,
    );
  }

  update(
    contractobject: ContractObjectSchema,
  ): Observable<ContractObjectSchema> {
    return this.http.put<ContractObjectSchema>(
      `${this.API_URL}/contract-objects/${contractobject?.id}`,
      contractobject,
    );
  }

  delete(id: string): Observable<ContractObjectSchema> {
    return this.http.delete<ContractObjectSchema>(
      `${this.API_URL}/contract-objects/${id}`,
    );
  }

  toDataTable(
    contractObjects: Array<ContractObjectSchema>,
  ): Array<IContractObjectDatatable> {
    return contractObjects.map((d) => ({
      description: d.description,
      id: d.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'description_contains',
        field: 'Descripción',
        default: true,
      },
    ]);
  }

  toSelect(companies: ContractObjectSchema[]): IToSelect[] {
    return companies.map((a) => ({ id: a.id, description: a.description }));
  }

  getTableHead(): Observable<string[]> {
    return of(['Descripción']);
  }
}
