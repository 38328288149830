import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';

import { UserService } from '@services/core';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  USER_PERMISSIONS: number[] = [];

  constructor(private userService: UserService) {}

  hasPermission(permissionCode: number | null): Observable<boolean> {
    return this.userService.userProfile$.pipe(
      switchMap((u) => {
        const userPermissions = u.role.frontend_permissions;
        if (userPermissions.includes(permissionCode)) {
          return of(true);
        }
        return of(false);
      }),
    );
  }
}
