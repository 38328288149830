import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import {
  AffiliateSchema,
  TransportAgreementSchema,
  VehicleSchema,
  DocumentSubjectSchema,
} from '@schemas/core';
import { IVehicleDatatable, IToSelect } from '@shared/interfaces';
import { VehicleTypeEnum } from '@shared/enums';
import { find, map } from 'rxjs/operators';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';
import format from 'date-fns/format';

@Injectable({
  providedIn: 'root',
})
export class VehicleService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
    populate: string[] | null = null,
  ): Observable<VehicleSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'vehicle_number:ASC')
      .set('_limit', -1);

    params = populate
      ? params.set('populate', JSON.stringify(populate))
      : params;

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<VehicleSchema[]>(`${this.API_URL}/vehicles`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams().set('_sort', 'vehicle_number:ASC');

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/vehicles/count`, {
      params,
    });
  }

  /**
   * Busca vehículos según el array de ids que se pasa
   * por parámetro
   *
   * @return Observable<VehicleSchema[]>
   * @memberof VehicleService
   */
  findByIds(ids: string[]): Observable<VehicleSchema[]> {
    const query = ids.map((i) => `id_in=${i}`).join('&');
    return this.http.get<VehicleSchema[]>(`${this.API_URL}/vehicles/?${query}`);
  }

  findOne(id: string): Observable<VehicleSchema> {
    return this.http.get<VehicleSchema>(`${this.API_URL}/vehicles/${id}`);
  }

  /**
   * Find duplicate vehicle by contract_number, plate, or transit_license_number.
   * Can exclude registers for find duplicate
   * Return array boolean on this order contract_number, plate, transit_license_number
   *
   */
  findDuplicate(
    data: {
      contract_number: string;
      plate: string;
      transit_license_number: string;
      vehicle_number: string;
    },
    exclude: string[],
  ): Observable<Array<boolean>> {
    return combineLatest([
      this.findDuplicateField('contract_number', data.contract_number, exclude),
      this.findDuplicateField('plate', data.plate, exclude),
      this.findDuplicateField(
        'transit_license_number',
        data.transit_license_number,
        exclude,
      ),
      this.findDuplicateField('vehicle_number', data.vehicle_number, exclude),
    ]).pipe(
      map(
        ([contract_number, plate, transit_license_number, vehicle_number]) => [
          contract_number,
          plate,
          transit_license_number,
          vehicle_number,
        ],
      ),
    );
  }

  /**
   * Return true if the field is duplicate
   * Can exclude registers for find duplicate
   *
   * En la actualidad Strapi no tiene una forma de buscar
   * un campo igual que no sea case sensitive, por eso se usa contains.
   *
   */
  private findDuplicateField(
    field: string,
    data: string,
    exclude: string[],
  ): Observable<boolean> {
    return this.http
      .get<VehicleSchema[]>(
        `${this.API_URL}/vehicles?${field}_contains=${data}`,
      )
      .pipe(
        map((r) =>
          r.find(
            (x) =>
              !exclude.includes(x.id.toString()) &&
              x[field].toUpperCase() === data.toUpperCase(),
          )
            ? true
            : false,
        ),
      );
  }

  /**
   * Busca un vehículo por placa
   * @param plate
   * @returns Observable<VehicleSchema>
   */
  findOneByPlate(plate: string): Observable<VehicleSchema> {
    return this.http
      .get<VehicleSchema>(`${this.API_URL}/vehicles?plate=${plate}`)
      .pipe(map((v) => v[0]));
  }

  /**Busca los vehículos que pertenezca a una compania específica
   *
   * @param idCompany
   * @returns Observable<VehicleSchema[]>
   */
  findByidCompany(
    idCompany: string,
    populate: string[] | null = null,
  ): Observable<VehicleSchema[]> {
    let params = new HttpParams()
      .set('affiliate.company', idCompany)
      .set('_sort', 'plate:ASC,brand:ASC')
      .set('_limit', -1);

    params = populate
      ? params.set('populate', JSON.stringify(populate))
      : params;

    return this.http.get<VehicleSchema[]>(`${this.API_URL}/vehicles`, {
      params,
    });
  }

  create(vehicle: VehicleSchema): Observable<VehicleSchema> {
    return this.http.post<VehicleSchema>(`${this.API_URL}/vehicles`, vehicle);
  }

  createWithFormData(formData: FormData): Observable<VehicleSchema> {
    return this.http.post<VehicleSchema>(`${this.API_URL}/vehicles`, formData);
  }

  update(vehicle: VehicleSchema): Observable<VehicleSchema> {
    return this.http.put<VehicleSchema>(
      `${this.API_URL}/vehicles/${vehicle?.id}`,
      vehicle,
    );
  }

  updateWithFormData(
    id: string,
    formData: FormData,
  ): Observable<VehicleSchema> {
    return this.http.put<VehicleSchema>(
      `${this.API_URL}/vehicles/${id}`,
      formData,
    );
  }

  delete(id: string): Observable<VehicleSchema> {
    return this.http.delete<VehicleSchema>(`${this.API_URL}/vehicles/${id}`);
  }

  /**
   * Link driver to vehicle
   *
   * @param {VehicleSchema} vehicle
   * @return {*}  {Observable<VehicleSchema>}
   * @memberof VehicleService
   */
  linkDriver(params: {
    id: string;
    driverId: string;
  }): Observable<VehicleSchema> {
    return this.http.put<VehicleSchema>(
      `${this.API_URL}/vehicles/${params.id}/link/drivers`,
      { driverId: params.driverId },
    );
  }

  /**
   * unlink driver to vehicle
   *
   * @param {VehicleSchema} vehicle
   * @return {*}  {Observable<VehicleSchema>}
   * @memberof VehicleService
   */
  unlinkDriver(params: {
    id: string;
    driverId: string;
  }): Observable<VehicleSchema> {
    return this.http.put<VehicleSchema>(
      `${this.API_URL}/vehicles/${params.id}/unlink/drivers`,
      { driverId: params.driverId },
    );
  }

  toDataTable(vehicles: Array<VehicleSchema>): Array<IVehicleDatatable> {
    return vehicles.map((v) => ({
      plate: v.plate,
      brand: v.brand,
      vehicle_number: v.vehicle_number,
      affiliate_name: (v.affiliate as AffiliateSchema)?.company_name
        ? `${(v.affiliate as AffiliateSchema)?.company_name}`
        : `${(v.affiliate as AffiliateSchema)?.firstname} ${
            (v.affiliate as AffiliateSchema)?.lastname
          }`,
      company_agreement_name: (
        v?.transport_agreement as TransportAgreementSchema
      )?.company_name,
      documentsSubject: v.document_subjects as DocumentSubjectSchema[],
      id: v.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'plate_contains',
        field: 'Placa',
        default: false,
      },
      {
        APIField: 'brand_contains',
        field: 'Marca',
        default: false,
      },
      {
        APIField: 'vehicle_number_contains',
        field: 'No. Interno',
        default: true,
      },
      {
        APIField: 'affiliate.firstname_contains',
        field: 'Nombre del afiliado',
        default: false,
      },
      {
        APIField: 'affiliate.lastname_contains',
        field: 'Apellido del afiliado',
        default: false,
      },
      {
        APIField: 'affiliate.company_name_contains',
        field: 'Nombre de la compañía',
        default: false,
      },
      {
        APIField: 'transport_agreement.company_name_contains',
        field: 'Empresa Convenio',
        default: false,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of([
      'Placa',
      'Marca',
      'No. Interno',
      'Afiliado',
      'Empresa Convenio',
    ]);
  }

  toSelect(vehicle: VehicleSchema[]): IToSelect[] {
    return vehicle.map((a) => ({
      id: a.id,
      description: `${a.plate} - ${a.brand} `,
    }));
  }

  typeVehicleToSelect(): Observable<IToSelect[]> {
    return of(
      Object.keys(VehicleTypeEnum)
        .map((vt) => ({
          id: vt,
          description: VehicleTypeEnum[vt],
        }))
        .sort((a, b) => a.description.localeCompare(b.description)),
    );
  }
}
