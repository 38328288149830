export const environment = {
  production: process.env.NG_APP_PRODUCTION.toLowerCase() === 'true',
  ENV: process.env.NG_APP_ENVIRONMENT,
  API_URL: process.env.NG_APP_API_URL,

  // Para activar o desactivar los widgets del template original
  showWidgets: process.env.NG_APP_SHOW_WIDGETS.toLowerCase() === 'true',

  //Para activar o desactivar los recorridos multiples en el módulo FUEC
  MULTIPLE_ROUTES: process.env.NG_APP_MULTIPLE_ROUTES.toLowerCase() === 'true',

  // TODO: Esto debería ir en alguna pantalla
  // de configuración de la aplicación. Debería poderse
  // cambiar de manera dinámica
  // ID del documento Tarjeta de Operación en Backend
  ID_TARJETA_DE_OPERACION: process.env.NG_APP_ID_TARJETA_DE_OPERACION,
  ID_LICENCIA_CONDUCTOR: process.env.NG_APP_ID_LICENCIA_CONDUCTOR,

  // Tiempo en segundos de inactividad antes de mostrar la modal
  // de notificación de expiración de sesión
  // Default 15 minutos
  INACTIVE_USER_TIME_NOTIFICATION: parseInt(
    process.env?.NG_APP_INACTIVE_USER_TIME_NOTIFICATION ?? '900',
    10,
  ),

  // Tiempo de duración de la modal en pantalla para
  // que el usuario decida si continuar o no en el sistema
  // en milisegundos
  // Default 30 segundos
  SESSION_EXPIRATION_TIME_NOTIFICACION: parseInt(
    process.env.NG_APP_SESSION_EXPIRATION_TIME_NOTIFICACION ?? '30000',
    10,
  ),

  // Tiempo en milisegundos para revisar por documentos vencidos
  DOCUMENT_ALERT_TIME_CHECK: parseInt(
    process.env.NG_APP_DOCUMENT_ALERT_TIME_CHECK ?? '60000',
    10,
  ),

  // Tiempo en milisegundos para revisar por mensajes nuevos
  MESSAGES_TIME_CHECK: parseInt(
    process.env.NG_APP_MESSAGES_TIME_CHECK ?? '30000',
    10,
  ),

  // Taked to https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  TIMEZONE: process.env.NG_APP_TIMEZONE,
};
