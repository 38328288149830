<div
  class="auth-wrapper d-flex no-block justify-content-center align-items-center background-start"
>
  <div>
    <div class="auth-box">
      <div class="row">
        <div class="col-12">
          <div class="logo">
            <span class="db">
              <img src="assets/images/logo-light-text.svg" alt="logo" />
            </span>
            <h5 class="font-medium m-b-20 text-box">Crear nueva contraseña</h5>
            <span class="text-box">¡Ingrese su nueva contraseña!</span>
          </div>
          <div class="m-t-20">
            <!-- Form -->
            <form [formGroup]="form" (ngSubmit)="onSubmit()" errorTailor>
              <!-- email -->
              <div class="form-group row">
                <div class="offset-1 col-10">
                  <div class="input-group">
                    <input
                      [type]="showPassword ? 'text' : 'password'"
                      class="form-control form-control-lg text-box"
                      autocomplete="new-password"
                      placeholder="Contraseña"
                      aria-label="Email"
                      aria-describedby="basic-addon1"
                      [formControlName]="FIELD_PASSWORD"
                      [ngClass]="{
                        'login-error':
                          (form.controls[FIELD_PASSWORD].dirty ||
                            form.controls[FIELD_PASSWORD].touched) &&
                          form.controls[FIELD_PASSWORD].errors &&
                          form.controls[FIELD_PASSWORD].errors?.required
                      }"
                      [controlErrorAnchor]="anchorPassword"
                      controlErrorsClass="tailor-error"
                    />
                    <div class="input-group-append">
                      <span class="icon-inside-noborder">
                        <i
                          class="fas fa-eye cursor-pointer"
                          (click)="showPassword = !showPassword"
                          title="Ver contraseña"
                        ></i>
                      </span>
                    </div>
                  </div>
                  <ng-template
                    controlErrorAnchor
                    #anchorPassword="controlErrorAnchor"
                  ></ng-template>
                </div>
              </div>
              <!-- pwd -->
              <div class="form-group row">
                <div class="offset-1 col-10">
                  <div class="input-group">
                    <input
                      [type]="showRepeatPassword ? 'text' : 'password'"
                      class="form-control form-control-lg text-box"
                      autocomplete="new-password"
                      placeholder="Confirmación"
                      aria-label="Email"
                      aria-describedby="basic-addon1"
                      [formControlName]="FIELD_PASSWORD_REPEAT"
                      [ngClass]="{
                        'login-error':
                          (form.controls[FIELD_PASSWORD_REPEAT].dirty ||
                            form.controls[FIELD_PASSWORD_REPEAT].touched) &&
                          form.controls[FIELD_PASSWORD_REPEAT].errors &&
                          (form.controls[FIELD_PASSWORD_REPEAT].errors
                            ?.required ||
                            form.controls[FIELD_PASSWORD_REPEAT].errors
                              ?.confirmedValidator)
                      }"
                      [controlErrorAnchor]="anchorRepeatPassword"
                      controlErrorsClass="tailor-error"
                    />
                    <div class="input-group-append">
                      <span class="icon-inside-noborder">
                        <i
                          class="fas fa-eye cursor-pointer"
                          (click)="showRepeatPassword = !showRepeatPassword"
                          title="Ver contraseña"
                        ></i>
                      </span>
                    </div>
                  </div>
                  <ng-template
                    controlErrorAnchor
                    #anchorRepeatPassword="controlErrorAnchor"
                  ></ng-template>
                </div>
              </div>

              <div class="form-group text-center">
                <div class="col-xs-12 p-b-20">
                  <button
                    class="btn btn-lg btn-login"
                    type="submit"
                    name="action"
                  >
                    ENVIAR
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
