import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import {
  RouteSchema,
  MunicipalitySchema,
  DepartmentSchema,
} from '@schemas/core';

import { IRouteDatatable, IToSelect } from '@shared/interfaces';
import { DepartmentService } from '../department/department.service';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class RouteService extends BaseService {
  constructor(
    protected http: HttpClient,
    private departmentService: DepartmentService,
  ) {
    super(http);
  }

  private getRoutesWithDepartments(
    routes$: Observable<RouteSchema[]>,
  ): Observable<RouteSchema[]> {
    return combineLatest([routes$, this.departmentService.find()]).pipe(
      map(([routes, deparments]) =>
        routes.map((r) => {
          const toMunicipalityDepartment = deparments.find(
            (d) =>
              d.id === (r.to_municipality as MunicipalitySchema).department,
          );
          const fromMunicipalityDepartment = deparments.find(
            (d) =>
              d.id === (r.from_municipality as MunicipalitySchema).department,
          );
          (r.to_municipality as MunicipalitySchema).department =
            toMunicipalityDepartment;
          (r.from_municipality as MunicipalitySchema).department =
            fromMunicipalityDepartment;
          return r;
        }),
      ),
    );
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<RouteSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'from_municipality.name:ASC,to_municipality.name:ASC')
      .set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    const getRoutes$ = this.http.get<RouteSchema[]>(`${this.API_URL}/routes`, {
      params,
    });

    return this.getRoutesWithDepartments(getRoutes$);
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams();

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/routes/count`, {
      params,
    });
  }

  findOne(id: string): Observable<RouteSchema> {
    const getRoute = this.http.get<RouteSchema>(`${this.API_URL}/routes/${id}`);

    return combineLatest([getRoute, this.departmentService.find()]).pipe(
      map(([route, deparments]) => {
        const toMunicipalityDepartment = deparments.find(
          (d) =>
            d.id === (route.to_municipality as MunicipalitySchema).department,
        );
        const fromMunicipalityDepartment = deparments.find(
          (d) =>
            d.id === (route.from_municipality as MunicipalitySchema).department,
        );

        (route.to_municipality as MunicipalitySchema).department =
          toMunicipalityDepartment;
        (route.from_municipality as MunicipalitySchema).department =
          fromMunicipalityDepartment;
        return route;
      }),
    );
  }

  findByContractId(id: string): Observable<RouteSchema[]> {
    const params = new HttpParams()
      .set('_sort', 'from_municipality.name:ASC,to_municipality.name:ASC')
      .set('_limit', -1)
      .set('contracts_in', id);

    const getRoutes$ = this.http.get<RouteSchema[]>(`${this.API_URL}/routes`, {
      params,
    });

    return this.getRoutesWithDepartments(getRoutes$);
  }

  findByFromMunicipalityCode(code: string): Observable<RouteSchema[]> {
    const params = new HttpParams()
      .set('_sort', 'from_municipality.name:ASC,to_municipality.name:ASC')
      .set('_limit', -1)
      .set('from_municipality.code', code);

    const getRoutes$ = this.http.get<RouteSchema[]>(`${this.API_URL}/routes`, {
      params,
    });

    return this.getRoutesWithDepartments(getRoutes$);
  }

  create(route: RouteSchema): Observable<RouteSchema> {
    return this.http.post<RouteSchema>(`${this.API_URL}/routes`, route);
  }

  update(route: Partial<RouteSchema>): Observable<RouteSchema> {
    return this.http.put<RouteSchema>(
      `${this.API_URL}/routes/${route?.id}`,
      route,
    );
  }

  delete(id: string): Observable<RouteSchema> {
    return this.http.delete<RouteSchema>(`${this.API_URL}/routes/${id}`);
  }

  toDataTable(route: Array<RouteSchema>): Array<IRouteDatatable> {
    return route.map((d) => ({
      origin: `${(d.from_municipality as MunicipalitySchema).name} (${
        (
          (d.from_municipality as MunicipalitySchema)
            .department as DepartmentSchema
        ).name
      })`,
      destination: `${(d.to_municipality as MunicipalitySchema).name} (${
        (
          (d.to_municipality as MunicipalitySchema)
            .department as DepartmentSchema
        ).name
      })`,
      id: d.id,
    }));
  }

  getTableHead(): Observable<string[]> {
    return of(['Origen', 'Destino']);
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'from_municipality.name_contains',
        field: 'Origen',
        default: true,
      },
      {
        APIField: 'to_municipality.name_contains',
        field: 'Destino',
        default: false,
      },
    ]);
  }

  toSelect(route: RouteSchema[]): IToSelect[] {
    return route.map((a) => ({
      id: a.id,
      description: `${(a.from_municipality as MunicipalitySchema).name} (${
        (
          (a.from_municipality as MunicipalitySchema)
            .department as DepartmentSchema
        ).name
      }) - ${(a.to_municipality as MunicipalitySchema).name} (${
        (
          (a.to_municipality as MunicipalitySchema)
            .department as DepartmentSchema
        ).name
      })`,
    }));
  }

  /**
   * Transform routes by only origin
   *
   * @param {RouteSchema[]} route
   * @return {*}  {IToSelect[]}
   * @memberof RouteService
   */
  toSelectFrom(route: RouteSchema[]): IToSelect[] {
    return route.map((a) => ({
      id: a.id,
      description: `${(a.from_municipality as MunicipalitySchema).name}`,
    }));
  }

  /**
   * Transform routes by only destination
   *
   * @param {RouteSchema[]} route
   * @return {*}  {IToSelect[]}
   * @memberof RouteService
   */
  toSelectTo(route: RouteSchema[]): IToSelect[] {
    return route.map((a) => ({
      id: a.id,
      // eslint-disable-next-line max-len
      description: `${(a.to_municipality as MunicipalitySchema).name} (${
        (
          (a.to_municipality as MunicipalitySchema)
            .department as DepartmentSchema
        ).name
      })`,
    }));
  }
}
