import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import { PositionSchema } from '@schemas/core';
import { IPositionDatatable, IToSelect } from '@shared/interfaces';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class PositionService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<PositionSchema[]> {
    let params = new HttpParams().set('_sort', 'name:ASC').set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<PositionSchema[]>(`${this.API_URL}/positions`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams().set('_sort', 'name:ASC');

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/positions/count`, {
      params,
    });
  }

  findOne(id: string): Observable<PositionSchema> {
    return this.http.get<PositionSchema>(`${this.API_URL}/positions/${id}`);
  }

  create(position: PositionSchema): Observable<PositionSchema> {
    return this.http.post<PositionSchema>(
      `${this.API_URL}/positions`,
      position,
    );
  }

  update(position: PositionSchema): Observable<PositionSchema> {
    return this.http.put<PositionSchema>(
      `${this.API_URL}/positions/${position?.id}`,
      position,
    );
  }

  delete(id: string): Observable<PositionSchema> {
    return this.http.delete<PositionSchema>(`${this.API_URL}/positions/${id}`);
  }

  toDataTable(position: Array<PositionSchema>): Array<IPositionDatatable> {
    return position.map((d) => ({
      name: d.name,
      id: d.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'name_contains',
        field: 'Nombre',
        default: true,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of(['Nombre']);
  }

  toSelect(positions: PositionSchema[]): IToSelect[] {
    return positions.map((a) => ({ id: a.id, description: a.name }));
  }
}
