import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription, tap } from 'rxjs';

import { PermissionService } from './permission.service';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[permissionRequired]' })
export class PermissionDirective implements OnInit, OnDestroy {
  @Input() set permissionRequired(permissionCode: number) {
    this.permissionCode = permissionCode;
  }

  permissionCode: number | null = null;
  subscriptions = new Subscription();
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.permissionService
        .hasPermission(this.permissionCode)
        .pipe(
          tap((r) => {
            if (r) {
              this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
              this.viewContainer.clear();
            }
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
