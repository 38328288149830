import { HttpClient, HttpParams } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import { AssuredSchema, CompanySchema } from '@schemas/core';
import { IInsuranceDatatable, IToSelect } from '@shared/interfaces';
import { VehicleTypeEnum } from '@shared/enums';
import { InsuranceTypeEnum } from '@shared/enums';
import { DateTimeService } from '../datetime/datetime.service';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class InsuranceService extends BaseService {
  constructor(
    protected http: HttpClient,
    private currencyPipe: CurrencyPipe,
    private dateTimeService: DateTimeService,
  ) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<AssuredSchema[]> {
    let params = new HttpParams()
      .set('_sort', ' vehicle_type:ASC')
      .set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<AssuredSchema[]>(`${this.API_URL}/assureds`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams().set('_sort', 'vehicle_type:ASC');

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/assureds/count`, {
      params,
    });
  }

  findOne(id: string): Observable<AssuredSchema> {
    return this.http.get<AssuredSchema>(`${this.API_URL}/assureds/${id}`);
  }

  create(insurance: AssuredSchema): Observable<AssuredSchema> {
    return this.http.post<AssuredSchema>(`${this.API_URL}/assureds`, insurance);
  }

  update(insurance: AssuredSchema): Observable<AssuredSchema> {
    return this.http.put<AssuredSchema>(
      `${this.API_URL}/assureds/${insurance?.id}`,
      insurance,
    );
  }

  delete(id: string): Observable<AssuredSchema> {
    return this.http.delete<AssuredSchema>(`${this.API_URL}/assureds/${id}`);
  }

  toDataTable(insurance: Array<AssuredSchema>): Array<IInsuranceDatatable> {
    return insurance.map((i) => ({
      start_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        i.start_date,
      ),
      end_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        i.end_date,
      ),
      value: this.currencyPipe
        .transform(i.value, 'COP', 'symbol-narrow')
        .replace(',', 'x')
        .replace('.', ',')
        .replace('x', '.'),
      type: InsuranceTypeEnum[i.type],
      vehicle_type: VehicleTypeEnum[i.vehicle_type],
      company: (i.company as CompanySchema).name,
      id: i.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'value_contains',
        field: 'Valor',
        default: false,
      },
      {
        APIField: 'vehicle_type_contains',
        field: 'Clase de vehículo',
        default: true,
      },
      {
        APIField: 'company.name_contains',
        field: 'Empresa',
        default: false,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of([
      'Fecha Inicial',
      'Fecha Final',
      'Valor',
      'Tipo de Seguro',
      'Clase de Vehículo',
      'Empresa',
    ]);
  }

  typeInsuranceToSelect(): Observable<IToSelect[]> {
    return of(
      Object.keys(InsuranceTypeEnum)
        .map((it) => ({
          id: it,
          description: InsuranceTypeEnum[it],
        }))
        .sort((a, b) => a.description.localeCompare(b.description)),
    );
  }
}
