import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { RoleSchema, UserSchema, AffiliateSchema } from '@schemas/core';
import { IUserDatatable } from '@shared/interfaces';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly API_URL = environment.API_URL;
  // Por defecto no tiene ningún permiso en frontend
  private userProfileSource = new BehaviorSubject<UserSchema>({
    role: { frontend_permissions: [] },
  } as UserSchema);

  userProfile$ = this.userProfileSource.asObservable().pipe(
    map((resp) => {
      if (Object.keys(resp).length === 0) {
        this.loadUserProfile();
      }
      return resp;
    }),
  );

  constructor(private http: HttpClient) {}

  me(): Observable<UserSchema> {
    return this.http.get<UserSchema>(`${this.API_URL}/users/me`);
  }

  loadUserProfile(): void {
    this.me().subscribe({
      next: (resp) => {
        this.userProfileSource.next(resp);
      },
    });
  }

  /**
   * Find all users
   *
   * To load relations send relation name on populate array
   * Example: To load role send string "role"
   * on array populate
   *
   * @param {(string[] | null)} [populate=null]
   * @param {(IStateDataTable | null)} [stateDataTable=null]
   * @return {*}  {Observable<UserSchema[]>}
   * @memberof UserService
   */
  find(
    populate: string[] | null = null,
    stateDataTable: IStateDataTable | null = null,
  ): Observable<UserSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'firstname:ASC,lastname:ASC')
      .set('_limit', -1);

    params = populate
      ? params.set('populate', JSON.stringify(populate))
      : params;

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<UserSchema[]>(`${this.API_URL}/users`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams();

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/users/count`, {
      params,
    });
  }

  findOne(id: string): Observable<UserSchema> {
    return this.http.get<UserSchema>(`${this.API_URL}/users/${id}`);
  }

  update(user: UserSchema): Observable<UserSchema> {
    return this.http.put<UserSchema>(`${this.API_URL}/users/${user?.id}`, user);
  }

  updateMe(user: UserSchema): Observable<UserSchema> {
    return this.http.put<UserSchema>(`${this.API_URL}/users/me`, user);
  }

  create(user: UserSchema): Observable<UserSchema> {
    return this.http.post<UserSchema>(`${this.API_URL}/users`, user);
  }

  toDataTable(users: Array<UserSchema>): Array<IUserDatatable> {
    return users.map((u) => ({
      name: `${u.firstname ?? ''} ${u.lastname ?? ''}`,
      email: u.email,
      role: (u.role as RoleSchema).name,
      confirmed: u.confirmed === true ? 'Si' : 'No',
      blocked: u.blocked === true ? 'No' : 'Si',
      id: u.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'firstname_contains',
        field: 'Nombres',
        default: true,
      },
      {
        APIField: 'lastname_contains',
        field: 'Apellidos',
        default: false,
      },
      {
        APIField: 'email_contains',
        field: 'Correo',
        default: false,
      },
      {
        APIField: 'role.name_contains',
        field: 'Rol',
        default: false,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of(['Nombres y Apellidos', 'Correo', 'Rol', 'Confirmado', 'Activo']);
  }

  forgotPassword(user: UserSchema): Observable<UserSchema> {
    return this.http.post<UserSchema>(
      `${this.API_URL}/auth/forgot-password`,
      user,
    );
  }

  resetPassword(user: UserSchema): Observable<UserSchema> {
    return this.http.post<UserSchema>(
      `${this.API_URL}/auth/reset-password`,
      user,
    );
  }

  createAccountAffiliate(id: Partial<AffiliateSchema>): Observable<UserSchema> {
    return this.http.post<UserSchema>(`${this.API_URL}/users/affiliates`, id);
  }

  findByEmail(email: string): Observable<UserSchema[]> {
    return this.http.get<UserSchema[]>(`${this.API_URL}/users/?email=${email}`);
  }
}
