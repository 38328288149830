import { BaseSchema, DepartmentSchema, RouteSchema } from '../';

export class MunicipalitySchema extends BaseSchema {
  code: string;
  name: string;
  department: string | DepartmentSchema;
  from_routes: Array<RouteSchema> | Array<string>;

  constructor(params: {
    code: string;
    name: string;
    department: string | DepartmentSchema;
    from_routes?: Array<RouteSchema> | Array<string>;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.name = params.name;
    this.code = params.code;
    this.department = params.department;
    this.from_routes = params.from_routes;
  }
}
