import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, tap } from 'rxjs';
declare var $: any;
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BnNgIdleService } from 'bn-ng-idle';

import { environment } from 'src/environments/environment';
import { AuthService, DocumentAlertService, UserService } from '@services/core';
import { UserSchema } from '@schemas/core';
// eslint-disable-next-line max-len
import { ModalSessionExpirationNotificationComponent } from '../full/components/modal-session-expiration-notification/modal-session-expiration-notification.component';
import { PERMISSIONS, PermissionService } from '@shared/rbac';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
})
export class FullComponent implements OnInit, OnDestroy {
  readonly env = environment;
  public config: PerfectScrollbarConfigInterface = {};
  time = environment.INACTIVE_USER_TIME_NOTIFICATION;
  subscription = new Subscription();

  constructor(
    public router: Router,
    private userService: UserService,
    private authService: AuthService,
    private modalService: NgbModal,
    private bnIdle: BnNgIdleService,
    private documentAlertService: DocumentAlertService,
    private permissionService: PermissionService,
  ) {}

  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;

  options = {
    theme: 'dark', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // two possible values: vertical, horizontal
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
  };

  userProfile$: Observable<UserSchema> = new Observable();
  hasPermissionToViewMessages = false;
  today = Date.now();

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/classic']);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
    this.loadUserProfile();

    /**
     * Escucha por inactividad del usaurio;
     * Si se alcanza el tiempo de inactividad establecido en
     * configuración, se dispara la modal de inactividad.
     */
    const subIdle = this.bnIdle
      .startWatching(this.time)
      .subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          this.openSessionExpirationNotificacionModal();
        }
      });

    this.subscription.add(subIdle);

    const sub = this.permissionService
      .hasPermission(PERMISSIONS.SECURITY_MESSAGES_LIST)
      .pipe(
        tap((p) => {
          this.hasPermissionToViewMessages = p;
          if (this.hasPermissionToViewMessages) {
            // Revisa si hay documentos por vencer
            this.documentAlertService.checkForExpiredAffiliateDocuments();

            // Revisa si hay documentos de empresas por vencer
            this.documentAlertService.checkForExpiredCompanyDocuments();
          }
        }),
      )
      .subscribe();
    this.subscription.add(sub);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: string) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  handleClick(event: boolean) {
    this.showMobileMenu = event;
  }

  loadUserProfile(): void {
    this.userProfile$ = this.userService.userProfile$;
  }

  logout(): void {
    this.bnIdle.stopTimer();
    this.authService.logoutAndRedirect();
  }

  private openSessionExpirationNotificacionModal(): void {
    const modalRef = this.modalService.open(
      ModalSessionExpirationNotificationComponent,
      {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
      },
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
