/* eslint-disable no-underscore-dangle */
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { KeyValue } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DeleteDialogComponent } from '@shared/delete-dialog/delete-dialog.component';
import { ModalButtonsStateEnum } from '@shared/enums';
import { LoadingService } from '@services/core';
import {
  IFilterDataTable,
  IStateDataTable,
} from './state.data-table.interface';
import { PAGE, PAGE_SIZE, TOTAL_RECORDS } from '@shared/consts';
import { IToSelect } from '@shared/interfaces';
import {
  debounce,
  distinctUntilChanged,
  fromEvent,
  Subscription,
  timer,
} from 'rxjs';
@Component({
  selector: 'app-data-table-v2',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements AfterViewInit, OnDestroy {
  @Input() tableHead: string[];
  // Opciones del select
  @Input() set tableFilter(options: IFilterDataTable[]) {
    this.filterOptions = options.map((o) => {
      const option = {
        id: o.APIField,
        description: o.field,
      };

      if (o.default === true) {
        this.filterOptionSelected = [option];
      }
      return option;
    });
  }

  @Input() title = '';
  @Input() showAddButton = true;
  @Input() showEditButton = true;
  @Input() showDelButton = true;
  @Input() showActions = true;
  @Input() enableSearch = true;
  @Input() maxSize = 5;

  @Input() state: IStateDataTable = {
    page: PAGE,
    pageSize: PAGE_SIZE,
    totalRecords: TOTAL_RECORDS,
    searchTerm: null,
  };
  @Output() stateChange = new EventEmitter<IStateDataTable>();

  @Output() addRow: EventEmitter<boolean> = new EventEmitter(true);
  @Output() updateRow: EventEmitter<string> = new EventEmitter(true);
  @Output() deleteRow: EventEmitter<string> = new EventEmitter(true);

  @ContentChild('actionsTemplate', { static: false })
  actionsTemplate: TemplateRef<any>;
  @ContentChild('additionalColumnsHead', { static: false })
  additionalColumnsHead: TemplateRef<any>;
  @ContentChild('additionalColumns', { static: false })
  additionalColumns: TemplateRef<any>;
  modalButtonsStateEnum = ModalButtonsStateEnum;

  get tableData(): Array<any> {
    return this._tableData;
  }
  @Input() set tableData(value: Array<any>) {
    if (value) {
      this._tableData = [...value];
      this.tableDataFilter = [...this._tableData];
      this.totalLengthTableData = this.state.totalRecords;
    }
  }

  @Input() set loading(value: boolean) {
    if (value === true) {
      this.loadingService.startLoading();
    } else {
      this.loadingService.stopLoading();
    }
  }

  get page(): number {
    return this.state.page;
  }
  set page(pageNumber: number) {
    this.state = { ...this.state, page: pageNumber };
    this.stateChange.emit(this.state);
  }

  get pageSize(): number {
    return this.state.pageSize;
  }
  set pageSize(pageSizeLength: number) {
    this.state = { ...this.state, pageSize: pageSizeLength };
    this.stateChange.emit(this.state);
  }

  tableDataFilter: Array<any>;
  totalLengthTableData = 0;

  private _tableData: Array<any>;
  private _searchTerm = '';
  private _filterOptionSelected: IToSelect[];

  get searchTerm(): string {
    return this._searchTerm;
  }
  set searchTerm(val: string) {
    this._searchTerm = val;
    if (this._searchTerm && this.filterOptionSelected) {
      this.state = {
        ...this.state,
        searchTerm: {
          APIField: this.filterOptionSelected[0].id.toString(),
          term: this._searchTerm,
        },
      };
    } else {
      this.state = { ...this.state, searchTerm: null };
    }

    this.stateChange.emit(this.state);
  }

  get filterOptionSelected(): IToSelect[] {
    return this._filterOptionSelected;
  }
  set filterOptionSelected(val: IToSelect[]) {
    this._filterOptionSelected = val;

    if (this.searchInput) {
      this.searchTerm = '';
      this.searchInput.nativeElement.value = '';
      this.searchInput.nativeElement.focus();
    }

    if (this._filterOptionSelected && this.searchTerm) {
      this.state = {
        ...this.state,
        searchTerm: {
          ...this.state.searchTerm,
          APIField: this._filterOptionSelected[0].id.toString(),
        },
      };
    }
  }

  filterOptions: IToSelect[];
  loading$ = this.loadingService.loadingStatus$;
  subscriptions = new Subscription();
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    private modalService: NgbModal,
    private loadingService: LoadingService,
  ) {}

  ngAfterViewInit(): void {
    // Para darle un delay a la escritura en el search antes de buscar
    const searchChange = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounce(() => timer(1000)),
        distinctUntilChanged(
          null,
          (event: Event) => (event.target as HTMLInputElement).value,
        ),
      )
      .subscribe({
        next: (value) => {
          this.searchTerm = (value.target as HTMLInputElement).value;
        },
      });
    this.subscriptions.add(searchChange);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  preserveOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>,
  ): number => 0;

  add(value: boolean): void {
    this.addRow.emit(value);
  }

  update(value: string): void {
    this.updateRow.emit(value);
  }

  delete(value: string): void {
    const modalRef = this.modalService.open(DeleteDialogComponent, {
      size: 'md',
    });

    modalRef.result.then((result) => {
      if (result === this.modalButtonsStateEnum.YES) {
        this.deleteRow.emit(value);
      }
    });
  }
}
