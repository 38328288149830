import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map, Observable, of, tap } from 'rxjs';

import { BaseService } from '../base/base.service';
import { ErrorSchema, RoleSchema } from '@schemas/core';
import { IRoleDatatable, IToSelect } from '@shared/interfaces';
import { PermissionsSchema } from 'src/app/schemas/permission/permission.schema';
import { IPermissionEdnpoint, IPermissionModule } from '@shared/rbac';
import { NotifierService } from '../notifier/notifier.service';
import { MSG } from '@shared/consts';

// Colocar siempre en minúsculas
const DONT_SHOW_ROLES_NAMES = ['authenticated', 'public'];
// Colocar siempre en minúsculas
const INMUTABLE_ROLES_NAMES = ['authenticated', 'public'];
@Injectable({
  providedIn: 'root',
})
export class RoleService extends BaseService {
  constructor(protected http: HttpClient, private notifier: NotifierService) {
    super(http);
  }

  find(): Observable<RoleSchema[]> {
    return this.http
      .get<{ roles: RoleSchema[] }>(`${this.API_URL}/users-permissions/roles`)
      .pipe(
        map((r: { roles: RoleSchema[] }) =>
          r.roles
            .map((role) => {
              role.frontend_permissions = role.frontend_permissions ?? [];
              return role;
            })
            .filter((role) => !this.isDontShowRole(role.name)),
        ),
      );
  }

  findOne(id: string): Observable<RoleSchema> {
    return this.http
      .get<{ role: RoleSchema }>(
        `${this.API_URL}/users-permissions/roles/${id}`,
      )
      .pipe(
        map((r) => {
          r.role.frontend_permissions = r.role.frontend_permissions ?? [];
          return r.role;
        }),
        filter((r) => !this.isDontShowRole(r.name)),
      );
  }

  create(
    role: Pick<
      RoleSchema,
      'name' | 'frontend_permissions' | 'description' | 'permissions'
    >,
  ): Observable<{ ok: boolean } | ErrorSchema> {
    return this.http.post<{ ok: boolean } | ErrorSchema>(
      `${this.API_URL}/users-permissions/roles`,
      role,
    );
  }

  update(
    role: Pick<
      RoleSchema,
      'id' | 'name' | 'frontend_permissions' | 'description' | 'permissions'
    >,
  ): Observable<{ ok: boolean } | ErrorSchema> {
    // TODO: Esto debería mejorarse validando en backend y capturando el error en frontend
    if (this.isInmutableRole(role.name)) {
      this.notifier.error(MSG.ERROR_INMUTABLE_ROL);
    }
    return this.http.put<{ ok: boolean } | ErrorSchema>(
      `${this.API_URL}/users-permissions/roles/${role.id}`,
      role,
    );
  }

  delete(id: string): Observable<{ ok: boolean } | ErrorSchema> {
    return this.http.delete<{ ok: boolean } | ErrorSchema>(
      `${this.API_URL}/users-permissions/roles/${id}`,
    );
  }

  toSelect(roles: RoleSchema[]): IToSelect[] {
    return roles.map((r) => ({ id: r.id, description: r.name }));
  }

  toDataTable(roles: RoleSchema[]): IRoleDatatable[] {
    return roles.map((r) => ({
      name: r.name,
      description: r.description,
      id: r.id,
    }));
  }

  getTableHead(): Observable<string[]> {
    return of(['Rol', 'Descripción']);
  }

  /**
   * Get all api with default permission
   *
   * @return {*}  {Observable<PermissionSchema>}
   * @memberof RoleService
   */
  getPermissions(): Observable<PermissionsSchema> {
    return this.http.get<PermissionsSchema>(
      `${this.API_URL}/users-permissions/permissions`,
    );
  }

  /**
   * Prepare permissions to save
   *
   * @param {PermissionsSchema} permissions
   * @param {IPermissionModule[]} permissionsSelected
   * @return {*}  {PermissionsSchema}
   * @memberof RoleService
   */
  setPermissionsToSave(
    permissions: PermissionsSchema,
    permissionsSelected: IPermissionModule[],
  ): PermissionsSchema {
    const permissionsToSave = { ...permissions };
    permissionsSelected.forEach((ps) => {
      ps.endpoints_required.forEach((er) => {
        try {
          permissionsToSave.permissions[er.module].controllers[er.controller][
            er.action
          ].enabled = true;
        } catch (err) {
          this.notifier.error(MSG.ERROR_UNEXPECTED);
          throw err;
        }
      });
    });
    return permissionsToSave;
  }

  /**
   * Prepare default permissions
   *
   * @param {PermissionsSchema} permissions
   * @return {*}  {PermissionsSchema}
   * @memberof RoleService
   */
  setDefaultPermissionsToSave(
    permissions: PermissionsSchema,
  ): PermissionsSchema {
    const defaultPermissionsToSave = { ...permissions };
    const defaultPermissions: IPermissionEdnpoint[] = [
      // Obtener perfil propio
      { module: 'users-permissions', controller: 'user', action: 'me' },
      // Actualizar perfil propio
      { module: 'users-permissions', controller: 'user', action: 'updateMe' },
      // Mensajes del sistema
      { module: 'application', controller: 'message', action: 'create' },
    ];

    defaultPermissions.forEach((df) => {
      try {
        defaultPermissionsToSave.permissions[df.module].controllers[
          df.controller
        ][df.action].enabled = true;
      } catch (err) {
        this.notifier.error(MSG.ERROR_UNEXPECTED);
        throw err;
      }
    });
    return defaultPermissionsToSave;
  }

  isDontShowRole(roleName: string): boolean {
    return DONT_SHOW_ROLES_NAMES.includes(roleName.toLowerCase());
  }

  isInmutableRole(roleName: string): boolean {
    return INMUTABLE_ROLES_NAMES.includes(roleName.toLowerCase());
  }
}
