import {
  BaseSchema,
  DocumentTypeSchema,
  VehicleSchema,
  CompanySchema,
  DocumentSubjectSchema,
} from '../';

export class AffiliateSchema extends BaseSchema {
  affiliate_number: string;
  document_number: string;
  firstname: string;
  lastname: string;
  company_name: string;
  email: string;
  email_alternative: string;
  birth_date: string;
  address: string;
  city: string;
  cell_phone_number: string;
  home_phone_number: string;
  cell_phone_number_alternative: string;
  fullname_contact: string;
  cell_phone_number_contact: string;
  observations: string;
  extract_edit: boolean;
  routes_edit: boolean;
  login_portal?: boolean;
  active: boolean;
  document_type: DocumentTypeSchema | string;
  vehicles: Array<VehicleSchema> | Array<string>;
  company: CompanySchema | string;
  document_subjects?: Array<DocumentSubjectSchema> | Array<string>;

  constructor(params: {
    affiliate_number: string;
    document_number: string;
    firstname: string;
    lastname: string;
    company_name: string;
    email: string;
    email_alternative: string;
    birth_date: string;
    address: string;
    city: string;
    cell_phone_number: string;
    home_phone_number: string;
    cell_phone_number_alternative: string;
    fullname_contact: string;
    cell_phone_number_contact: string;
    observations: string;
    extract_edit: boolean;
    routes_edit: boolean;
    login_portal?: boolean;
    active: boolean;
    document_type: DocumentTypeSchema | string;
    vehicles?: Array<VehicleSchema> | Array<string>;
    company: CompanySchema | string;
    document_subjects?: Array<DocumentSubjectSchema> | Array<string>;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.affiliate_number = params.affiliate_number;
    this.document_number = params.document_number;
    this.firstname = params.firstname;
    this.lastname = params.lastname;
    this.company_name = params.company_name;
    this.email = params.email;
    this.email_alternative = params.email_alternative;
    this.birth_date = params.birth_date;
    this.address = params.address;
    this.city = params.city;
    this.cell_phone_number = params.cell_phone_number;
    this.home_phone_number = params.home_phone_number;
    this.cell_phone_number_alternative = params.cell_phone_number_alternative;
    this.fullname_contact = params.fullname_contact;
    this.cell_phone_number_contact = params.cell_phone_number_contact;
    this.observations = params.observations;
    this.extract_edit = params.extract_edit;
    this.routes_edit = params.routes_edit;
    this.login_portal = params.login_portal;
    this.active = params.active;
    this.document_type = params.document_type;
    this.vehicles = params.vehicles;
    this.company = params.company;
    this.document_subjects = params.document_subjects;
  }

  public get fullname(): string {
    return `${this.firstname} ${this.lastname}`;
  }
}
