<div id="page">
  <div
    class="auth-wrapper d-flex no-block justify-content-center align-items-center background-start"
  >
    <div *ngIf="documentExist === false">
      <div class="auth-box">
        <div class="logo m-b-20">
          <span class="db">
            <img src="assets/images/logo-light-text.svg" alt="logo" />
          </span>
        </div>
        <h3 class="text-danger text-center">
          El documento no existe, verifique el código y vuelva a intentarlo.
        </h3>
      </div>
    </div>
  </div>
</div>
