export class BaseSchema {
  published_at: Date;
  id?: string;
  created_by?: string;
  updated_by?: string;
  created_at?: string;
  updated_at?: string;

  constructor(
    published_at: Date,
    id?: string,
    created_by?: string,
    updated_by?: string,
    created_at?: string,
    updated_at?: string,
  ) {
    this.published_at = published_at;
    this.id = id;
    this.created_by = created_by;
    this.updated_by = updated_by;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }
}
