import {
  BaseSchema,
  DocumentTypeSchema,
  VehicleSchema,
  DocumentSubjectSchema,
} from '../';
import { DriverRelationTypeEnum } from '@shared/enums';

export class DriverSchema extends BaseSchema {
  document_number: string;
  firstname: string;
  lastname: string;
  cell_phone_number: string;
  home_phone_number: string;
  license_number: string;
  license_category: string;
  license_expedition_place: string;
  license_start_date: string;
  license_end_date: string;
  relation_type: DriverRelationTypeEnum;
  relation_date: string;
  social_security_date: string;
  active: boolean;
  document_type: string | DocumentTypeSchema;
  vehicles: string[] | VehicleSchema[];
  document_subjects?: Array<DocumentSubjectSchema> | Array<string>;

  constructor(params: {
    document_number: string;
    firstname: string;
    lastname: string;
    cell_phone_number: string;
    home_phone_number: string;
    license_number: string;
    license_category: string;
    license_expedition_place: string;
    license_start_date: string;
    license_end_date: string;
    relation_type: DriverRelationTypeEnum;
    relation_date: string;
    social_security_date: string;
    active: boolean;
    document_type: string | DocumentTypeSchema;
    vehicles?: string[] | VehicleSchema[];
    document_subjects?: Array<DocumentSubjectSchema> | Array<string>;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.document_number = params.document_number;
    this.firstname = params.firstname;
    this.lastname = params.lastname;
    this.cell_phone_number = params.cell_phone_number;
    this.home_phone_number = params.home_phone_number;
    this.license_number = params.license_number;
    this.license_category = params.license_category;
    this.license_expedition_place = params.license_expedition_place;
    this.license_start_date = params.license_start_date;
    this.license_end_date = params.license_end_date;
    this.relation_type = params.relation_type;
    this.relation_date = params.relation_date;
    this.social_security_date = params.social_security_date;
    this.active = params.active;
    this.document_type = params.document_type;
    this.vehicles = params.vehicles;
    this.document_subjects = params.document_subjects;
  }

  get fullname(): string {
    return `${this.firstname} ${this.lastname}`;
  }
}
