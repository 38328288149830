import {
  BaseSchema,
  ContractSchema,
  DriverSchema,
  RouteSchema,
  VehicleSchema,
} from '..';
import { TripTypeEnum } from '@shared/enums/';
import { UserSchema } from '../user/user.schema';
import { CompanySchema } from '../company/company.schema';
/**
 * Schema to manage FUEC
 *
 * Como el extracto es inmutable se decidió guardar
 * toda la información del extracto en un json, de esta manera
 * se mantiene la integridad del documento, así la información
 * original haya cambiado en el sistema.
 *
 * Se crearon algunos setters y getters para
 * mantener sincronizada la información del modelo
 * con el json del extracto. Por motivos de rendimiento
 * en las consultas, se duplicaron estos campos.
 *
 * @export
 * @class ExtractContract
 * @extends {BaseSchema}
 */
export class ExtractContractSchema extends BaseSchema {
  correlative_field: number;
  code_field: string;
  start_date_field: string;
  end_date_field: string;
  user_field: UserSchema;
  extract_contract_field = new ExtractContractFieldSchema(
    {} as ExtractContractFieldSchema,
  );
  company: CompanySchema;
  vehicle_plate: string;

  published_at: Date;
  id?: string;
  created_by?: string;
  updated_by?: string;
  created_at?: string;
  updated_at?: string;

  constructor(params: {
    correlative_field: number;
    code_field: string;
    start_date_field: string;
    end_date_field: string;
    user_field: UserSchema;
    extract_contract_field: ExtractContractFieldSchema;
    company: CompanySchema;
    vehicle_plate: string;

    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
    created_at?: string;
    updated_at?: string;
  }) {
    super(
      params.published_at,
      params.id,
      params.created_by,
      params.updated_by,
      params.created_at,
      params.updated_at,
    );

    this.extract_contract = params.extract_contract_field;
    this.correlative = params.correlative_field;
    this.code = params.code_field;
    this.start_date = params.start_date_field;
    this.end_date = params.end_date_field;
    this.user = params.user_field;
    this.company = params.company;
    this.vehicle_plate = params.vehicle_plate;
  }

  get correlative(): number {
    return this.correlative_field;
  }

  set correlative(value: number) {
    this.correlative_field = this.extract_contract.correlative = value;
  }

  get code(): string {
    return this.code_field;
  }

  set code(value: string) {
    this.code_field = this.extract_contract.code = value;
  }

  get start_date(): string {
    return this.start_date_field;
  }

  set start_date(value: string) {
    this.start_date_field = this.extract_contract.start_date = value;
  }

  get end_date(): string {
    return this.end_date_field;
  }

  set end_date(value: string) {
    this.end_date_field = this.extract_contract.end_date = value;
  }

  get user(): UserSchema {
    return this.user_field;
  }

  set user(value: UserSchema) {
    this.user_field = this.extract_contract.user = value;
  }

  get extract_contract(): ExtractContractFieldSchema {
    return this.extract_contract_field;
  }

  set extract_contract(value: ExtractContractFieldSchema) {
    this.extract_contract_field.correlative = this.correlative =
      value.correlative;
    this.extract_contract_field.code = this.code_field = value.code;
    this.extract_contract_field.start_date = this.start_date_field =
      value.start_date;
    this.extract_contract_field.end_date = this.end_date_field = value.end_date;
    this.extract_contract_field.user = this.user_field = value.user;
    this.extract_contract_field.trip_type = value.trip_type;
    this.extract_contract_field.contract = value.contract;
    this.extract_contract_field.routes = value.routes;
    this.extract_contract_field.drivers = value.drivers;
    this.extract_contract_field.vehicle = value.vehicle;
    this.extract_contract_field.operation_card_number =
      value.operation_card_number;
    this.extract_contract_field.has_digital_signature =
      value.has_digital_signature;
    this.extract_contract_field.digital_signature_base64 =
      value.digital_signature_base64;
    this.extract_contract_field.logo_company_base64 = value.logo_company_base64;
    this.extract_contract_field.file_base64 = value.file_base64;
  }
}

/**
 * Shema to manage JSON FUEC
 *
 * Guarda toda la información del extracto de contrato.
 * Dado que un extracto es inmutable, entonces
 * se decidió guardar una copia exacta en un json
 *
 * @export
 * @class ExtractContractFieldSchema
 */
export class ExtractContractFieldSchema {
  correlative: number;
  code: string;
  trip_type: TripTypeEnum;
  contract: ContractSchema;
  routes: RouteSchema[];
  drivers: DriverSchema[];
  vehicle: VehicleSchema;
  start_date: string;
  end_date: string;
  user: UserSchema;
  operation_card_number: string;
  has_digital_signature: boolean;
  digital_signature_base64: string;
  logo_company_base64: string;
  file_base64: string;

  constructor(params: {
    correlative: number;
    code: string;
    trip_type: TripTypeEnum;
    contract: ContractSchema;
    routes: RouteSchema[];
    drivers: DriverSchema[];
    vehicle: VehicleSchema;
    start_date: string;
    end_date: string;
    user: UserSchema;
    operation_card_number: string;
    has_digital_signature: boolean;
    digital_signature_base64: string;
    logo_company_base64: string;
    file_base64: string;
  }) {
    this.correlative = params.correlative;
    this.code = params.code;
    this.trip_type = params.trip_type;
    this.contract = params.contract;
    this.routes = params.routes;
    this.drivers = params.drivers;
    this.vehicle = params.vehicle;
    this.start_date = params.start_date;
    this.end_date = params.end_date;
    this.user = params.user;
    this.operation_card_number = params.operation_card_number;
    this.has_digital_signature = params.has_digital_signature;
    this.digital_signature_base64 = params.digital_signature_base64;
    this.logo_company_base64 = params.logo_company_base64;
    this.file_base64 = params.file_base64;
  }
}
