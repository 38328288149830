import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import {
  CompanySchema,
  ContractObjectSchema,
  ContractorSchema,
  ContractSchema,
} from '@schemas/core';
import { IContractDatatable, IToSelect } from '@shared/interfaces';
import { map } from 'rxjs/operators';
import { DateTimeService } from '../datetime/datetime.service';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class ContractService extends BaseService {
  constructor(
    protected http: HttpClient,
    private dateTimeService: DateTimeService,
  ) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<ContractSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'contract_number:DESC')
      .set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<ContractSchema[]>(`${this.API_URL}/contracts`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams();

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/contracts/count`, {
      params,
    });
  }

  findOne(id: string): Observable<ContractSchema> {
    return this.http.get<ContractSchema>(`${this.API_URL}/contracts/${id}`);
  }

  /**
   * Find duplicate contract by contract_number.
   * Can exclude registers for find duplicate
   * Return array boolean on contract_number
   *
   */
  findDuplicate(
    data: {
      contract_number: string;
    },
    company: string,
    exclude: string[],
  ): Observable<Array<boolean>> {
    return combineLatest([
      this.findDuplicateField(
        'contract_number',
        data.contract_number,
        company,
        exclude,
      ),
    ]).pipe(map(([contract_number]) => [contract_number]));
  }

  /**
   * Return true if the field is duplicate
   * Can exclude registers for find duplicate
   *
   *
   */
  private findDuplicateField(
    field: string,
    data: string,
    company: string,
    exclude: string[],
  ): Observable<boolean> {
    return this.http
      .get<ContractSchema[]>(
        `${this.API_URL}/contracts?${field}=${data}&company=${company}`,
      )
      .pipe(
        map((r) =>
          r.find(
            (x) =>
              !exclude.includes(x.id.toString()) &&
              x[field].toUpperCase() === data.toUpperCase(),
          )
            ? true
            : false,
        ),
      );
  }

  /**
   * Busca los contratos vigentes de una placa en específico
   * @param plate
   * @returns Observable<ContractSchema[]>
   */

  findContractCurrentPlate(plate: string): Observable<ContractSchema[]> {
    const date = this.dateTimeService.getStringFormatISOFromDate(
      this.dateTimeService.getDate(),
    );
    return this.http.get<ContractSchema[]>(
      `${this.API_URL}/contracts?vehicles.plate=${plate}&end_date_gte=${date}`,
    );
  }
  /**
   * Busca los contratos vigentes que poseean la opcion all_vehicles activa
   * de una empresa especifica
   * @param id
   * @returns  Observable<ContractSchema[]
   */
  findContractCurrentByCompany(id: string): Observable<ContractSchema[]> {
    const date = this.dateTimeService.getStringFormatISOFromDate(
      this.dateTimeService.getDate(),
    );
    return this.http.get<ContractSchema[]>(
      `${this.API_URL}/contracts?all_vehicles=true&company.id=${id}&end_date_gte=${date}`,
    );
  }

  create(contract: ContractSchema): Observable<ContractSchema> {
    return this.http.post<ContractSchema>(
      `${this.API_URL}/contracts`,
      contract,
    );
  }

  createWithFormData(formData: FormData): Observable<ContractSchema> {
    return this.http.post<ContractSchema>(
      `${this.API_URL}/contracts`,
      formData,
    );
  }

  update(contract: ContractSchema): Observable<ContractSchema> {
    return this.http.put<ContractSchema>(
      `${this.API_URL}/contracts/${contract?.id}`,
      contract,
    );
  }

  updateWithFormData(
    id: string,
    formData: FormData,
  ): Observable<ContractSchema> {
    return this.http.put<ContractSchema>(
      `${this.API_URL}/contracts/${id}`,
      formData,
    );
  }

  delete(id: string): Observable<ContractSchema> {
    return this.http.delete<ContractSchema>(`${this.API_URL}/contracts/${id}`);
  }

  toDataTable(contracts: Array<ContractSchema>): Array<IContractDatatable> {
    return contracts.map((c) => ({
      contract_number: c.contract_number,
      contractor_name: (c?.contractor as ContractorSchema)?.name,
      start_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        c.start_date,
      ),
      end_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        c.end_date,
      ),
      contract_object_description: (c?.contract_object as ContractObjectSchema)
        ?.description,
      company_name: (c?.company as CompanySchema)?.name,
      document_file_url: c?.file_contract?.url ?? '',
      id: c.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'contract_number_contains',
        field: '# Contrato',
        default: false,
      },
      {
        APIField: 'contractor.name_contains',
        field: 'Contratante',
        default: true,
      },
      {
        APIField: 'contract_object.description_contains',
        field: 'Objeto de contrato',
        default: false,
      },
      {
        APIField: 'company.name_contains',
        field: 'Empresa',
        default: false,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of([
      '# Contrato',
      'Contratante',
      'Fecha de Inicio',
      'Fecha de Fin',
      'Objeto de Contrato',
      'Empresa',
    ]);
  }

  toSelect(contracts: ContractSchema[]): IToSelect[] {
    return contracts.map((c) => ({
      id: c.id,
      description: `${c.contract_number} - ${
        (c.contractor as ContractorSchema).name
      } - ${(c.contract_object as ContractObjectSchema).description} `,
    }));
  }

  generateContractNumber(correlative: number): number {
    return correlative + 1;
  }
}
