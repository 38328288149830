import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base/base.service';
import { DocumentRequestedSchema, DocumentDefSchema } from '@schemas/core';
import { IDocumentRequestedDatatable } from '@shared/interfaces';
import { DocumentRequestedEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class DocumentRequestedService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  private findBySubject(
    subject: DocumentRequestedEnum,
  ): Observable<DocumentRequestedSchema[]> {
    return this.http.get<DocumentRequestedSchema[]>(
      `${this.API_URL}/document-requesteds?subject=${subject}`,
    );
  }

  findByAffiliate(): Observable<DocumentRequestedSchema[]> {
    return this.findBySubject(DocumentRequestedEnum.AFFILIATE);
  }

  findByVehicle(): Observable<DocumentRequestedSchema[]> {
    return this.findBySubject(DocumentRequestedEnum.VEHICLE);
  }

  findByDriver(): Observable<DocumentRequestedSchema[]> {
    return this.findBySubject(DocumentRequestedEnum.DRIVER);
  }

  findByCompany(): Observable<DocumentRequestedSchema[]> {
    return this.findBySubject(DocumentRequestedEnum.COMPANY);
  }

  update(
    documentRequested: DocumentRequestedSchema,
  ): Observable<DocumentRequestedSchema> {
    return this.http.put<DocumentRequestedSchema>(
      `${this.API_URL}/document-requesteds/${documentRequested?.id}`,
      documentRequested,
    );
  }

  toDataTable(
    documentDef: Array<DocumentDefSchema>,
  ): Array<IDocumentRequestedDatatable> {
    return documentDef.map((d) => ({
      document_name: d.name,
      validate_in_contract_extract:
        d.validate_in_contract_extract === true ? 'Si' : 'No',
      id: d.id,
    }));
  }

  getTableHead(): Observable<string[]> {
    return of(['Nombre de Documento', 'Valida en extracto de contrato']);
  }
}
