import { UserSchema } from '../user/user.schema';

export interface LoginSchema {
  jwt: string;
  user: UserSchema;
  created_a: Date;
  updated_at: Date;
}

export class LoginNewSchema {
  identifier: string;
  password: string;

  constructor(identifier: string, password: string) {
    this.identifier = identifier;
    this.password = password;
  }
}
