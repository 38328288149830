import { NgModule } from '@angular/core';
import {
  NgbModule,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
} from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { ToastrModule } from 'ngx-toastr';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { VerticalNavigationComponent } from './vertical-header/vertical-navigation.component';
import { VerticalSidebarComponent } from './vertical-sidebar/vertical-sidebar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { HorizontalNavigationComponent } from './horizontal-header/horizontal-navigation.component';
import { HorizontalSidebarComponent } from './horizontal-sidebar/horizontal-sidebar.component';
import { SpinnerComponent } from './spinner.component';
import { DataTableComponent } from './data-table/data-table.component';
import { DataTableComponent as DataTableComponentv2 } from './data-table-v2/data-table.component';
import { ngbDateParserFormatter } from './parsers/ngbdatepicker.parser';
import { CustomDatepickerI18n, I18n } from './parsers/datepicker-i18n';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { MaskInputDirective } from './mask-datepicker.directive';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MenuCardComponent } from './menu-card/menu-card.component';
import { LoadingComponent } from './loading/loading.component';
import { EnvironmentInfoComponent } from './environment-info/environment-info.component';
import { ProfileComponent } from './profile/profile.component';
import { PermissionDirective } from '@shared/rbac';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    SpinnerComponent,
    VerticalNavigationComponent,
    BreadcrumbComponent,
    VerticalSidebarComponent,
    HorizontalNavigationComponent,
    HorizontalSidebarComponent,
    DataTableComponent,
    DataTableComponentv2,
    DeleteDialogComponent,
    MaskInputDirective,
    MenuCardComponent,
    LoadingComponent,
    EnvironmentInfoComponent,
    ProfileComponent,
    PermissionDirective,
  ],
  imports: [
    NgbModule,
    PerfectScrollbarModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 20,
      space: -4,
      outerStrokeWidth: 4,
      innerStrokeWidth: 2,
      outerStrokeColor: '#06d79c',
      innerStrokeColor: '#e7e8ea',
      showSubtitle: false,
      titleFontSize: '13',
      animationDuration: 300,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
    ErrorTailorModule.forRoot({
      errors: {
        useValue: {
          required: 'Este campo es obligatorio',
          email: 'El correo electrónico no es válido',
          confirmedValidator: 'Las contraseñas no coinciden',
          minlength: ({ requiredLength, actualLength }) =>
            `Mínimo ${requiredLength} caracteres`,
          invalidAddress: (error) => `La dirección no es válida`,
          maxlength: ({ requiredLength, actualLength }) =>
            `Máximo ${requiredLength} caracteres`,
        },
      },
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      blurPredicate(element): boolean {
        return (
          element.tagName === 'INPUT' ||
          element.tagName === 'SELECT' ||
          element.tagName === 'TEXTAREA'
        );
      },
    }),
    NgxMaskModule.forRoot(),
  ],
  exports: [
    SpinnerComponent,
    VerticalNavigationComponent,
    BreadcrumbComponent,
    VerticalSidebarComponent,
    HorizontalNavigationComponent,
    HorizontalSidebarComponent,
    DataTableComponent,
    DataTableComponentv2,
    MenuCardComponent,
    MaskInputDirective,
    NgCircleProgressModule,
    ErrorTailorModule,
    LoadingComponent,
    EnvironmentInfoComponent,
    ProfileComponent,
    PermissionDirective,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: NgbDateParserFormatter, useClass: ngbDateParserFormatter },
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
  bootstrap: [],
})
export class SharedModule {}
