import { ActionSchema } from '../permission/permission.schema';

export interface RoleSchema {
  id: number;
  name: string;
  description: string;
  type: string;
  frontend_permissions: number[];
  permissions: Record<string, Record<string, Record<string, ActionSchema>>>;
}

export class RoleNewSchema implements RoleSchema {
  id: number;
  name: string;
  description: string;
  type: string;
  frontend_permissions: number[];
  permissions: Record<string, Record<string, Record<string, ActionSchema>>>;
}
