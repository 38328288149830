import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouteInfo } from '../vertical-sidebar/vertical-sidebar.metadata';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.css'],
})
export class MenuCardComponent {
  @Input() menu: RouteInfo;
  @Output() itemMenu: EventEmitter<string> = new EventEmitter(true);

  constructor() {}

  addItemMenu(title: string): void {
    this.itemMenu.emit(title);
  }
}
