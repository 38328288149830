import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BnNgIdleService } from 'bn-ng-idle';

import { AuthService } from '@services/core';
import { ModalButtonsStateEnum } from '@shared/enums';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-session-expiration-notification',
  templateUrl: './modal-session-expiration-notification.component.html',
  styleUrls: ['./modal-session-expiration-notification.component.scss'],
})
export class ModalSessionExpirationNotificationComponent implements OnInit {
  SessionExpirationNotificacionModal: any;
  modalButtonsStateEnum = ModalButtonsStateEnum;
  readonly SESSION_EXPIRATION_TIME_NOTIFICACION =
    environment.SESSION_EXPIRATION_TIME_NOTIFICACION;

  constructor(
    public activeModal: NgbActiveModal,
    public router: Router,
    private authService: AuthService,
    private bnIdle: BnNgIdleService,
  ) {}

  ngOnInit(): void {
    this.SessionExpirationNotificacionModal = setTimeout(() => {
      clearTimeout(this.SessionExpirationNotificacionModal);
      this.activeModal.close(this.modalButtonsStateEnum.CLOSE);
      this.logout();
    }, this.SESSION_EXPIRATION_TIME_NOTIFICACION);
  }

  continue() {
    clearTimeout(this.SessionExpirationNotificacionModal);
    this.activeModal.close(this.modalButtonsStateEnum.CLOSE);
    this.bnIdle.resetTimer();
  }

  logout(): void {
    this.bnIdle.stopTimer();
    this.authService.logoutAndRedirect();
  }
}
