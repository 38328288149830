import {
  IAffiliateDocumentsUpdated,
  IDriverDocumentsUpdated,
  IVehicleDocumentsUpdated,
} from '@shared/interfaces';

class Msg {
  readonly ERROR_DUPLICATE_ROLE_NAME =
    'El nombre de rol ya se encuentra registrado.';
  readonly ERROR_ROLE_WITH_USERS =
    'El rol tiene usuarios asociados, no se puede eliminar.';
  readonly ERROR_HAS_NOT_PERMISSION_FOR_THIS_PAGE =
    'No tiene permisos para ingresar a esta página';
  // Error inesperado, generalmente es un error grave y se debe
  // contactar al administrador del sistema
  readonly ERROR_UNEXPECTED =
    'Ocurrió un error inesperado, por favor contacte al administrador del sistema';
  readonly ERROR_INMUTABLE_ROL =
    'Este rol es inmutable, no puede ser actualizado ni eliminado';
  // Mensaje de validación de documentos de vehículo al generar FUEC
  readonly FUEC_VEHICLE_DOCUMENTS_OUTDATE = (
    vehiclesDocsOutDate: IVehicleDocumentsUpdated[],
  ) => {
    let msg = '';
    vehiclesDocsOutDate.forEach((v) => {
      msg = msg ? msg + `<br>` : '';
      msg += `Los documentos del vehículo con placa 
      <a href="/document-management/documents/vehicles/${v.vehicle.id}">
      ${v.vehicle.plate}</a>
       estarán vencidos para la fecha de finalización de FUEC.`;
    });
    return msg;
  };

  // Mensaje de validación de documentos de afiliado al generar FUEC
  readonly FUEC_AFFILIATE_DOCUMENTS_OUTDATE = (
    affiliatesDocsOutDate: IAffiliateDocumentsUpdated[],
  ) => {
    let msg = '';
    affiliatesDocsOutDate.forEach((a) => {
      msg = msg ? msg + `<br>` : '';
      msg += `Los documentos del afiliado con número interno 
      <a href="/document-management/documents/affiliates/${a.affiliate.id}">
      ${a.affiliate.affiliate_number}</a>
       estarán vencidos para la fecha de finalización de FUEC.`;
    });
    return msg;
  };

  // Mensaje de validación de documentos de conductores al generar FUEC
  readonly FUEC_DRIVERS_DOCUMENTS_OUTDATE = (
    driversDocsOutDate: IDriverDocumentsUpdated[],
  ) => {
    let msg = '';
    driversDocsOutDate.forEach((d) => {
      msg = msg ? msg + `<br>` : '';
      msg += `Los documentos del conductor con número de documento  
      <a href="/document-management/documents/drivers/${d.driver.id}">
      ${d.driver.document_number}</a>
       estarán vencidos para la fecha de finalización de FUEC.`;
    });
    return msg;
  };
}

export const MSG = new Msg();
