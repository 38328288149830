import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import {
  AffiliateSchema,
  CompanySchema,
  DocumentSubjectSchema,
  DocumentTypeSchema,
} from '@schemas/core';

import { IAffiliateDatatable, IToSelect } from '@shared/interfaces';
import { map } from 'rxjs/operators';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class AffiliateService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<AffiliateSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'firstname:ASC,lastname:ASC')
      .set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<AffiliateSchema[]>(`${this.API_URL}/affiliates`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams().set('_sort', 'firstname:ASC,lastname:ASC');

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/affiliates/count`, {
      params,
    });
  }

  /**
   * Busca afiliados según el array de ids que se pasa
   * por parámetro
   *
   * @return Observable<AffiliateSchema[]>
   * @memberof AffiliateService
   */
  findByIds(ids: string[]): Observable<AffiliateSchema[]> {
    const query = ids.map((i) => `id_in=${i}`).join('&');
    return this.http.get<AffiliateSchema[]>(
      `${this.API_URL}/affiliates/?${query}`,
    );
  }

  findOne(id: string): Observable<AffiliateSchema> {
    return this.http.get<AffiliateSchema>(`${this.API_URL}/affiliates/${id}`);
  }

  /**
   * Find duplicate affiliate by affiliate_number or document_number.
   * Can exclude registers for find duplicate
   * Return array boolean on this order affiliate_number, document_number
   *
   */
  findDuplicate(
    data: {
      affiliate_number: string;
      document_number: string;
    },
    exclude: string[],
  ): Observable<Array<boolean>> {
    return combineLatest([
      this.findDuplicateField(
        'affiliate_number',
        data.affiliate_number,
        exclude,
      ),
      this.findDuplicateField('document_number', data.document_number, exclude),
    ]).pipe(
      map(([affiliate_number, document_number]) => [
        affiliate_number,
        document_number,
      ]),
    );
  }

  /**
   * Return true if the field is duplicate
   * Can exclude registers for find duplicate
   *
   * En la actualidad Strapi no tiene una forma de buscar
   * un campo igual que no sea case sensitive, por eso se usa contains.
   *
   */
  private findDuplicateField(
    field: string,
    data: string,
    exclude: string[],
  ): Observable<boolean> {
    return this.http
      .get<AffiliateSchema[]>(
        `${this.API_URL}/affiliates?${field}_contains=${data}`,
      )
      .pipe(
        map((r) =>
          r.find(
            (x) =>
              !exclude.includes(x.id.toString()) &&
              x[field].toUpperCase() === data.toUpperCase(),
          )
            ? true
            : false,
        ),
      );
  }

  create(affiliate: AffiliateSchema): Observable<AffiliateSchema> {
    return this.http.post<AffiliateSchema>(
      `${this.API_URL}/affiliates`,
      affiliate,
    );
  }

  update(affiliate: Partial<AffiliateSchema>): Observable<AffiliateSchema> {
    return this.http.put<AffiliateSchema>(
      `${this.API_URL}/affiliates/${affiliate?.id}`,
      affiliate,
    );
  }

  delete(id: string): Observable<AffiliateSchema> {
    return this.http.delete<AffiliateSchema>(
      `${this.API_URL}/affiliates/${id}`,
    );
  }

  toDataTable(affiliates: Array<AffiliateSchema>): Array<IAffiliateDatatable> {
    return affiliates.map((a) => ({
      name: a.company_name
        ? `${a.company_name}`
        : `${a.firstname} ${a.lastname}`,
      document_type: (a.document_type as DocumentTypeSchema).name,
      document_number: a.document_number,
      company: (a.company as CompanySchema).name,
      id: a.id,
      documentsSubject: a.document_subjects as DocumentSubjectSchema[],
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'firstname_contains',
        field: 'Nombres',
        default: false,
      },
      {
        APIField: 'lastname_contains',
        field: 'Apellidos',
        default: false,
      },
      {
        APIField: 'company_name_contains',
        field: 'Compañía',
        default: false,
      },
      {
        APIField: 'document_type.name_contains',
        field: 'Tipo de documento',
        default: true,
      },
      {
        APIField: 'document_number_contains',
        field: 'Número de documento',
        default: false,
      },
      {
        APIField: 'company.name_contains',
        field: 'Compañía afiliada',
        default: false,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of([
      'Nombres y Apellidos',
      'Tipo de Documento',
      'Número de Documento',
      'Afiliado a',
    ]);
  }

  toSelect(affiliates: AffiliateSchema[]): IToSelect[] {
    return affiliates.map((a) => ({
      id: a.id,
      description: a.company_name
        ? `${a.document_number} - ${a.company_name}`
        : `${a.document_number} - ${a.firstname} ${a.lastname}`,
    }));
  }
}
