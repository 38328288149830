import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { ExtractContractPdfComponent } from '@modules/fuec/pages/extract-contract-pdf/extract-contract-pdf.component';
import { PermissionGuard, PERMISSIONS } from '@shared/rbac';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    canActivateChild: [PermissionGuard],
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'starter',
        loadChildren: () =>
          import('./starter/starter.module').then((m) => m.StarterModule),
      },
      {
        path: 'component',
        loadChildren: () =>
          import('./component/component.module').then(
            (m) => m.ComponentsModule,
          ),
      },
      {
        path: 'administration',
        loadChildren: () =>
          import('@modules/administration/administration.module').then(
            (m) => m.AdministrationModule,
          ),
        data: { permissionRequired: PERMISSIONS.ADMINISTRATION },
      },
      {
        path: 'document-management',
        loadChildren: () =>
          import(
            '@modules/document-management/document-management.module'
          ).then((m) => m.DocumentManagementModule),
        data: { permissionRequired: PERMISSIONS.DOCUMENT_MANAGEMENT },
      },
      {
        path: 'contract',
        loadChildren: () =>
          import('@modules/contract/contract.module').then(
            (m) => m.ContractModule,
          ),
        data: { permissionRequired: PERMISSIONS.CONTRACTS },
      },
      {
        path: 'fuec',
        loadChildren: () =>
          import('@modules/fuec/fuec.module').then((m) => m.FuecModule),
        data: { permissionRequired: PERMISSIONS.FUEC },
      },
      {
        path: 'companies-management',
        loadChildren: () =>
          import(
            '@modules/companies-management/companies-management.module'
          ).then((m) => m.CompaniesManagementModule),
        data: { permissionRequired: PERMISSIONS.COMPANIES_MANAGEMENT },
      },
      {
        path: 'security',
        loadChildren: () =>
          import('@modules/security/security.module').then(
            (m) => m.SecurityModule,
          ),
      },
    ],
  },
  {
    path: 'fuec/extract-contract/view/pdf/:code',
    component: ExtractContractPdfComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },

  {
    path: 'reset-password/:code',
    component: ResetPasswordComponent,
  },
  {
    path: 'email-confirmation',
    component: EmailConfirmationComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];
