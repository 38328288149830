import { Component, OnInit } from '@angular/core';
import { EnvironmentEnum } from '@shared/enums/environment.enum';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-environment-info',
  templateUrl: './environment-info.component.html',
  styleUrls: ['./environment-info.component.css'],
})
export class EnvironmentInfoComponent implements OnInit {
  environment = environment;
  ignoreEnvironments = [`${EnvironmentEnum.PRODUCTION}`];

  constructor() {}

  ngOnInit(): void {}

  getInfo(): string {
    let envInfo = `Ambiente: ${environment.ENV?.toUpperCase()}`;
    if (environment.ENV?.toLowerCase() === `${EnvironmentEnum.LOCALHOST}`) {
      envInfo += ` <br>Conectado al backend: ${environment.API_URL}`;
    }
    return envInfo;
  }
}
