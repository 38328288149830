import { BaseSchema, DocumentDefSchema } from '../';
import { DocumentRequestedEnum } from '@shared/enums';

export class DocumentRequestedSchema extends BaseSchema {
  subject: DocumentRequestedEnum;
  document_defs: Array<string> | Array<DocumentDefSchema>;
  constructor(params: {
    subject: DocumentRequestedEnum;
    document_defs: Array<string> | Array<DocumentDefSchema>;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.subject = params.subject;
    this.document_defs = params.document_defs;
  }
}
