<div
  class="auth-wrapper d-flex no-block justify-content-center align-items-center background-start"
>
  <div>
    <div class="auth-box">
      <div class="row">
        <div class="col-12">
          <div class="logo">
            <span class="db">
              <img src="assets/images/logo-light-text.svg" alt="logo" />
            </span>
            <h5 class="font-medium m-b-20 text-box">Crear nueva contraseña</h5>
            <span class="text-box">
              ¡Ingrese su correo electrónico y se le
              <br />
              enviarán las instrucciones!
            </span>
          </div>
          <div class="m-t-20">
            <!-- Form -->
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <!-- email -->
              <div class="form-group row">
                <div class="offset-1 col-10">
                  <input
                    type="email"
                    class="form-control form-control-lg text-box"
                    placeholder="Correo electrónico"
                    aria-label="Email"
                    aria-describedby="basic-addon1"
                    [formControlName]="FIELD_EMAIL"
                    [ngClass]="{
                      'login-error':
                        (form.controls[FIELD_EMAIL].dirty ||
                          form.controls[FIELD_EMAIL].touched) &&
                        form.controls[FIELD_EMAIL].errors &&
                        form.controls[FIELD_EMAIL].errors?.required
                    }"
                  />
                </div>
              </div>
              <!-- pwd -->

              <div class="form-group text-center">
                <div class="col-xs-12 p-b-20">
                  <button
                    class="btn btn-lg btn-login"
                    type="submit"
                    name="action"
                  >
                    ENVIAR
                  </button>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <div class="text-center">
                    <a
                      [routerLink]="['/login  ']"
                      href="javascript:void(0)"
                      id="to-recover"
                      class="text-box"
                    >
                      Ir a inicio de sesión
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
