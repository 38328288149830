<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
  <li class="nav-item d-none d-lg-block">
    <a
      (click)="toggleSidebar.emit()"
      class="nav-link sidebartoggler waves-effect waves-light"
      href="javascript:void(0)"
    >
      <i class="ti-menu"></i>
    </a>
  </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  <li
    class="nav-item dropdown"
    ngbDropdown
    placement="bottom-right"
    *ngIf="env.showWidgets"
  >
    <a
      ngbDropdownToggle
      class="nav-link dropdown-toggle"
      href="javascript:void(0)"
      id="navbarDropdown"
      s
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="flag-icon flag-icon-{{ selectedLanguage.icon }}"></i>
    </a>
    <div
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="navbarDropdown"
      ngbDropdownMenu
    >
      <span
        class="dropdown-item"
        href="#"
        *ngFor="let lang of languages"
        (click)="changeLanguage(lang)"
      >
        <i class="flag-icon flag-icon-{{ lang.icon }}"></i>
        <span>
          {{ lang.language }}
          <span *ngIf="lang.type">({{ lang.type }})</span>
        </span>
      </span>
    </div>
  </li>

  <!-- ============================================================== -->
  <!-- Search -->
  <!-- ============================================================== -->
  <li class="nav-item search-box" *ngIf="env.showWidgets">
    <a
      (click)="showSearch = !showSearch"
      class="nav-link waves-effect waves-dark"
      href="javascript:void(0)"
    >
      <i class="ti-search"></i>
    </a>
    <form
      class="app-search position-absolute"
      [ngClass]="{ 'show-search': showSearch }"
    >
      <input
        type="text"
        class="form-control"
        placeholder="Search &amp; enter"
      />
      <a class="srh-btn" (click)="showSearch = !showSearch">
        <i class="ti-close"></i>
      </a>
    </form>
  </li>
  <!-- ============================================================== -->
  <!-- Comment -->
  <!-- ============================================================== -->
  <li
    class="nav-item dropdown"
    ngbDropdown
    [autoClose]="'true'"
    placement="bottom-right"
    *ngIf="hasPermissionToViewMessages"
  >
    <a
      ngbDropdownToggle
      class="nav-link dropdown-toggle waves-effect waves-dark"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      (click)="onClickMessageTray()"
    >
      <i class="mdi mdi-bell-ring-outline"></i>
      <div class="notify" *ngIf="(hasNewMessages$ | async) ?? 0 !== 0">
        <span class="heartbit"></span>
        <span class="point"></span>
      </div>
    </a>
    <div class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu>
      <ul class="list-style-none">
        <!-- <li class="border-bottom">
          <div class="drop-title bg-info text-white">Notifications</div>
        </li> -->
        <li>
          <div class="message-center notifications" [perfectScrollbar]="config">
            <!-- Message -->
            <!-- Alerta de documentos por vencer-->
            <a
              class="message-item"
              [class.message-alert]="
                hasAffiliateDocumentsAlert || hasCompanyDocumentsAlert
              "
              ngbDropdownItem
            >
              <span class="">
                <img
                  src="assets/images/icon/ICONOS_solicitud_de_servicio_al_cliente.png"
                  alt="Documentos por vencer"
                  width="40"
                  height="40"
                />
              </span>
              <span class="mail-contnet">
                <h5
                  class="message-title"
                  *ngIf="hasAffiliateDocumentsAlert || hasCompanyDocumentsAlert"
                >
                  Existen documentos por vencer
                </h5>
                <h5
                  class="message-title"
                  *ngIf="
                    !hasAffiliateDocumentsAlert && !hasCompanyDocumentsAlert
                  "
                >
                  No existen documentos por vencer
                </h5>
                <!-- <span class="mail-desc">{{ notification.subject }}</span> -->
                <span class="text-center">
                  <button
                    [class.document-alert]="hasAffiliateDocumentsAlert"
                    class="btn"
                    [routerLink]="['/document-management/documents-alert']"
                  >
                    Afiliados
                  </button>
                  <button
                    [class.document-alert]="hasCompanyDocumentsAlert"
                    class="btn"
                    [routerLink]="[
                      '/companies-management/companies-documents-alert'
                    ]"
                  >
                    Empresas
                  </button>
                </span>
              </span>
            </a>
            <!-- Fin Alerta de documentos por vencer -->
            <ng-container *ngIf="messages.length > 0; else emptyMessages">
              <a
                class="message-item"
                ngbDropdownItem
                *ngFor="let message of messages"
                [routerLink]="message.action_url"
              >
                <span class="">
                  <img
                    src="assets/images/icon/{{ message.icon }}"
                    alt="Documentos por vencer"
                    width="40"
                    height="40"
                  />
                </span>
                <span class="mail-contnet">
                  <h5 class="message-title">{{ message.title }}</h5>
                  <span class="mail-desc">{{ message.subject }}</span>
                  <span class="time">
                    {{ message.created_at | date : 'dd-MM-yyyy h:mm a' }}
                  </span>
                </span>
              </a>
            </ng-container>
            <ng-template #emptyMessages>
              <a href="javascript:void(0)" class="message-item" ngbDropdownItem>
                <!-- <span class="btn btn-circle {{ message.btn }}">
                  <i class="{{ message.icon }}"></i>
                </span> -->
                <span class="mail-contnet text-center">
                  <h5 class="message-title">No existen mensajes nuevos</h5>
                  <!-- <span class="mail-desc">{{ message.subject }}</span>
                <span class="time">{{ message.time }}</span> -->
                </span>
              </a>
            </ng-template>
          </div>
        </li>
        <li>
          <div class="text-center message-actions">
            <button
              ngbDropdownItem
              class="btn"
              title="Ver todas las notificaciones"
              [routerLink]="['/security/message-management']"
            >
              <i class="fas fa-eye" alt="Ver"></i>
              Ver todas
            </button>
            <button
              ngbDropdownItem
              class="btn"
              title="Limpiar notificaciones"
              (click)="clearMessageTray()"
            >
              <i class="fas fa-trash-alt" alt="Limpiar"></i>
              Limpiar
            </button>
          </div>
        </li>
      </ul>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End Comment -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Messages -->
  <!-- ============================================================== -->
  <li
    class="nav-item dropdown"
    ngbDropdown
    placement="bottom-right"
    *ngIf="env.showWidgets"
  >
    <a
      ngbDropdownToggle
      class="nav-link dropdown-toggle waves-effect waves-dark"
      href="javascript:void(0)"
      id="2"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="mdi mdi-email"></i>
      <div class="notify">
        <span class="heartbit"></span>
        <span class="point"></span>
      </div>
    </a>
    <div
      class="dropdown-menu dropdown-menu-right mailbox"
      aria-labelledby="2"
      ngbDropdownMenu
    >
      <ul class="list-style-none">
        <li class="border-bottom">
          <div class="drop-title bg-info text-white">Messages</div>
        </li>
        <li>
          <div class="message-center message-body" [perfectScrollbar]="config">
            <!-- Message -->
            <a
              href="javascript:void(0)"
              class="message-item"
              *ngFor="let mymessage of mymessages"
            >
              <span class="user-img">
                <img
                  src="{{ mymessage.useravatar }}"
                  alt="user"
                  class="rounded-circle"
                />
                <span
                  class="profile-status {{ mymessage.status }} pull-right"
                ></span>
              </span>
              <span class="mail-contnet">
                <h5 class="message-title">{{ mymessage.from }}</h5>
                <span class="mail-desc">{{ mymessage.subject }}</span>
                <span class="time">{{ mymessage.time }}</span>
              </span>
            </a>
          </div>
        </li>
        <li>
          <a class="nav-link text-center link" href="javascript:void(0);">
            <b>See all e-Mails</b>
            <i class="fa fa-angle-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End Messages -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- mega menu -->
  <!-- ============================================================== -->
  <li
    class="nav-item mega-dropdown"
    ngbDropdown
    [autoClose]="false"
    *ngIf="env.showWidgets"
  >
    <a
      ngbDropdownToggle
      class="nav-link waves-effect waves-dark"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="mdi mdi-view-grid"></i>
    </a>
    <div class="dropdown-menu" ngbDropdownMenu>
      <div class="mega-dropdown-menu row">
        <div class="col-lg-3 col-xlg-2 m-b-30">
          <h4 class="m-b-20">CAROUSEL</h4>
          <!-- CAROUSEL -->
          <ngb-carousel>
            <ng-template ngbSlide>
              <img
                src="assets/images/big/img1.jpg"
                class="img-fluid"
                alt="Random first slide"
              />
              <div class="carousel-caption">
                <h3 class="text-white font-bold">First slide label</h3>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <img
                src="assets/images/big/img2.jpg"
                class="img-fluid"
                alt="Random second slide"
              />
              <div class="carousel-caption">
                <h3 class="text-white font-bold">Second slide label</h3>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <img
                src="assets/images/big/img3.jpg"
                class="img-fluid"
                alt="Random third slide"
              />
              <div class="carousel-caption">
                <h3 class="text-white font-bold">Third slide label</h3>
              </div>
            </ng-template>
          </ngb-carousel>
          <!-- End CAROUSEL -->
        </div>
        <div class="col-lg-3 m-b-30">
          <h4 class="m-b-20">ACCORDION</h4>
          <!-- Accordian -->
          <ngb-accordion
            [closeOthers]="true"
            activeIds="static-1"
            class="accordion nav-accordion"
          >
            <ngb-panel id="static-1" title="Simple">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-2">
              <ng-template ngbPanelTitle>
                <span>
                  &#9733;
                  <b>Fancy</b>
                  title &#9733;
                </span>
              </ng-template>
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-3" title="Disabled" [disabled]="true">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid.
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
        <div class="col-lg-3 m-b-30">
          <h4 class="m-b-20">CONTACT US</h4>
          <!-- Contact -->
          <form>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="exampleInputname1"
                placeholder="Enter Name"
              />
            </div>
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                placeholder="Enter email"
              />
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                id="exampleTextarea"
                rows="3"
                placeholder="Message"
              ></textarea>
            </div>
            <button type="submit" class="btn btn-info">Submit</button>
          </form>
        </div>
        <div class="col-lg-3 col-xlg-4 m-b-30">
          <h4 class="m-b-20">List style</h4>
          <!-- List style -->
          <ul class="list-style-none">
            <li>
              <a href="javascript:void(0)">
                <i class="fa fa-check text-success"></i>
                You can give link
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <i class="fa fa-check text-success"></i>
                Give link
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <i class="fa fa-check text-success"></i>
                Another Give link
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <i class="fa fa-check text-success"></i>
                Forth link
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <i class="fa fa-check text-success"></i>
                Another fifth link
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End mega menu -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-right">
    <a
      ngbDropdownToggle
      class="nav-link text-muted waves-effect waves-dark pro-pic"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span class="text-dark m-r-5 font-12">{{ userProfile.username }}</span>
      <img
        *ngIf="!userProfile.avatar_b64"
        src="assets/images/icon/ICONOS_sidebar_users.png"
        alt="user"
        class="rounded-circle avatar-empty"
        width="31"
        height="31"
      />
      <img
        *ngIf="userProfile.avatar_b64"
        [src]="userProfile.avatar_b64"
        alt="user"
        class="rounded-circle avatar-empty"
        width="31"
        height="31"
      />
    </a>
    <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
      <div class="d-flex no-block align-items-center p-15 m-b-10">
        <div>
          <img
            *ngIf="!userProfile.avatar_b64"
            src="assets/images/icon/ICONOS_sidebar_users.png"
            class="rounded-circle avatar-empty"
            width="70"
            height="70"
          />
          <img
            *ngIf="userProfile.avatar_b64"
            [src]="userProfile.avatar_b64"
            class="rounded-circle avatar-empty"
            width="70"
            height="70"
          />
        </div>
        <div class="m-l-10">
          <h4 class="m-b-0 font-15">{{ userProfile.username }}</h4>
          <p class="text-muted m-b-0 font-12">{{ userProfile.email }}</p>
          <a
            href="javascript:void(0)"
            class="btn btn-danger btn-rounded btn-sm m-t-5"
            *ngIf="env.showWidgets"
          >
            View Profile
          </a>
        </div>
      </div>
      <a class="dropdown-item" [routerLink]="['/security/user-profile/edit/1']">
        <i class="ti-user m-r-5 m-l-5"></i>
        Mi Perfil
      </a>
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        *ngIf="env.showWidgets"
      >
        <i class="ti-wallet m-r-5 m-l-5"></i>
        My Balance
      </a>
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        *ngIf="env.showWidgets"
      >
        <i class="ti-email m-r-5 m-l-5"></i>
        Inbox
      </a>
      <div class="dropdown-divider"></div>
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        *ngIf="env.showWidgets"
      >
        <i class="ti-settings m-r-5 m-l-5"></i>
        Account Setting
      </a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="onLogout()">
        <i class="fa fa-power-off m-r-5 m-l-5"></i>
        Logout
      </a>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>
