import { BaseSchema, MunicipalitySchema, ContractSchema } from '../';

export class RouteSchema extends BaseSchema {
  from_municipality: string | MunicipalitySchema;
  to_municipality: string | MunicipalitySchema;
  contracts: string[] | ContractSchema[];

  constructor(params: {
    from_municipality: string | MunicipalitySchema;
    to_municipality: string | MunicipalitySchema;
    contracts?: string[] | ContractSchema[];
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.from_municipality = params.from_municipality;
    this.to_municipality = params.to_municipality;
    this.contracts = params.contracts;
  }
}
