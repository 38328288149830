import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class NotifierService {
  constructor(private toastr: ToastrService) {}

  saveDataSuccess(): void {
    this.toastr.success('Se guardó el registro exitosamente', '¡Guardado!');
  }

  saveDataError(): void {
    this.toastr.error('Ocurrió un error al guardar el registro', '¡Error!');
  }

  deleteDataSuccess(): void {
    this.toastr.success('Se eliminó el registro exitosamente', '¡Eliminado!');
  }

  deleteDataError(): void {
    this.toastr.error('Ocurrió un error al eliminar el registro', '¡Error!');
  }

  saveDataErrorUserEmailExists(): void {
    this.toastr.error(
      'El correo electrónico ya se encuentra registrado',
      '¡Error!',
    );
  }
  sendDataErrorUserEmailNotExists(): void {
    this.toastr.error(
      'El correo electrónico no se encuentra registrado',
      '¡Error!',
    );
  }

  sendDataSuccess(): void {
    this.toastr.success(
      'Se ha enviado un correo electronico con instrucciones sobre cómo reestablecer su contraseña ',
      '¡Enviado!',
    );
  }
  sendDataError(): void {
    this.toastr.error('Ocurrió un error al enviar la información', '¡Error!');
  }

  passwordNotMatchs(): void {
    this.toastr.error('Las contraseña no coinciden', '¡Error!');
  }

  saveDataErrorUserCode(): void {
    this.toastr.error(
      'El código para crear nueva contraseña es incorrecto.',
      '¡Error!',
    );
  }
  saveDataErrorAcountAffiliate(): void {
    this.toastr.error(
      'Ocurrió un error al registrar la cuenta de usuario del afiliado.',
      '¡Error!',
    );
  }
  error(msg: string): void {
    this.toastr.error(msg, '¡Error!');
  }
}
