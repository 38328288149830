import {
  Component,
  Input,
  OnDestroy,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LoadingService } from '../services/loading/loading.service';

@Component({
  selector: 'app-spinner',
  template: `
    <div class="preloader" *ngIf="isSpinnerVisible">
      <div
        class="auth-wrapper d-flex no-block justify-content-center align-items-center background-start  fade-in-logo"
      >
        <div class="auth-box">
          <div class="logo m-b-20">
            <img src="assets/images/logo-light-text.svg" alt="logo" />
          </div>
        </div>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent implements OnDestroy {
  public isSpinnerVisible = true;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isSpinnerVisible = false;
        }
      },
      complete: () => {
        this.isSpinnerVisible = false;
      },
    });
  }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
  }
}
