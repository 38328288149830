import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import { ContractorSchema } from '@schemas/core';
import { IContractorDatatable, IToSelect } from '@shared/interfaces';
import { map } from 'rxjs/operators';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class ContractorService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<ContractorSchema[]> {
    let params = new HttpParams().set('_sort', 'name:ASC').set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<ContractorSchema[]>(`${this.API_URL}/contractors`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams();

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/contractors/count`, {
      params,
    });
  }

  findOne(id: string): Observable<ContractorSchema> {
    return this.http.get<ContractorSchema>(`${this.API_URL}/contractors/${id}`);
  }

  /**
   * Find duplicate contractor by document_number.
   * Can exclude registers for find duplicate
   * Return array boolean on this order document_number
   *
   */
  findDuplicate(
    data: {
      document_number: string;
    },
    exclude: string[],
  ): Observable<Array<boolean>> {
    return combineLatest([
      this.findDuplicateField('document_number', data.document_number, exclude),
    ]).pipe(map(([document_number]) => [document_number]));
  }

  /**
   * Return true if the field is duplicate
   * Can exclude registers for find duplicate
   *
   * En la actualidad Strapi no tiene una forma de buscar
   * un campo igual que no sea case sensitive, por eso se usa contains.
   *
   */
  private findDuplicateField(
    field: string,
    data: string,
    exclude: string[],
  ): Observable<boolean> {
    return this.http
      .get<ContractorSchema[]>(
        `${this.API_URL}/contractors?${field}_contains=${data}`,
      )
      .pipe(
        map((r) =>
          r.find(
            (x) =>
              !exclude.includes(x.id.toString()) &&
              x[field].toUpperCase() === data.toUpperCase(),
          )
            ? true
            : false,
        ),
      );
  }

  create(contractor: ContractorSchema): Observable<ContractorSchema> {
    return this.http.post<ContractorSchema>(
      `${this.API_URL}/contractors`,
      contractor,
    );
  }

  update(contractor: ContractorSchema): Observable<ContractorSchema> {
    return this.http.put<ContractorSchema>(
      `${this.API_URL}/contractors/${contractor?.id}`,
      contractor,
    );
  }

  delete(id: string): Observable<ContractorSchema> {
    return this.http.delete<ContractorSchema>(
      `${this.API_URL}/contractors/${id}`,
    );
  }

  toDataTable(
    contractors: Array<ContractorSchema>,
  ): Array<IContractorDatatable> {
    return contractors.map((c) => ({
      document_number: c.document_number,
      name: c.name,
      legal_representative_name: c.legal_representative_name,
      id: c.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'document_number_contains',
        field: 'Identificación',
        default: false,
      },
      {
        APIField: 'name_contains',
        field: 'Razón social',
        default: true,
      },
      {
        APIField: 'legal_representative_name_contains',
        field: 'Representante legal',
        default: false,
      },
    ]);
  }

  toSelect(contractor: ContractorSchema[]): IToSelect[] {
    return contractor.map((c) => ({
      id: c.id,
      description: `${c.document_number} - ${c.name}`,
    }));
  }

  getTableHead(): Observable<string[]> {
    return of(['Identificación', 'Razón Social', 'Representante Legal']);
  }
}
