import { BaseSchema, CompanySchema } from '..';

export class AssuredSchema extends BaseSchema {
  start_date: string;
  end_date: string;
  type: string;
  vehicle_type: string;
  value: number;
  /* insurance_description: string;*/
  company: CompanySchema | string;

  constructor(params: {
    start_date: string;
    end_date: string;
    type: string;
    vehicle_type: string;
    value: number;
    /* insurance_description: string;*/
    company: CompanySchema | string;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.start_date = params.start_date;
    this.end_date = params.end_date;
    this.type = params.type;
    this.vehicle_type = params.vehicle_type;
    this.value = params.value;
    /* this.insurance_description = params.insurance_description;*/
    this.company = params.company;
  }
}
