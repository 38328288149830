<div class="modal-header">
  <h4 class="modal-title">Sesión por finalizar</h4>
  <!-- <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss(modalButtonsStateEnum.CLOSE)"
  >
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<div class="modal-body">
  <div class="row align-items-center">
    <div class="col-sm-12 col-md-9 mt-3">
      <h5>
        Su sesión está por finalizar, presione "continuar" si desea continuar en
        Vizor
      </h5>
    </div>
    <div class="col-sm-12 col-md-3">
      <circle-progress
        [percent]="100"
        [radius]="35"
        [outerStrokeWidth]="6"
        [innerStrokeWidth]="6"
        [space]="-6"
        [outerStrokeColor]="'#e7e8ea'"
        [innerStrokeColor]="'#06d79c'"
        [animation]="true"
        [animationDuration]="SESSION_EXPIRATION_TIME_NOTIFICACION"
        [clockwise]="false"
        [showUnits]="false"
        [title]="['Finalizando', 'sesión']"
        [titleFontSize]="10"
      ></circle-progress>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button
      type="button"
      class="btn btn-block btn-primary"
      (click)="continue()"
    >
      Continuar
    </button>
  </div>
</div>
