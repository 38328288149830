<div
  class="auth-wrapper d-flex no-block justify-content-center align-items-center background-start"
>
  <div>
    <div class="auth-box">
      <div class="row">
        <div class="col-12">
          <div class="logo">
            <span class="db">
              <img src="assets/images/logo-light-text.svg" alt="logo" />
            </span>
            <h5 class="font-medium m-b-20 text-box">
              ¡Su cuenta ha sido confimada con éxito!
            </h5>
            <span class="text-box">
              Para continuar con el proceso cree una contraseña en el siguiente
              enlace
            </span>
            <br />
            <br />
            <a
              [routerLink]="['/forgot-password']"
              href="javascript:void(0)"
              id="to-recover"
              class="text-box"
            >
              <i class="fa fa-lock m-r-5"></i>
              Crear contraseña
            </a>

            <br />
            <br />
            <span class="text-box">
              Recuerde completar su perfil al ingresar al sistema.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
