import { MessageTypeEnum } from '@shared/enums';
import { BaseSchema } from '../';

export class MessageSchema extends BaseSchema {
  type: MessageTypeEnum;
  title: string;
  subject: string;
  icon: string;
  action_url: string;

  constructor(params: {
    type: MessageTypeEnum;
    title: string;
    subject: string;
    icon: string;
    action_url: string;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
    created_at?: string;
    updated_at?: string;
  }) {
    super(
      params.published_at,
      params.id,
      params.created_by,
      params.updated_by,
      params.created_at,
      params.updated_at,
    );
    this.type = params.type;
    this.title = params.title;
    this.subject = params.subject;
    this.icon = params.icon;
    this.action_url = params.action_url;
  }
}
