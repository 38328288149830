/**
 * Enum for type of trip (Tipo de Viaje)
 * Solo Ida o Ida y vuelta
 *
 * @export
 * @enum {number}
 */
export enum TripTypeEnum {
  ONE_WAY = 'ONE_WAY',
  ROUND_TRIP = 'ROUND_TRIP',
}
