import { BaseSchema, CompanySchema, PositionSchema } from '../';

export class EmployeeSchema extends BaseSchema {
  name: string;
  identification: string;
  email: string;
  phone_cel: string;
  phone_home: string;
  address: string;
  company: CompanySchema | string;
  position: PositionSchema | string;

  constructor(params: {
    name: string;
    identification: string;
    email: string;
    phone_cel: string;
    phone_home: string;
    address: string;
    company: CompanySchema | string;
    position: PositionSchema | string;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.name = params.name;
    this.identification = params.identification;
    this.email = params.email;
    this.phone_cel = params.phone_cel;
    this.phone_home = params.phone_home;
    this.address = params.address;
    this.company = params.company;
    this.position = params.position;
  }
}
