/* eslint-disable no-underscore-dangle */
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { KeyValue } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DeleteDialogComponent } from '@shared/delete-dialog/delete-dialog.component';
import { ModalButtonsStateEnum } from '@shared/enums';
import { LoadingService } from '@services/core';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  private;
  @Input() tableHead: Array<string>;
  @Input() page = 1;
  @Input() pageSize = 5;
  @Input() title = '';
  @Input() showAddButton = true;
  @Input() showEditButton = true;
  @Input() showDelButton = true;
  @Input() showActions = true;
  @Input() enableSearch = true;
  @Input() maxSize = 5;
  @Output() addRow: EventEmitter<boolean> = new EventEmitter(true);
  @Output() updateRow: EventEmitter<string> = new EventEmitter(true);
  @Output() deleteRow: EventEmitter<string> = new EventEmitter(true);

  @ContentChild('actionsTemplate', { static: false })
  actionsTemplate: TemplateRef<any>;
  @ContentChild('additionalColumnsHead', { static: false })
  additionalColumnsHead: TemplateRef<any>;
  @ContentChild('additionalColumns', { static: false })
  additionalColumns: TemplateRef<any>;
  modalButtonsStateEnum = ModalButtonsStateEnum;

  @Input() set tableData(value: Array<any>) {
    this.tableDataFilter = this._tableData = value;
    this.totalLengthTableData = this.tableDataFilter?.length;
    this.page = 1;
  }
  get tableData(): Array<any> {
    return this._tableData;
  }

  @Input() set loading(value: boolean) {
    if (value === true) {
      this.loadingService.startLoading();
    } else {
      this.loadingService.stopLoading();
    }
  }

  tableDataFilter: Array<any>;
  totalLengthTableData = 0;

  private _tableData: Array<any>;
  private _searchTerm = '';

  get searchTerm(): string {
    return this._searchTerm;
  }
  set searchTerm(val: string) {
    this._searchTerm = val;
    this.tableDataFilter = this.filter(val);
    this.totalLengthTableData = this.tableDataFilter.length;
  }

  loading$ = this.loadingService.loadingStatus$;

  constructor(
    private modalService: NgbModal,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {}

  filter(t: string): Array<any> {
    return this.tableData.filter((item: any) =>
      Object.keys(item).some((key) =>
        item[key]?.toString()?.toLowerCase()?.includes(t?.toLowerCase()),
      ),
    );
  }

  preserveOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>,
  ): number => 0;

  add(value: boolean): void {
    this.addRow.emit(value);
  }

  update(value: string): void {
    this.updateRow.emit(value);
  }

  delete(value: string): void {
    const modalRef = this.modalService.open(DeleteDialogComponent, {
      size: 'md',
    });

    modalRef.result.then((result) => {
      if (result === this.modalButtonsStateEnum.YES) {
        this.deleteRow.emit(value);
      }
    });
  }
}
