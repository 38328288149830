import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

// TODO: Revisar si feather icons es necesario
// import { FeatherModule } from 'angular-feather';
// import {
//   Camera,
//   Heart,
//   Github,
//   Activity,
//   Airplay,
//   AlertCircle,
//   AlertOctagon,
//   AlertTriangle,
//   AlignCenter,
//   AlignJustify,
//   AlignLeft,
//   AlignRight,
//   Anchor,
//   Aperture,
//   Archive,
//   ArrowDown,
//   ArrowDownCircle,
//   ArrowDownLeft,
//   ArrowDownRight,
//   ArrowLeftCircle,
//   ArrowLeft,
//   ArrowRight,
//   ArrowRightCircle,
//   ArrowUp,
//   ArrowUpCircle,
//   ArrowUpLeft,
//   ArrowUpRight,
//   AtSign,
//   Award,
//   BarChart2,
//   BarChart,
//   BatteryCharging,
//   Battery,
//   BellOff,
//   Bell,
//   Bluetooth,
//   Bold,
//   BookOpen,
//   Book,
//   Bookmark,
//   Box,
//   Briefcase,
//   Calendar,
//   CameraOff,
//   Cast,
//   CheckCircle,
//   CheckSquare,
//   Check,
//   ChevronDown,
//   ChevronLeft,
//   ChevronRight,
//   ChevronUp,
//   ChevronsDown,
//   ChevronsLeft,
//   ChevronsRight,
//   ChevronsUp,
//   Chrome,
//   Circle,
//   Clipboard,
//   Clock,
//   CloudDrizzle,
//   CloudLightning,
//   CloudOff,
//   CloudRain,
//   Cloud,
//   CloudSnow,
//   Code,
//   Codepen,
//   Codesandbox,
//   Coffee,
//   Columns,
//   Command,
//   Compass,
//   Copy,
//   CornerDownLeft,
//   CornerDownRight,
//   CornerLeftDown,
//   CornerLeftUp,
//   CornerRightDown,
//   CornerRightUp,
//   CornerUpLeft,
//   CornerUpRight,
//   Cpu,
//   CreditCard,
//   Crop,
//   Crosshair,
//   Database,
//   Delete,
//   Disc,
//   DollarSign,
//   DownloadCloud,
//   Download,
//   Droplet,
//   Edit,
//   Edit2,
//   Edit3,
//   ExternalLink,
//   EyeOff,
//   Eye,
//   Facebook,
//   FastForward,
//   Feather,
//   Figma,
//   FileMinus,
//   FilePlus,
//   FileText,
//   File,
//   Film,
//   Filter,
//   Flag,
//   Folder,
//   FolderMinus,
//   FolderPlus,
//   Framer,
//   Frown,
//   Gift,
//   GitBranch,
//   GitCommit,
//   GitMerge,
//   GitPullRequest,
//   Gitlab,
//   Globe,
//   Grid,
//   HardDrive,
//   Hash,
//   Headphones,
//   HelpCircle,
//   Hexagon,
//   Home,
//   MoreHorizontal,
//   Image,
//   Inbox,
//   Info,
//   Instagram,
//   Italic,
//   Key,
//   Layers,
//   Layout,
//   LifeBuoy,
//   Link,
//   Link2,
//   Linkedin,
//   List,
//   Loader,
//   Lock,
//   LogIn,
//   LogOut,
//   Mail,
//   MapPin,
//   Map,
//   Maximize,
//   Maximize2,
//   Meh,
//   Menu,
//   MessageCircle,
//   MessageSquare,
//   Mic,
//   MicOff,
//   Minimize,
//   Minimize2,
//   MinusCircle,
//   MinusSquare,
//   Minus,
//   Monitor,
//   Moon,
//   MoreVertical,
//   MousePointer,
//   Move,
//   Music,
//   Navigation,
//   Navigation2,
//   Octagon,
//   Package,
//   Paperclip,
//   PauseCircle,
//   Pause,
//   PenTool,
//   Percent,
//   PhoneCall,
//   PhoneForwarded,
//   PhoneIncoming,
//   PhoneMissed,
//   Phone,
//   PhoneOff,
//   PhoneOutgoing,
//   PieChart,
//   Play,
//   PlayCircle,
//   Plus,
//   PlusCircle,
//   PlusSquare,
//   Pocket,
//   Power,
//   Printer,
//   Radio,
//   RefreshCcw,
//   RefreshCw,
//   Repeat,
//   Rewind,
//   RotateCcw,
//   RotateCw,
//   Rss,
//   Save,
//   Scissors,
//   Search,
//   Send,
//   Server,
//   Settings,
//   Share,
//   Share2,
//   Shield,
//   ShieldOff,
//   ShoppingBag,
//   ShoppingCart,
//   Shuffle,
//   Sidebar,
//   SkipBack,
//   SkipForward,
//   Slack,
//   Slash,
//   Sliders,
//   Smartphone,
//   Smile,
//   Speaker,
//   Square,
//   Star,
//   StopCircle,
//   Sun,
//   Sunrise,
//   Sunset,
//   Tablet,
//   Tag,
//   Target,
//   Terminal,
//   Thermometer,
//   ThumbsDown,
//   ThumbsUp,
//   ToggleLeft,
//   ToggleRight,
//   Tool,
//   Trash,
//   Trash2,
//   Trello,
//   TrendingDown,
//   TrendingUp,
//   Triangle,
//   Truck,
//   Tv,
//   Twitch,
//   Twitter,
//   Type,
//   Umbrella,
//   Underline,
//   Unlock,
//   Upload,
//   UploadCloud,
//   User,
//   UserCheck,
//   UserMinus,
//   UserPlus,
//   UserX,
//   Users,
//   Video,
//   VideoOff,
//   Voicemail,
//   Volume,
//   Volume1,
//   Volume2,
//   VolumeX,
//   Watch,
//   Wifi,
//   WifiOff,
//   Wind,
//   XCircle,
//   XOctagon,
//   XSquare,
//   X,
//   Youtube,
//   Zap,
//   ZapOff,
//   ZoomIn,
//   ZoomOut,
// } from 'angular-feather/icons';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { APP_ROUTES } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { SharedModule } from '@shared/shared.module';
import { TokenInterceptor } from '@shared/interceptors/token/token.interceptor';
import { PermissionGuard } from '@shared/rbac';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { BnNgIdleService } from 'bn-ng-idle';
// eslint-disable-next-line max-len
import { ModalSessionExpirationNotificationComponent } from './layouts/full/components/modal-session-expiration-notification/modal-session-expiration-notification.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Select some icons (use an object, not an array)
// const icons = {
//   Camera,
//   Heart,
//   Github,
//   Activity,
//   Airplay,
//   AlertCircle,
//   AlertOctagon,
//   AlertTriangle,
//   AlignCenter,
//   AlignJustify,
//   AlignLeft,
//   AlignRight,
//   Anchor,
//   Aperture,
//   Archive,
//   ArrowDown,
//   ArrowDownCircle,
//   ArrowDownLeft,
//   ArrowDownRight,
//   ArrowLeftCircle,
//   ArrowLeft,
//   ArrowRight,
//   ArrowRightCircle,
//   ArrowUp,
//   ArrowUpCircle,
//   ArrowUpLeft,
//   ArrowUpRight,
//   AtSign,
//   Award,
//   BarChart2,
//   BarChart,
//   BatteryCharging,
//   Battery,
//   BellOff,
//   Bell,
//   Bluetooth,
//   Bold,
//   BookOpen,
//   Book,
//   Bookmark,
//   Box,
//   Briefcase,
//   Calendar,
//   CameraOff,
//   Cast,
//   CheckCircle,
//   CheckSquare,
//   Check,
//   ChevronDown,
//   ChevronLeft,
//   ChevronRight,
//   ChevronUp,
//   ChevronsDown,
//   ChevronsLeft,
//   ChevronsRight,
//   ChevronsUp,
//   Chrome,
//   Circle,
//   Clipboard,
//   Clock,
//   CloudDrizzle,
//   CloudLightning,
//   CloudOff,
//   CloudRain,
//   Cloud,
//   CloudSnow,
//   Code,
//   Codepen,
//   Codesandbox,
//   Coffee,
//   Columns,
//   Command,
//   Compass,
//   Copy,
//   CornerDownLeft,
//   CornerDownRight,
//   CornerLeftDown,
//   CornerLeftUp,
//   CornerRightDown,
//   CornerRightUp,
//   CornerUpLeft,
//   CornerUpRight,
//   Cpu,
//   CreditCard,
//   Crop,
//   Crosshair,
//   Database,
//   Delete,
//   Disc,
//   DollarSign,
//   DownloadCloud,
//   Download,
//   Droplet,
//   Edit,
//   Edit2,
//   Edit3,
//   ExternalLink,
//   EyeOff,
//   Eye,
//   Facebook,
//   FastForward,
//   Feather,
//   Figma,
//   FileMinus,
//   FilePlus,
//   FileText,
//   File,
//   Film,
//   Filter,
//   Flag,
//   Folder,
//   FolderMinus,
//   FolderPlus,
//   Framer,
//   Frown,
//   Gift,
//   GitBranch,
//   GitCommit,
//   GitMerge,
//   GitPullRequest,
//   Gitlab,
//   Globe,
//   Grid,
//   HardDrive,
//   Hash,
//   Headphones,
//   HelpCircle,
//   Hexagon,
//   Home,
//   MoreHorizontal,
//   Image,
//   Inbox,
//   Info,
//   Instagram,
//   Italic,
//   Key,
//   Layers,
//   Layout,
//   LifeBuoy,
//   Link,
//   Link2,
//   Linkedin,
//   List,
//   Loader,
//   Lock,
//   LogIn,
//   LogOut,
//   Mail,
//   MapPin,
//   Map,
//   Maximize,
//   Maximize2,
//   Meh,
//   Menu,
//   MessageCircle,
//   MessageSquare,
//   Mic,
//   MicOff,
//   Minimize,
//   Minimize2,
//   MinusCircle,
//   MinusSquare,
//   Minus,
//   Monitor,
//   Moon,
//   MoreVertical,
//   MousePointer,
//   Move,
//   Music,
//   Navigation,
//   Navigation2,
//   Octagon,
//   Package,
//   Paperclip,
//   PauseCircle,
//   Pause,
//   PenTool,
//   Percent,
//   PhoneCall,
//   PhoneForwarded,
//   PhoneIncoming,
//   PhoneMissed,
//   Phone,
//   PhoneOff,
//   PhoneOutgoing,
//   PieChart,
//   Play,
//   PlayCircle,
//   Plus,
//   PlusCircle,
//   PlusSquare,
//   Pocket,
//   Power,
//   Printer,
//   Radio,
//   RefreshCcw,
//   RefreshCw,
//   Repeat,
//   Rewind,
//   RotateCcw,
//   RotateCw,
//   Rss,
//   Save,
//   Scissors,
//   Search,
//   Send,
//   Server,
//   Settings,
//   Share,
//   Share2,
//   Shield,
//   ShieldOff,
//   ShoppingBag,
//   ShoppingCart,
//   Shuffle,
//   Sidebar,
//   SkipBack,
//   SkipForward,
//   Slack,
//   Slash,
//   Sliders,
//   Smartphone,
//   Smile,
//   Speaker,
//   Square,
//   Star,
//   StopCircle,
//   Sun,
//   Sunrise,
//   Sunset,
//   Tablet,
//   Tag,
//   Target,
//   Terminal,
//   Thermometer,
//   ThumbsDown,
//   ThumbsUp,
//   ToggleLeft,
//   ToggleRight,
//   Tool,
//   Trash,
//   Trash2,
//   Trello,
//   TrendingDown,
//   TrendingUp,
//   Triangle,
//   Truck,
//   Tv,
//   Twitch,
//   Twitter,
//   Type,
//   Umbrella,
//   Underline,
//   Unlock,
//   Upload,
//   UploadCloud,
//   User,
//   UserCheck,
//   UserMinus,
//   UserPlus,
//   UserX,
//   Users,
//   Video,
//   VideoOff,
//   Voicemail,
//   Volume,
//   Volume1,
//   Volume2,
//   VolumeX,
//   Watch,
//   Wifi,
//   WifiOff,
//   Wind,
//   XCircle,
//   XOctagon,
//   XSquare,
//   X,
//   Youtube,
//   Zap,
//   ZapOff,
//   ZoomIn,
//   ZoomOut,
// };

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    BlankComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ModalSessionExpirationNotificationComponent,
    EmailConfirmationComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // FeatherModule,
    // FeatherModule.pick(icons),
    RouterModule.forRoot(APP_ROUTES),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgbModule,
    PerfectScrollbarModule,
    SharedModule,
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    AuthGuard,
    PermissionGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    BnNgIdleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
