import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  format,
  startOfMonth,
  subMonths,
  compareAsc,
  addMonths,
  addDays,
  subDays,
} from 'date-fns';

import { environment } from 'src/environments/environment';
import { CompareDatesEnum } from '@shared/enums';

export type FormFieldDateType = NgbDateStruct;
@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  readonly TIMEZONE = environment.TIMEZONE;
  readonly UTC = 'UTC';
  readonly DATE_SEPARATOR = '-';
  readonly DATE_FORMAT = 'dd-MM-yyyy';
  readonly DATE_FORMAT_ISO = 'yyyy-MM-dd';
  readonly DATE_FORMAT_DATE_HOUR = 'dd-MM-yyyy HH:mm';

  constructor() {}

  /**
   * Transform date string to object Date TIMEZONE
   * The string should be in format 'xxxx-xx-xx'
   * 'fullyear-month-day'
   * if date is null return today
   *
   * Retorna solo la fecha sin importar la hora.
   * Siempre la hora será 0:0:0
   *
   * @param {string} date
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getDate(dateString: string | null = null): Date {
    // const date = new Date(
    //   new Date().toLocaleString('en-US', {
    //     timeZone: this.TIMEZONE,
    //   }),
    // );

    // Transform from dateString param
    if (dateString) {
      const [year, month, day] = dateString.split(this.DATE_SEPARATOR);

      return new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10),
        0,
        0,
        0,
        0,
      );
    }

    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  /**
   * Transform date string to object Date TIMEZONE
   * The string should be in format 'xxxx-xx-xx'
   * 'fullyear-month-day'
   * if date is null return today
   *
   * Retorna la fecha con la hora
   *
   * @param {string} date
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getDateTime(dateString: string | null = null): Date {
    let dateTime = new Date();

    if (dateString) {
      dateTime = new Date(dateString);
    }
    return dateTime;
  }

  /**
   * Transform date string to object Date UTC
   * The string should be in format 'xxxx-xx-xx'
   * 'fullyear-month-day'
   * if date is null return today
   *
   * Retorna la fecha sin importar la hora
   * Siempre la hora será 0:0:0
   * En la práctica esta función no tiene
   * ningún efecto
   *
   * @param {(string | null)} [dateString=null]
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getUTCDate(dateString: string | null = null): Date {
    // const date = new Date(
    //   new Date().toLocaleString('en-US', {
    //     timeZone: this.UTC,
    //   }),
    // );

    // const date = new Date(
    //   new Date().toLocaleString('en-US', {
    //     timeZone: this.TIMEZONE,
    //   }),
    // );

    // Transform from dateString param
    if (dateString) {
      const [year, month, day] = dateString.split(this.DATE_SEPARATOR);

      return new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10),
        0,
        0,
        0,
        0,
      );
    }

    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  /**
   * Get actual date with time 0
   *
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getToday(): Date {
    const date = this.getDate();

    date.setHours(0, 0, 0, 0);

    return date;
  }

  /**
   * Get actual UTC date time
   *
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getUTCNow(): Date {
    const now = new Date(
      new Date().toLocaleString('en-US', {
        timeZone: this.UTC,
      }),
    );

    return now;
  }

  /**
   * Get actual date time
   *
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getNow(): Date {
    return this.getDate();
  }

  /**
   * Transform DateStruct to Date
   *
   * @param {NgbDateStruct} dateStruct
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getDateFromDateStruct(dateStruct: NgbDateStruct): Date {
    return this.getDate(this.getStringFromDateStruct(dateStruct));
  }

  /**
   * Transform DateStruct to string
   * Return string in format 'fullyear-month-day'
   *
   * @param {NgbDateStruct} dateStruct
   * @return {*}  {string}
   * @memberof DateTimeService
   */
  getStringFromDateStruct(dateStruct: NgbDateStruct): string {
    return `${dateStruct.year}-${dateStruct.month}-${dateStruct.day}`;
  }

  /**
   * Transform date Object to DateStruct
   *
   * @param {Date} dateObject
   * @return {*}  {NgbDateStruct}
   * @memberof DateTimeService
   */
  getDateStructFromDate(dateObject: Date): NgbDateStruct {
    const dateStruct = {
      year: dateObject.getFullYear(),
      month: dateObject.getMonth() + 1,
      day: dateObject.getDate(),
    };
    return dateStruct;
  }

  getStringFormatFromDateHour(dateObject: Date): string {
    return format(dateObject, this.DATE_FORMAT_DATE_HOUR);
  }

  getStringFormatFromDate(dateObject: Date): string {
    return format(dateObject, this.DATE_FORMAT);
  }

  getStringFormatISOFromDate(dateObject: Date): string {
    return format(dateObject, this.DATE_FORMAT_ISO);
  }

  getDateFromAPIDate(dateAPI: string): Date {
    return this.getDate(dateAPI);
  }

  getAPIDateFromDate(date: Date): string {
    // Se mantiene este comentario por si en un futuro
    // se desea usar el TIMEZONE
    // return this.getUTCDate(date.toISOString()).toISOString();
    return this.getStringFormatISOFromDate(date);
  }

  getStringFormatFromAPIDate(date: string): string {
    return this.getStringFormatFromDate(this.getDate(date));
  }

  /**
   * Transform API Date to
   * Form Field Date
   *
   * @param {string} date
   * @return {*}  {NgbDateStruct}
   * @memberof DateTimeService
   */
  getFormFieldDateFromAPIDate(date: string): NgbDateStruct {
    return this.getDateStructFromDate(this.getDateFromAPIDate(date));
  }

  /**
   * Transform Date to
   * Form Field Date
   *
   * @param {Date} date
   * @return {*}  {NgbDateStruct}
   * @memberof DateTimeService
   */
  getFormFieldDateFromDate(date: Date): NgbDateStruct {
    return this.getDateStructFromDate(date);
  }

  /**
   * Transform Form Field Date to
   * Date
   *
   * @param {NgbDateStruct} date
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getDateFromFormFieldDate(date: NgbDateStruct): Date {
    return this.getDateFromDateStruct(date);
  }

  /**
   * Transform Form Field Date to
   * API Date
   *
   * @param {NgbDateStruct} date
   * @return {*}  {string}
   * @memberof DateTimeService
   */
  getAPIDateFromFormFieldDate(date: NgbDateStruct): string {
    return this.getAPIDateFromDate(this.getDateFromDateStruct(date));
  }

  /**
   * Transform API Date to
   * Datatable cell date
   *
   * @param {string} date
   * @return {*}  {string}
   * @memberof DateTimeService
   */
  getDataTableCellDateFromAPIDate(date: string): string {
    return this.getStringFormatFromDate(this.getDate(date));
  }

  /**
   * Transform API Date Time to
   * Datatable cell date
   *
   * @param {string} date
   * @return {*}  {string}
   * @memberof DateTimeService
   */
  getDataTableCellDateFromAPIDateTime(date: string): string {
    return this.getStringFormatFromDateHour(this.getDateTime(date));
  }

  /**
   * Pass Date params and add amount days
   *
   * @param {Date} date
   * @param {number} amount
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getDateAddDays(date: Date, amount: number): Date {
    return addDays(date, amount);
  }

  /**
   * Pass Date params and subtract amount days
   *
   * @param {Date} date
   * @param {number} amount
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getDateSubDays(date: Date, amount: number): Date {
    return subDays(date, amount);
  }

  /**
   * Pass Date params and add amount months
   *
   * @param {Date} date
   * @param {number} amount
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getDateAddMonths(date: Date, amount: number): Date {
    return addMonths(date, amount);
  }

  /**
   * Pass Date params and subtract amount months
   *
   * @param {Date} date
   * @param {number} amount
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getDateSubMonths(date: Date, amount: number): Date {
    return subMonths(date, amount);
  }

  /**
   * Get Date with start of Month
   *
   * @param {Date} date
   * @return {*}  {Date}
   * @memberof DateTimeService
   */
  getDateStartOfMonth(date: Date): Date {
    return startOfMonth(date);
  }

  /**
   * Compare the two dates
   * return 1 if the first date is after the second,
   * return  -1 if the first date is before the second,
   * return 0 if dates are equal.
   *
   * @param {Date} firstDate
   * @param {Date} secondDate
   * @return {*}  {number}
   * @memberof DateTimeService
   */
  compareDates(firstDate: Date, secondDate: Date): number {
    const result = compareAsc(firstDate, secondDate);

    if (result === 1) {
      return CompareDatesEnum.AFTER;
    }

    if (result === -1) {
      return CompareDatesEnum.BEFORE;
    }

    return CompareDatesEnum.EQUAL;
  }

  /**
   * Compare Two FormFieldDateType
   *
   * @param {FormFieldDateType} firstDate
   * @param {FormFieldDateType} secondDate
   * @return {*}  {number}
   * @memberof DateTimeService
   */
  compareFormFieldDates(
    firstDate: FormFieldDateType,
    secondDate: FormFieldDateType,
  ): number {
    return this.compareDates(
      this.getDateFromFormFieldDate(firstDate),
      this.getDateFromFormFieldDate(secondDate),
    );
  }
}
