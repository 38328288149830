import {
  Component,
  AfterViewInit,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

import { environment } from 'src/environments/environment';
import { MessageSchema, UserSchema } from '@schemas/core';
import {
  DocumentAlertService,
  MessageService,
  MessageTrayService,
} from '@services/core';
import { combineLatest, Subscription, switchMap, tap, timer } from 'rxjs';
import { PERMISSIONS, PermissionService } from '@shared/rbac';

@Component({
  selector: 'app-vertical-navigation',
  templateUrl: './vertical-navigation.component.html',
  styleUrls: ['./vertical-navigation.component.scss'],
})
export class VerticalNavigationComponent implements AfterViewInit, OnDestroy {
  @Input() userProfile?: UserSchema;
  @Output() logout = new EventEmitter<void>();
  @Output() toggleSidebar = new EventEmitter<void>();

  readonly env = environment;

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;

  hasPermissionToViewMessages = false;
  hasNewMessages$ = this.messageTrayService.messages$;
  hasAffiliateDocumentsAlert = false;
  hasCompanyDocumentsAlert = false;
  subscription = new Subscription();

  // This is for Notifications
  messages: MessageSchema[] = [];

  // This is for Mymessages
  mymessages: Record<string, unknown>[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM',
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM',
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM',
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM',
    },
  ];

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us',
  };

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us',
    },
    {
      language: 'Español',
      code: 'es',
      icon: 'es',
    },
    {
      language: 'Français',
      code: 'fr',
      icon: 'fr',
    },
    {
      language: 'German',
      code: 'de',
      icon: 'de',
    },
  ];

  constructor(
    private translate: TranslateService,
    private messageTrayService: MessageTrayService,
    private documentAlertService: DocumentAlertService,
    private messageService: MessageService,
    private permissionService: PermissionService,
  ) {
    translate.setDefaultLang('en');
  }

  changeLanguage(lang: any) {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  ngAfterViewInit() {
    const sub = this.permissionService
      .hasPermission(PERMISSIONS.SECURITY_MESSAGES_LIST)
      .pipe(
        tap((p) => {
          this.hasPermissionToViewMessages = p;
          if (this.hasPermissionToViewMessages) {
            this.checkForDocumentsAlert();
            this.checkForNewMessages();
          }
        }),
      )
      .subscribe();
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onLogout(): void {
    this.logout.emit();
  }

  clearMessageTray(): void {
    this.messageTrayService.clear();
    this.hasAffiliateDocumentsAlert = false;
    this.hasCompanyDocumentsAlert = false;
    this.messages = [];
  }

  onClickMessageTray(): void {
    this.messageTrayService.clear();
  }

  checkForDocumentsAlert(): void {
    const sub = combineLatest([
      this.documentAlertService.hasAffiliateDocuments$,
      this.documentAlertService.hasCompanyDocuments$,
    ])
      .pipe(
        tap(([had, hcd]) => {
          this.hasAffiliateDocumentsAlert = had;
          this.hasCompanyDocumentsAlert = hcd;
          const hasMessages = had || hcd;
          if (hasMessages) {
            this.messageTrayService.add(1);
          }
        }),
      )
      .subscribe();

    this.subscription.add(sub);
  }

  checkForNewMessages(): void {
    const sub = timer(0, this.env.MESSAGES_TIME_CHECK)
      .pipe(
        switchMap(() =>
          this.messageService.findLast().pipe(
            tap((m) => {
              this.messages = m;
              if (m.length > 0) {
                this.messageTrayService.add(1);
              }
            }),
          ),
        ),
      )
      .subscribe();

    this.subscription.add(sub);
  }
}
