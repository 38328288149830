export enum DocumentSubjectStatusEnum {
  VERIFIED = 'VERIFIED',
  NO_VERIFIED = 'NO_VERIFIED',
  REJECTED = 'REJECTED',
}

export enum DocumentSubjectStatusTextEnum {
  VERIFIED = 'Verificado',
  NO_VERIFIED = 'No verificado',
  REJECTED = 'Rechazado',
}
