<div class="row">
  <div class="col-lg-4 col-xlg-3 col-md-5">
    <div class="card">
      <div class="card-body">
        <div class="delete text-right">
          <div *ngIf="!users">
            <button
              *ngIf="imgUrlAvatar"
              type="button"
              class="button-delete"
              title="Eliminar imagen"
              (click)="deleteAvatar()"
            >
              X
            </button>
          </div>
        </div>
        <div class="text-center">
          <div *ngIf="!imgUrlAvatar">
            <img
              src="assets/images/icon/ICONOS_sidebar_users.png"
              class="rounded-circle avatar-empty"
              width="150"
              height="150"
              (click)="clickfileUpload()"
            />
          </div>
          <div *ngIf="imgUrlAvatar">
            <img
              [src]="imgAvatarBase64"
              class="rounded-circle"
              width="150"
              height="150"
              (click)="clickfileUpload()"
            />
          </div>
          <h4
            *ngIf="this.firstname !== null && this.lastname !== null"
            class="card-title m-t-10"
          >
            {{ this.firstname + ' ' + this.lastname }}
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-8 col-xlg-9 col-md-7">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" errorTailor>
          <ng-container *ngIf="users">
            <div class="form-group col-md-12">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="FIELD_ACTIVE"
                  [formControlName]="FIELD_ACTIVE"
                  value=""
                />
                <label class="custom-control-label" for="FIELD_ACTIVE">
                  Activo
                </label>
              </div>
            </div>
          </ng-container>
          <div class="col-sm-12 col-md-4">
            <div class="form-group">
              <div>
                <input
                  #fileUpload
                  type="file"
                  class="d-none"
                  [id]="FIELD_AVATAR"
                  [formControlName]="FIELD_AVATAR"
                  (change)="onChangeAvatar($event)"
                  accept="image/*"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-md-12">
            <label
              [for]="FIELD_FIRST_NAME"
              class="control-label col-form-label"
            >
              Nombres
            </label>

            <input
              type="text"
              class="form-control"
              [id]="FIELD_FIRST_NAME"
              [formControlName]="FIELD_FIRST_NAME"
              placeholder="Nombres"
              [readonly]="isReadonly"
              (blur)="
                form.controls[FIELD_FIRST_NAME].setValue(
                  util.cleanFields(form.controls[FIELD_FIRST_NAME].value)
                )
              "
            />
          </div>
          <div class="form-group col-md-12">
            <label [for]="FIELD_LAST_NAME" class="control-label col-form-label">
              Apellidos
            </label>

            <input
              type="text"
              class="form-control"
              [id]="FIELD_LAST_NAME"
              [formControlName]="FIELD_LAST_NAME"
              placeholder="Apellidos"
              [readonly]="isReadonly"
              (blur)="
                form.controls[FIELD_LAST_NAME].setValue(
                  util.cleanFields(form.controls[FIELD_LAST_NAME].value)
                )
              "
            />
          </div>
          <div class="form-group col-md-12">
            <label
              [for]="FIELD_EMAIL"
              class="control-label col-form-label required"
            >
              Correo electrónico
            </label>

            <input
              type="text"
              class="form-control"
              [id]="FIELD_EMAIL"
              [formControlName]="FIELD_EMAIL"
              placeholder="Correo electrónico"
              [readonly]="true"
            />
          </div>

          <div class="form-group col-md-12">
            <label
              [for]="FIELD_CELL_PHONE_NUMBER"
              class="control-label col-form-label"
            >
              Número de Celular
            </label>
            <input
              type="text"
              class="form-control placeholder-mask"
              [id]="FIELD_CELL_PHONE_NUMBER"
              [formControlName]="FIELD_CELL_PHONE_NUMBER"
              placeholder="(___) ___-____"
              mask="(000) 000-0000"
              [readonly]="isReadonly"
            />
          </div>
          <div class="form-group col-md-12">
            <label [for]="FIELD_POSITION" class="control-label col-form-label">
              Cargo
            </label>
            <input
              type="text"
              class="form-control"
              [id]="FIELD_POSITION"
              [formControlName]="FIELD_POSITION"
              placeholder="Cargo"
              [readonly]="isReadonly"
              (blur)="
                form.controls[FIELD_POSITION].setValue(
                  util.cleanFields(form.controls[FIELD_POSITION].value)
                )
              "
            />
          </div>
          <div class="form-group col-md-12">
            <label
              class="control-label col-form-label required"
              [for]="FIELD_ROLE"
            >
              Rol
            </label>
            <ng-multiselect-dropdown
              [placeholder]="
                (roles$ | async) === null ? 'Cargando...' : 'Seleccione'
              "
              [id]="FIELD_ROLE"
              [formControlName]="FIELD_ROLE"
              [settings]="{
                singleSelection: true,
                idField: 'id',
                textField: 'description',
                closeDropDownOnSelection: true,
                allowSearchFilter: false,
                searchPlaceholderText: 'Buscar'
              }"
              [data]="roles$ | async"
              [disabled]="isDropdownDisabled"
              (onDropDownClose)="onDropDownClose()"
              [ngClass]="{
                'ng-multiselect-dropdown-error':
                  (form.controls[FIELD_ROLE].dirty ||
                    form.controls[FIELD_ROLE].touched) &&
                  form.controls[FIELD_ROLE].errors &&
                  form.controls[FIELD_ROLE].errors?.required
              }"
            ></ng-multiselect-dropdown>
            <label
              *ngIf="
                (form.controls[FIELD_ROLE].dirty ||
                  form.controls[FIELD_ROLE].touched) &&
                form.controls[FIELD_ROLE].errors &&
                form.controls[FIELD_ROLE].errors?.required
              "
              class="control-error"
            >
              Este campo es obligatorio
            </label>
          </div>
          <ng-container *ngIf="!users">
            <div class="form-group col-md-5">
              <label
                [for]="FIELD_PASSWORD"
                class="control-label col-form-label"
              >
                Contraseña
              </label>
              <div class="input-group">
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  class="form-control textbox-icon-inside"
                  autocomplete="new-password"
                  [id]="FIELD_PASSWORD"
                  [formControlName]="FIELD_PASSWORD"
                  placeholder="Contraseña del usuario"
                  [readonly]="isReadonly"
                />
                <div class="input-group-append">
                  <span class="icon-inside">
                    <i
                      class="fas fa-eye cursor-pointer"
                      (click)="showHidePassword()"
                      title="Ver contraseña"
                    ></i>
                  </span>
                </div>
              </div>
            </div>

            <div class="form-group col-md-5">
              <label
                [for]="FIELD_PASSWORD_REPEAT"
                class="control-label col-form-label"
              >
                Repetir contraseña
              </label>
              <div class="input-group">
                <input
                  [type]="showPasswordRepeat ? 'text' : 'password'"
                  class="form-control textbox-icon-inside"
                  autocomplete="new-password"
                  controlErrorsIgnore
                  [id]="FIELD_PASSWORD_REPEAT"
                  [formControlName]="FIELD_PASSWORD_REPEAT"
                  placeholder="Repetir contraseña del usuario"
                  [readonly]="isReadonly"
                />
                <div class="input-group-append">
                  <span class="icon-inside">
                    <i
                      class="fas fa-eye cursor-pointer"
                      (click)="showHidePasswordRepeat()"
                      title="Ver contraseña"
                    ></i>
                  </span>
                </div>
              </div>
              <label
                *ngIf="
                  (form.controls[FIELD_PASSWORD_REPEAT].dirty ||
                    form.controls[FIELD_PASSWORD_REPEAT].touched) &&
                  form.controls[FIELD_PASSWORD_REPEAT].errors &&
                  (form.controls[FIELD_PASSWORD_REPEAT].errors?.required ||
                    form.controls[FIELD_PASSWORD_REPEAT].errors
                      ?.confirmedValidator)
                "
                class="control-error"
              >
                Las contraseñas no coinciden
              </label>
            </div>
          </ng-container>
          <div class="row">
            <div class="col text-center">
              <label *ngIf="avatarFileTooLarge" class="control-error">
                El tamaño de la imagen debe ser menor de
                {{ SIZE_FILE_AVATAR_MEGAS }}
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="col text-center">
              <hr />
              <button
                type="button"
                class="btn btn-secondary"
                routerLink="{{
                  this.pageEdit === 'editUsers'
                    ? '/security/user-management'
                    : '/starter'
                }}"
              >
                Cancelar
              </button>
              <button
                type="submit"
                *ngIf="this.pageEdit === 'editUsers'"
                class="btn btn-primary"
                [class.btn-width]="loading === true"
                [disabled]="!form.valid || loading === true"
              >
                <span
                  [ngClass]="{
                    'spinner-border spinner-border-sm': loading === true
                  }"
                  aria-hidden="true"
                >
                  {{ loading === false ? 'Actualizar usuario' : '' }}
                </span>
              </button>
              <button
                type="submit"
                *ngIf="this.pageEdit !== 'editUsers'"
                class="btn btn-primary"
                [class.btn-width-profile]="loading === true"
                [disabled]="!form.valid || loading === true"
              >
                <span
                  [ngClass]="{
                    'spinner-border spinner-border-sm': loading === true
                  }"
                  aria-hidden="true"
                >
                  {{ loading === false ? 'Actualizar perfil del usuario' : '' }}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
