import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Subscription,
  switchMap,
  tap,
  timer,
} from 'rxjs';

import { DocumentSubjectService } from '@services/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DocumentAlertService implements OnDestroy {
  readonly env = environment;

  private hasDocuments = new BehaviorSubject<boolean>(false);
  hasDocuments$ = this.hasDocuments.asObservable();

  private hasAffiliateDocuments = new BehaviorSubject<boolean>(false);
  hasAffiliateDocuments$ = this.hasAffiliateDocuments.asObservable();

  private hasCompanyDocuments = new BehaviorSubject<boolean>(false);
  hasCompanyDocuments$ = this.hasCompanyDocuments.asObservable();

  subscriptions = new Subscription();

  constructor(private documentSubjectService: DocumentSubjectService) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  checkForExpiredAffiliateDocuments(): void {
    const sub = timer(0, this.env.DOCUMENT_ALERT_TIME_CHECK)
      .pipe(
        switchMap(() =>
          combineLatest([
            this.documentSubjectService.findCurrentDocumentsByAffiliates(),
            this.documentSubjectService.findCurrentDocumentsByVehicles(),
            this.documentSubjectService.findCurrentDocumentsByDrivers(),
          ]).pipe(
            tap(([daf, dv, dd]) => {
              const value = daf.length > 0 || dv.length > 0 || dd.length > 0;
              this.hasAffiliateDocuments.next(value);
            }),
          ),
        ),
      )
      .subscribe();

    this.subscriptions.add(sub);
  }

  checkForExpiredCompanyDocuments(): void {
    const sub = timer(0, this.env.DOCUMENT_ALERT_TIME_CHECK)
      .pipe(
        switchMap(() =>
          this.documentSubjectService.findCurrentDocumentsByCompanies().pipe(
            map((dc) => {
              const value = dc.length > 0;
              this.hasCompanyDocuments.next(value);
            }),
          ),
        ),
      )
      .subscribe();

    this.subscriptions.add(sub);
  }
}
