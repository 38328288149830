import { BaseSchema, DocumentTypeSchema, ContractSchema } from '../';

export class ContractorSchema extends BaseSchema {
  name: string;
  document_type: DocumentTypeSchema | string;
  document_number: string;
  address: string;
  phone_number: string;
  dv: string;
  contact: string;
  email: string;
  legal_representative_name: string;
  legal_repesentative_document_type: DocumentTypeSchema | string;
  legal_representative_document_number: string;
  contracts: Array<ContractSchema> | Array<string>;

  constructor(params: {
    name: string;
    document_type: DocumentTypeSchema | string;
    document_number: string;
    address: string;
    phone_number: string;
    dv: string;
    contact: string;
    email: string;
    legal_representative_name: string;
    legal_repesentative_document_type: DocumentTypeSchema | string;
    legal_representative_document_number: string;
    contracts?: Array<ContractSchema> | Array<string>;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.name = params.name;
    this.document_type = params.document_type;
    this.document_number = params.document_number;
    this.address = params.address;
    this.phone_number = params.phone_number;
    this.dv = params.dv;
    this.contact = params.contact;
    this.email = params.email;
    this.legal_representative_name = params.legal_representative_name;
    this.legal_repesentative_document_type =
      params.legal_repesentative_document_type;
    this.legal_representative_document_number =
      params.legal_representative_document_number;
    this.contracts = params.contracts;
  }
}
