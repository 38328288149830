<nav class="sidebar-nav mt-3">
  <ul id="sidebarnav">
    <!-- User Profile-->
    <li
      class="sidebar-item user-profile"
      [class.active]="showMenu === 'user'"
      *ngIf="env.showWidgets"
    >
      <a
        class="sidebar-link has-arrow waves-effect waves-dark"
        href="javascript:void(0)"
        (click)="addExpandClass('user')"
        aria-expanded="false"
      >
        <span>
          <img src="assets/images/users/profile.png" alt="user" />
        </span>
        <span class="hide-menu">user.email@gmail.com</span>
      </a>
      <ul
        aria-expanded="false"
        class="collapse"
        [class.in]="showMenu === 'user'"
      >
        <!-- <li>
          <a href="javascript:void()" class="sidebar-link p-0">My Profile</a>
        </li>
        <li>
          <a href="javascript:void()" class="sidebar-link p-0">My Balance</a>
        </li>
        <li><a href="javascript:void()" class="sidebar-link p-0">Inbox</a></li>
        <li>
          <a href="javascript:void()" class="sidebar-link p-0">
            Account Setting
          </a>
        </li> -->
        <li>
          <a
            href="javascript:void()"
            (click)="logout()"
            class="sidebar-link p-0"
          >
            Salir
          </a>
        </li>
      </ul>
    </li>
    <!-- First level menu -->
    <li class="sidebar-item" (click)="goHome()">
      <a class="sidebar-link" [routerLink]="['/starter']">
        <img
          class="sidebar-img"
          src="../../assets/images/icon/ICONOS__address home type.png"
        />
        <span class="hide-menu">Home</span>
      </a>
    </li>
    <ng-container *ngFor="let sidebarnavItem of sidebarnavItems">
      <li
        class="sidebar-item"
        [class.active]="showMenu === sidebarnavItem.title"
        (click)="sidebarnavItem.submenu.length !== 0 ? '' : handleNotify('')"
        [routerLinkActive]="sidebarnavItem.submenu.length !== 0 ? '' : 'active'"
        *permissionRequired="sidebarnavItem.permissionRequired"
      >
        <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
          <img [src]="[sidebarnavItem.icon]" width="25px" height="25px" />
          <span class="hide-menu">{{ sidebarnavItem.title | translate }}</span>
        </div>
        <a
          class="sidebar-link waves-effect waves-dark"
          [routerLink]="
            sidebarnavItem.class === '' ? [sidebarnavItem.path] : null
          "
          [ngClass]="[sidebarnavItem.class]"
          *ngIf="!sidebarnavItem.extralink"
          (click)="
            addExpandClass(sidebarnavItem.title);
            handleNotify(sidebarnavItem.title)
          "
          [routerLinkActive]="
            sidebarnavItem.submenu.length !== 0 ? '' : 'active'
          "
        >
          <img class="sidebar-img" [src]="[sidebarnavItem.icon]" />
          <!-- <i-feather
          [name]="sidebarnavItem.icon"
          [ngClass]="[sidebarnavItem.icon]"
        ></i-feather> -->
          <span class="hide-menu">
            {{ sidebarnavItem.title | translate }}
            <span
              *ngIf="sidebarnavItem.label !== ''"
              [ngClass]="['sidebarnavItem.labelClass']"
            >
              {{ sidebarnavItem.label | translate }}
            </span>
          </span>
        </a>
        <!-- Second level menu -->
        <ul
          aria-expanded="false"
          class="collapse first-level"
          *ngIf="sidebarnavItem.submenu.length > 0"
          [ngClass]="{ in: showMenu === sidebarnavItem.title }"
        >
          <ng-container
            *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
          >
            <li
              class="sidebar-item"
              *permissionRequired="sidebarnavSubItem.permissionRequired"
              (click)="
                sidebarnavSubItem.submenu.length !== 0 ? '' : handleNotify('')
              "
              [class.active]="showSubMenu === sidebarnavSubItem.title"
              [routerLinkActive]="
                sidebarnavSubItem.submenu.length > 0 ? '' : 'active'
              "
            >
              <a
                class="sidebar-link"
                [routerLink]="
                  sidebarnavSubItem.submenu.length > 0
                    ? null
                    : [sidebarnavSubItem.path]
                "
                [routerLinkActive]="
                  sidebarnavSubItem.submenu.length > 0
                    ? ''
                    : 'router-link-active'
                "
                [ngClass]="[sidebarnavSubItem.class]"
                *ngIf="!sidebarnavSubItem.extralink"
                (click)="addActiveClass(sidebarnavSubItem.title)"
              >
                <img class="sidebar-img" [src]="[sidebarnavSubItem.icon]" />
                <span class="hide-menu">
                  {{ sidebarnavSubItem.title | translate }}
                </span>
              </a>
              <!-- Third level menu -->
              <ul
                aria-expanded="false"
                class="collapse Second-level"
                *ngIf="sidebarnavSubItem.submenu.length > 0"
                [ngClass]="{ in: showSubMenu === sidebarnavSubItem.title }"
              >
                <ng-container
                  *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu"
                >
                  <li
                    class="sidebar-item"
                    *permissionRequired="
                      sidebarnavSubsubItem.permissionRequired
                    "
                    routerLinkActive="active"
                    [ngClass]="[sidebarnavSubsubItem.class]"
                  >
                    <a
                      class="sidebar-link"
                      [routerLink]="[sidebarnavSubsubItem.path]"
                      *ngIf="!sidebarnavSubsubItem.extralink"
                      [routerLinkActive]="
                        sidebarnavSubsubItem.submenu.length > 0
                          ? ''
                          : 'router-link-active'
                      "
                    >
                      <img
                        class="sidebar-img"
                        [src]="[sidebarnavSubsubItem.icon]"
                      />
                      <span class="hide-menu">
                        {{ sidebarnavSubsubItem.title | translate }}
                      </span>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>
        </ul>
      </li>
    </ng-container>
  </ul>
</nav>
