import { HttpClient, HttpParams } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import { BearingSchema, CompanySchema } from '@schemas/core';
import { IBearingDatatable } from '@shared/interfaces';
import { VehicleTypeEnum } from '@shared/enums';
import { DateTimeService } from '../datetime/datetime.service';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class BearingService extends BaseService {
  constructor(
    protected http: HttpClient,
    private currencyPipe: CurrencyPipe,
    private dateTimeService: DateTimeService,
  ) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<BearingSchema[]> {
    let params = new HttpParams()
      .set('_sort', ' vehicle_type:ASC')
      .set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<BearingSchema[]>(`${this.API_URL}/bearings`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams().set('_sort', 'vehicle_type:ASC');

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/bearings/count`, {
      params,
    });
  }

  findOne(id: string): Observable<BearingSchema> {
    return this.http.get<BearingSchema>(`${this.API_URL}/bearings/${id}`);
  }

  create(bearing: BearingSchema): Observable<BearingSchema> {
    return this.http.post<BearingSchema>(`${this.API_URL}/bearings`, bearing);
  }

  update(bearing: BearingSchema): Observable<BearingSchema> {
    return this.http.put<BearingSchema>(
      `${this.API_URL}/bearings/${bearing?.id}`,
      bearing,
    );
  }

  delete(id: string): Observable<BearingSchema> {
    return this.http.delete<BearingSchema>(`${this.API_URL}/bearings/${id}`);
  }

  toDataTable(bearing: Array<BearingSchema>): Array<IBearingDatatable> {
    return bearing.map((b) => ({
      start_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        b.start_date,
      ),
      end_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        b.end_date,
      ),
      value: this.currencyPipe
        .transform(b.value, 'COP', 'symbol-narrow')
        .replace(',', 'x')
        .replace('.', ',')
        .replace('x', '.'),
      vehicle_type: VehicleTypeEnum[b.vehicle_type],
      company: (b.company as CompanySchema).name,
      id: b.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'value_contains',
        field: 'Valor',
        default: false,
      },
      {
        APIField: 'vehicle_type_contains',
        field: 'Clase de vehículo',
        default: true,
      },
      {
        APIField: 'company.name_contains',
        field: 'Empresa',
        default: false,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of([
      'Fecha Inicial',
      'Fecha Final',
      'Valor',
      'Clase de Vehículo',
      'Empresa',
    ]);
  }
}
