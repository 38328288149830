import {
  BaseSchema,
  AffiliateSchema,
  ContractSchema,
  EmployeeSchema,
  BearingSchema,
  AssuredSchema,
  DocumentSubjectSchema,
} from '../';

export class CompanySchema extends BaseSchema {
  nit: string;
  resolution_number: string;
  territorial_address_code: string;
  address: string;
  email: string;
  logo?: any;
  name: string;
  qualification_year: string;
  manager_name: string;
  phone_number: string;
  website: string;
  signature_image?: any;
  document_subjects?: Array<DocumentSubjectSchema> | Array<string>;
  affiliates: Array<AffiliateSchema> | Array<string>;
  contracts: Array<ContractSchema> | Array<string>;
  employees: Array<EmployeeSchema> | Array<string>;
  bearings: Array<BearingSchema> | Array<string>;
  assureds: Array<AssuredSchema> | Array<string>;
  prefix_bearings: string;
  prefix_insurances: string;
  prefix_transfer_rights: string;
  prefix_renovations: string;
  prefix_loan_installments: string;
  correlative_contract: number;
  correlative_bearings: number;
  correlative_insurances: number;
  correlative_transfer_rights: number;
  correlative_renovations: number;
  correlative_loan_installments: number;
  correlative_extract_contract: number;

  constructor(params: {
    nit: string;
    resolution_number: string;
    territorial_address_code: string;
    address: string;
    email: string;
    logo?: any;
    name: string;
    qualification_year: string;
    manager_name: string;
    phone_number: string;
    website: string;
    signature_image?: string;
    document_subjects?: Array<DocumentSubjectSchema> | Array<string>;
    affiliates?: Array<AffiliateSchema> | Array<string>;
    contracts?: Array<ContractSchema> | Array<string>;
    employees?: Array<EmployeeSchema> | Array<string>;
    bearings?: Array<BearingSchema> | Array<string>;
    assureds?: Array<AssuredSchema> | Array<string>;
    prefix_bearings: string;
    prefix_insurances: string;
    prefix_transfer_rights: string;
    prefix_renovations: string;
    prefix_loan_installments: string;
    correlative_contract: number;
    correlative_bearings: number;
    correlative_insurances: number;
    correlative_transfer_rights: number;
    correlative_renovations: number;
    correlative_loan_installments: number;
    correlative_extract_contract: number;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.nit = params.nit;
    this.resolution_number = params.resolution_number;
    this.territorial_address_code = params.territorial_address_code;
    this.address = params.address;
    this.email = params.email;
    this.logo = params.logo;
    this.name = params.name;
    this.qualification_year = params.qualification_year;
    this.manager_name = params.manager_name;
    this.phone_number = params.phone_number;
    this.website = params.website;
    this.signature_image = params.signature_image;
    this.affiliates = params.affiliates;
    this.contracts = params.contracts;
    this.employees = params.employees;
    this.bearings = params.bearings;
    this.assureds = params.assureds;
    this.document_subjects = params.document_subjects;
    this.prefix_bearings = params.prefix_bearings;
    this.prefix_insurances = params.prefix_insurances;
    this.prefix_transfer_rights = params.prefix_transfer_rights;
    this.prefix_renovations = params.prefix_renovations;
    this.prefix_loan_installments = params.prefix_loan_installments;
    this.correlative_contract = params.correlative_contract;
    this.correlative_bearings = params.correlative_bearings;
    this.correlative_insurances = params.correlative_insurances;
    this.correlative_transfer_rights = params.correlative_transfer_rights;
    this.correlative_renovations = params.correlative_renovations;
    this.correlative_loan_installments = params.correlative_loan_installments;
    this.correlative_extract_contract = params.correlative_extract_contract;
  }
}
