import { Injectable } from '@angular/core';

import {
  ContractorSchema,
  ContractSchema,
  ExtractContractSchema,
  MunicipalitySchema,
  RouteSchema,
  UserSchema,
  VehicleSchema,
} from '@schemas/core';
import { IHistoryExtractContractDatatable } from '@shared/interfaces';
import { Observable, of } from 'rxjs';
import { DateTimeService } from '../datetime/datetime.service';

@Injectable({
  providedIn: 'root',
})
export class HistoryExtractContractService {
  constructor(private dateTimeService: DateTimeService) {}

  toDataTable(
    extractContract: ExtractContractSchema[],
  ): IHistoryExtractContractDatatable[] {
    return extractContract.map((ec) => ({
      correlative: ec.extract_contract.correlative,
      contract_number: (ec.extract_contract?.contract as ContractSchema)
        ?.contract_number,
      contractor_name: (
        (ec.extract_contract?.contract as ContractSchema)
          ?.contractor as ContractorSchema
      )?.name,
      routes_name: this.getRoutesName(ec.extract_contract?.routes),
      vehicle_plate: ec.vehicle_plate,
      extract_contract_start_date:
        this.dateTimeService.getDataTableCellDateFromAPIDate(
          ec.extract_contract.start_date,
        ),
      extract_contract_end_date:
        this.dateTimeService.getDataTableCellDateFromAPIDate(
          ec.extract_contract.end_date,
        ),
      user_username: (ec.user as UserSchema)?.username,
      extract_contract_created_date:
        this.dateTimeService.getDataTableCellDateFromAPIDateTime(ec.created_at),
      download_file_url: `/fuec/extract-contract/view/pdf/${ec.extract_contract.code}`,
      id: ec.id,
    }));
  }

  getTableHead(): Observable<string[]> {
    return of([
      'N°. FUEC',
      'N°. Contrato',
      'Contratante',
      'Recorridos',
      'Placa',
      'Fecha de inicio',
      'Fecha de fin',
      'Usuario que genera',
      'Fecha de generación',
    ]);
  }

  /**
   * Get routes and contact in one string
   *
   * @param {RouteSchema[]} routes
   * @return {*}  {string}
   * @memberof HistoryExtractContractService
   */
  getRoutesName(routes: RouteSchema[]): string {
    return routes
      ?.map(
        (r) =>
          `${(r.from_municipality as MunicipalitySchema).name} - ${
            (r.to_municipality as MunicipalitySchema).name
          }`,
      )
      .join(', ');
  }
}
