/**
 * Se definen aquí las constantes númericas que representan cada uno
 * de los permisos necesarios en frontend.
 *
 * Se definen según módulo y acción dentro de cada módulo.
 *
 * Se definen según grupos de 100, de manera que haya un margen
 * para agregar un nuevo permiso correspondiente a un módulo.
 *
 * El patrón para el nombre de las constantes es:
 *
 * NOMBREMODULO_ACCION
 */

export const PERMISSIONS = {
  /* Definiciones generales */
  EVERYONE: 1,

  /* Definición de módulo de Administración 100 */
  ADMINISTRATION: 100,

  /* Definición  de módulo de Affiliados 200*/
  ADMINISTRATION_AFFILIATES: 200,
  ADMINISTRATION_AFFILIATES_CREATE: 201,
  ADMINISTRATION_AFFILIATES_VIEW: 202,
  ADMINISTRATION_AFFILIATES_EDIT: 203,
  ADMINISTRATION_AFFILIATES_LIST: 204,
  ADMINISTRATION_AFFILIATES_DELETE: 205,
  ADMINISTRATION_AFFILIATES_CREATE_ACCOUNT: 206,

  /* Definición  de módulo de Vehículos 300*/
  ADMINISTRATION_VEHICLES: 300,
  ADMINISTRATION_VEHICLES_CREATE: 301,
  ADMINISTRATION_VEHICLES_VIEW: 302,
  ADMINISTRATION_VEHICLES_EDIT: 303,
  ADMINISTRATION_VEHICLES_LIST: 304,
  ADMINISTRATION_VEHICLES_DELETE: 305,
  ADMINISTRATION_VEHICLES_ASSOCIATE_DRIVER: 306,
  ADMINISTRATION_VEHICLES_ASSOCIATE_DRIVER_ADD: 307,
  ADMINISTRATION_VEHICLES_DISASSOCIATE_DRIVER: 308,

  /* Definición de módulo de conductores 400 */
  ADMINISTRATION_DRIVERS: 400,
  ADMINISTRATION_DRIVERS_CREATE: 401,
  ADMINISTRATION_DRIVERS_VIEW: 402,
  ADMINISTRATION_DRIVERS_EDIT: 403,
  ADMINISTRATION_DRIVERS_LIST: 404,
  ADMINISTRATION_DRIVERS_DELETE: 405,

  /* Definición de módulo de Convenios de Transporte 500 */
  ADMINISTRATION_TRANSPORT_AGREEMENTS: 500,
  ADMINISTRATION_TRANSPORT_AGREEMENTS_CREATE: 501,
  ADMINISTRATION_TRANSPORT_AGREEMENTS_VIEW: 502,
  ADMINISTRATION_TRANSPORT_AGREEMENTS_EDIT: 503,
  ADMINISTRATION_TRANSPORT_AGREEMENTS_LIST: 504,
  ADMINISTRATION_TRANSPORT_AGREEMENTS_DELETE: 505,

  /* Definición de módulo de Rodamientos 600 */
  ADMINISTRATION_BEARINGS: 600,
  ADMINISTRATION_BEARINGS_CREATE: 601,
  ADMINISTRATION_BEARINGS_VIEW: 602,
  ADMINISTRATION_BEARINGS_EDIT: 603,
  ADMINISTRATION_BEARINGS_LIST: 604,
  ADMINISTRATION_BEARINGS_DELETE: 605,

  /* Definición de módulo de Seguros 700 */
  ADMINISTRATION_INSURANCES: 700,
  ADMINISTRATION_INSURANCES_CREATE: 701,
  ADMINISTRATION_INSURANCES_VIEW: 702,
  ADMINISTRATION_INSURANCES_EDIT: 703,
  ADMINISTRATION_INSURANCES_LIST: 704,
  ADMINISTRATION_INSURANCES_DELETE: 705,

  /* Definición de módulo de Municipios 800 */
  ADMINISTRATION_MUNICIPALITIES: 800,
  ADMINISTRATION_MUNICIPALITIES_CREATE: 801,
  ADMINISTRATION_MUNICIPALITIES_VIEW: 802,
  ADMINISTRATION_MUNICIPALITIES_EDIT: 803,
  ADMINISTRATION_MUNICIPALITIES_LIST: 804,
  ADMINISTRATION_MUNICIPALITIES_DELETE: 805,

  /* Definición de módulo de Recorridos 900 */
  ADMINISTRATION_ROUTES: 900,
  ADMINISTRATION_ROUTES_CREATE: 901,
  ADMINISTRATION_ROUTES_VIEW: 902,
  ADMINISTRATION_ROUTES_EDIT: 903,
  ADMINISTRATION_ROUTES_LIST: 904,
  ADMINISTRATION_ROUTES_DELETE: 905,

  /* Definición de módulo de Cargos 1000 */
  ADMINISTRATION_POSITIONS: 1000,
  ADMINISTRATION_POSITIONS_CREATE: 1001,
  ADMINISTRATION_POSITIONS_VIEW: 1002,
  ADMINISTRATION_POSITIONS_EDIT: 1003,
  ADMINISTRATION_POSITIONS_LIST: 1004,
  ADMINISTRATION_POSITIONS_DELETE: 1005,

  /* Definición de módulo de Empleados 1100*/
  ADMINISTRATION_EMPLOYEES: 1100,
  ADMINISTRATION_EMPLOYEES_CREATE: 1101,
  ADMINISTRATION_EMPLOYEES_VIEW: 1102,
  ADMINISTRATION_EMPLOYEES_EDIT: 1103,
  ADMINISTRATION_EMPLOYEES_LIST: 1104,
  ADMINISTRATION_EMPLOYEES_DELETE: 1105,

  /* Definición de módulo de Contratos 1200*/
  CONTRACTS: 1200,

  /* Definición de módulo de Contratantes (Clientes) 1300*/
  CONTRACTS_CONTRACTORS: 1300,
  CONTRACTS_CONTRACTORS_CREATE: 1301,
  CONTRACTS_CONTRACTORS_VIEW: 1302,
  CONTRACTS_CONTRACTORS_EDIT: 1303,
  CONTRACTS_CONTRACTORS_LIST: 1304,
  CONTRACTS_CONTRACTORS_DELETE: 1305,

  /* Definición de módulo de Contratos 1400*/
  CONTRACTS_CONTRACTS: 1400,
  CONTRACTS_CONTRACTS_CREATE: 1401,
  CONTRACTS_CONTRACTS_VIEW: 1402,
  CONTRACTS_CONTRACTS_EDIT: 1403,
  CONTRACTS_CONTRACTS_LIST: 1404,
  CONTRACTS_CONTRACTS_DELETE: 1405,
  CONTRACTS_CONTRACTS_ASSOCIATE_VEHICLE: 1406,
  CONTRACTS_CONTRACTS_ASSOCIATE_ROUTE: 1407,
  CONTRACTS_CONTRACTS_VIEW_CONTRACT_FILE: 1408,
  CONTRACTS_CONTRACTS_UPLOAD_CONTRACT_FILE: 1409,
  CONTRACTS_CONTRACTS_ASSOCIATE_VEHICLE_ADD: 1410,
  CONTRACTS_CONTRACTS_DISASSOCIATE_VEHICLE: 1411,
  CONTRACTS_CONTRACTS_ASSOCIATE_ROUTE_ADD: 1412,
  CONTRACTS_CONTRACTS_DISASSOCIATE_ROUTE: 1413,

  /* Definición de módulo de Objetos de Contratos 1500 */
  CONTRACTS_CONTRACT_OBJECTS: 1500,
  CONTRACTS_CONTRACT_OBJECTS_CREATE: 1501,
  CONTRACTS_CONTRACT_OBJECTS_VIEW: 1502,
  CONTRACTS_CONTRACT_OBJECTS_EDIT: 1503,
  CONTRACTS_CONTRACT_OBJECTS_LIST: 1504,
  CONTRACTS_CONTRACT_OBJECTS_DELETE: 1505,

  /* Definición de módulo de FUEC (Extracto de Contrato) 1600 */
  FUEC: 1600,

  /* Definición de módulo de Generar FUEC 1700 */
  // FUEC_GENERATE_FUEC: 1700,
  FUEC_GENERATE_FUEC_CREATE: 1701,
  // FUEC_GENERATE_FUEC_VIEW: 1702,
  // FUEC_GENERATE_FUEC_EDIT: 1703,
  // FUEC_GENERATE_FUEC_LIST: 1704,
  // FUEC_GENERATE_FUEC_DELETE: 1705,

  /* Definición de módulo de Historial de FUEC 1800 */
  // FUEC_HISTORY_FUEC: 1800,
  // FUEC_HISTORY_FUEC_CREATE: 1801,
  FUEC_HISTORY_FUEC_VIEW: 1802,
  // FUEC_HISTORY_FUEC_EDIT: 1803,
  // FUEC_HISTORY_FUEC_LIST: 1804,
  // FUEC_HISTORY_FUEC_DELETE: 1805,

  /* Definición de módulo de Gestión de documentos 1900 */
  DOCUMENT_MANAGEMENT: 1900,

  /* Definición de módulo de Documentos 2000*/
  DOCUMENT_MANAGEMENT_DOCUMENTS: 2000,
  DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST: 2001,
  DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST: 2002,
  DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST: 2003,
  DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST: 2004,
  DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST: 2005,
  DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST: 2006,
  DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT: 2007,
  DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT: 2008,
  DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT: 2009,
  DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT: 2010,
  DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT: 2011,
  DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT: 2012,
  DOCUMENT_MANAGEMENT_DOCUMENTS_VERIFY_DOCUMENTS_AUTOMATICALLY: 2013,
  DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT: 2014,
  DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT: 2015,
  DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT: 2016,
  DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT: 2017,
  DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT: 2018,
  DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT: 2019,
  DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT: 2020,
  DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT: 2021,
  DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT: 2022,

  /* Definición de módulo de Asignación de documentos 2100 */
  DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED: 2100,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_AFFILIATE_LIST: 2101,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_VEHICLE_LIST: 2102,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_DRIVER_LIST: 2103,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_AFFILIATE_LIST_ASSIGN: 2104,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_VEHICLE_LIST_ASSIGN: 2105,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_DRIVER_LIST_ASSIGN: 2106,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_AFFILIATE_LIST_UNASSIGN: 2107,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_VEHICLE_LIST_UNASSIGN: 2108,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_DRIVER_LIST_UNASSIGN: 2109,

  /* Definición de módulo de Tipos de documentos 2200 */
  DOCUMENT_MANAGEMENT_DOCUMENT_TYPES: 2200,
  DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_CREATE: 2201,
  DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_VIEW: 2202,
  DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_EDIT: 2203,
  DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_LIST: 2204,
  DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_DELETE: 2205,

  /* Definición de módulo de Revisión de documentos 2300 */
  DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW: 2300,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_AFFILIATES_LIST: 2301,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_VEHICLES_LIST: 2302,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_DRIVERS_LIST: 2303,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_AFFILIATES_LIST_VERIFY_DOCUMENTS: 2304,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_VEHICLES_LIST_VERIFY_DOCUMENTS: 2305,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_DRIVERS_LIST_VERIFY_DOCUMENTS: 2306,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_AFFILIATES_LIST_VIEW_DOCUMENT: 2307,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_VEHICLES_LIST_VIEW_DOCUMENT: 2308,
  DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_DRIVERS_LIST_VIEW_DOCUMENT: 2309,

  /* Definición de módulo de Documentos por vencer 2400 */
  DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT: 2400,
  DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_AFFILIATES_LIST: 2401,
  DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_VEHICLES_LIST: 2402,
  DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_DRIVERS_LIST: 2403,
  DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_AFFILIATES_LIST_VIEW_DOCUMENT: 2404,
  DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_VEHICLES_LIST_VIEW_DOCUMENT: 2405,
  DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_DRIVERS_LIST_VIEW_DOCUMENT: 2406,
  /*DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_AFFILIATES_LIST_LOAD_DOCUMENT: 2407,
  DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_VEHICLES_LIST_LOAD_DOCUMENT: 2408,
  DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_DRIVERS_LIST_LOAD_DOCUMENT: 2409,*/

  /* Definición de módulo de Empresas 2500 */
  COMPANIES_MANAGEMENT: 2500,

  /* Definición de módulo de Mis empresas 2600 */
  COMPANIES_MANAGEMENT_BUSINESS: 2600,
  COMPANIES_MANAGEMENT_BUSINESS_CREATE: 2601,
  COMPANIES_MANAGEMENT_BUSINESS_VIEW: 2602,
  COMPANIES_MANAGEMENT_BUSINESS_EDIT: 2603,
  COMPANIES_MANAGEMENT_BUSINESS_LIST: 2604,
  COMPANIES_MANAGEMENT_BUSINESS_DELETE: 2605,

  /* Definición de módulo de Gestión documental de empresas 2700 */
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS: 2700,

  /* Definición de módulo de Gestión documental de empresas / Documentos 2800 */
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS: 2800,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST: 2801,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST: 2802,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT: 2803,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT: 2804,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT: 2805,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT: 2806,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT: 2807,

  /* Definición de módulo de Gestión documental de empresas / Asignación de Documentos 2900 */
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED: 2900,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED_DOCUMENTS_COMPANIES_LIST_ASSIGN: 2901,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED_DOCUMENTS_COMPANIES_LIST_UNASSIGN: 2902,

  /* Definición de módulo de Gestión documental de empresas / tipo de Documentos 3000 */
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES: 3000,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_CREATE: 3001,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_VIEW: 3002,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_EDIT: 3003,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_LIST: 3004,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_DELETE: 3005,

  /* Definición de módulo de Gestión documental de empresas / Documentos por vencer 3100 */
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_ALERT: 3100,
  COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_ALERT_COMPANY_LIST_VIEW_DOCUMENT: 3101,
  /* COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_ALERT_COMPANY_LIST_LOAD_DOCUMENT: 3102,*/

  /* Definición de módulo de Seguridad 3200 */
  SECURITY: 3200,

  /* Definición de módulo de seguridad / Usuarios 3300 */
  SECURITY_USERS: 3300,
  SECURITY_USERS_CREATE: 3301,
  SECURITY_USERS_VIEW: 3302,
  SECURITY_USERS_EDIT: 3303,
  SECURITY_USERS_LIST: 3304,

  /* Definición de módulo de seguridad / Roles 3400 */
  SECURITY_ROLES: 3400,
  SECURITY_ROLES_CREATE: 3401,
  SECURITY_ROLES_VIEW: 3402,
  SECURITY_ROLES_EDIT: 3403,
  SECURITY_ROLES_LIST: 3404,
  SECURITY_ROLES_DELETE: 3405,

  /* Definición de módulo de seguridad / Usuarios 3400 */
  SECURITY_MESSAGES: 3500,
  SECURITY_MESSAGES_CREATE: 3501,
  SECURITY_MESSAGES_VIEW: 3502,
  // SECURITY_MESSAGE_EDIT: 3503, No se debe poder editar mensajes
  SECURITY_MESSAGES_LIST: 3504,
  // SECURITY_MESSAGE_DELETE: 3505, No se debe poder borrar mensajes

  /* Cuando está activo los usuarios con este rol
     solo tendrán acceso a aus propios registros */
  ACCESS_ONLY_OWN_RECORDS: 4000,
};
