import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LoginNewSchema, LoginSchema } from '@schemas/core';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly API_URL = environment.API_URL;

  userToken: string | null = null;

  constructor(private http: HttpClient, private userService: UserService) {}

  get isAuthenticated(): boolean {
    return this.userToken !== null ? true : false;
  }

  login(payload: LoginNewSchema): Observable<LoginSchema> {
    return this.http
      .post<LoginSchema>(`${this.API_URL}/auth/local`, payload)
      .pipe(
        map((r) => {
          this.saveToken(r.jwt);
          this.userService.loadUserProfile();
          return r;
        }),
      );
  }

  logout(): void {
    this.deleteToken();
  }

  saveToken(token: string): void {
    this.userToken = token;
  }

  recoverToken(): string {
    return this.userToken;
  }

  deleteToken(): void {
    this.userToken = null;
  }

  redirectToLogin(): void {
    const loginPage = `${window.location.protocol}//${window.location.host}/login`;
    window.location.href = loginPage;
  }

  logoutAndRedirect(): void {
    this.logout();
    this.redirectToLogin();
  }
}
