import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { getDate, getYear, format } from 'date-fns';
import { es } from 'date-fns/locale';

import { BaseService } from '../base/base.service';
import {
  LOGO_MIN_TRANSPORTE_COLOMBIA,
  LOGO_COTECNA_ISO_9001_2015,
} from '@shared/consts';
import {
  CompanySchema,
  ContractObjectSchema,
  ContractorSchema,
  ContractSchema,
  DriverSchema,
  MunicipalitySchema,
  RouteSchema,
  VehicleSchema,
  DepartmentSchema,
  ExtractContractSchema,
  DocumentSubjectSchema,
  TransportAgreementSchema,
  ExtractContractFieldSchema,
} from '@schemas/core';
import { TransportAgreementTypeEnum, TripTypeEnum } from '@shared/enums';
import { DateTimeService } from '../datetime/datetime.service';
import { environment } from 'src/environments/environment';
import { IStateDataTable } from '@shared/data-table-v2/state.data-table.interface';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export interface IFilterByDateAndVehiclePlate {
  startDate: string;
  endDate: string;
  vehiclePlate: string;
}

@Injectable({
  providedIn: 'root',
})
export class ExtractContractService extends BaseService {
  private readonly NO_APLICA = 'N/A';

  readonly ID_LICENCIA_CONDUCTOR = environment.ID_LICENCIA_CONDUCTOR;

  constructor(
    protected http: HttpClient,
    private dateTimeService: DateTimeService,
  ) {
    super(http);
  }

  find(): Observable<ExtractContractSchema[]> {
    return this.http.get<ExtractContractSchema[]>(
      `${this.API_URL}/extract-contracts/`,
    );
  }

  /**
   * La búsqueda por fechas se hace por la fecha de generación del FUEC
   * Se buscan los FUEC cuya fecha de generación esté dentro del rango
   * de fechas que se pasan por parámetro
   *
   * @param {IFilterByDateAndVehiclePlate} qParams
   * @param {(IStateDataTable | null)} [stateDataTable=null]
   * @return {*}  {Observable<ExtractContractSchema[]>}
   * @memberof ExtractContractService
   */
  findByDateRangeAndVehiclePlate(
    qParams: IFilterByDateAndVehiclePlate,
    stateDataTable: IStateDataTable | null = null,
  ): Observable<ExtractContractSchema[]> {
    let params = new HttpParams().set('_sort', 'correlative_field:DESC');
    params = qParams?.startDate
      ? params.append('created_at_gte', qParams.startDate)
      : params;

    // Se le debe sumar un día a la fecha ya que por defecto
    // la fecha va con la hora 00, por tanto en la práctica
    // el 'lte' no funciona
    if (qParams?.endDate) {
      const endDate = this.dateTimeService.getAPIDateFromDate(
        this.dateTimeService.getDateAddDays(
          this.dateTimeService.getDateFromAPIDate(qParams?.endDate),
          1,
        ),
      );

      params = params.append('created_at_lte', endDate);
    }
    params = qParams?.vehiclePlate
      ? params.append('vehicle_plate', qParams.vehiclePlate)
      : params;

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.append(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.append('_limit', stateDataTable.pageSize)
      : params;

    return this.http.get<ExtractContractSchema[]>(
      `${this.API_URL}/extract-contracts/with-out-file/`,
      { params },
    );
  }

  /**
   * Cantidad de registros según el filtro
   *
   * @param {IFilterByDateAndVehiclePlate} qParams
   * @param {(IStateDataTable | null)} [stateDataTable=null]
   * @return {*}  {Observable<ExtractContractSchema[]>}
   * @memberof ExtractContractService
   */
  countByDateRangeAndVehiclePlate(
    qParams: IFilterByDateAndVehiclePlate,
  ): Observable<number> {
    let params = new HttpParams();
    params = qParams?.startDate
      ? params.append('created_at_gte', qParams.startDate)
      : params;
    params = qParams?.endDate
      ? params.append('created_at_lte', qParams.endDate)
      : params;
    params = qParams?.vehiclePlate
      ? params.append('vehicle_plate', qParams.vehiclePlate)
      : params;

    return this.http.get<number>(`${this.API_URL}/extract-contracts/count`, {
      params,
    });
  }

  findOneByCode(code: string): Observable<ExtractContractSchema> {
    return this.http
      .get<ExtractContractSchema>(
        `${this.API_URL}/extract-contracts?code_field=${code}`,
      )
      .pipe(
        map((e) => {
          if (e) {
            return new ExtractContractSchema(e[0]);
          }
          return e;
        }),
      );
  }

  findByIdContract(idContrato: string): Observable<ExtractContractSchema[]> {
    return this.find().pipe(
      map((e) =>
        e.filter(
          (ec) =>
            (
              ec.extract_contract_field.contract as ContractSchema
            )?.id.toString() === idContrato,
        ),
      ),
    );
  }

  create(
    extractContract: ExtractContractSchema,
  ): Observable<ExtractContractSchema> {
    return this.http.post<ExtractContractSchema>(
      `${this.API_URL}/extract-contracts`,
      extractContract,
    );
  }

  createPdf(extractContract: ExtractContractSchema): any {
    const EXTRACT_CONTRACT = extractContract.extract_contract;
    // eslint-disable-next-line max-len
    const verifyDocumentURL = `${window.location.protocol}//${window.location.host}/fuec/extract-contract/view/pdf/${EXTRACT_CONTRACT.code}`;
    const tripType =
      EXTRACT_CONTRACT.trip_type === TripTypeEnum.ONE_WAY
        ? '(Solo Ida)'
        : '(Ida y Regreso)';

    let DIGITAL_SIGNATURE_PDF: any = [
      {
        text: 'Firma digital segun ley 527 de 1999, decreto 1074 de 2015.',
        alignment: 'center',
        margin: [0, 60, 0, 0],
        fontSize: 8,
      },
    ];

    if (EXTRACT_CONTRACT.has_digital_signature) {
      DIGITAL_SIGNATURE_PDF = [
        {
          image: `${EXTRACT_CONTRACT.digital_signature_base64}`,
          maxHeight: 40,
          alignment: 'center',
          margin: [0, 20, 0, 0],
        },
        {
          text: 'Firma digital segun ley 527 de 1999, decreto 1074 de 2015.',
          alignment: 'center',
          fontSize: 8,
        },
      ];
    }

    const pdfDefinition = {
      // a string or { width: number, height: number }
      pageSize: 'A4',

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      //pageMargins: [ 30, 30, 30, 30 ],
      content: [
        {
          style: 'tableContainer',
          table: {
            widths: ['*'],
            body: [
              [
                {
                  stack: [
                    {
                      // Cabecera de imágenes
                      layout: 'noBorders',
                      style: 'tableHeader',
                      table: {
                        widths: ['auto', '*', '*'],
                        body: [
                          [
                            {
                              image: `data:image/jpeg;base64,${LOGO_MIN_TRANSPORTE_COLOMBIA}`,
                              width: 195,
                            },
                            {
                              image: `data:image/jpeg;base64,${LOGO_COTECNA_ISO_9001_2015}`,
                              width: 80,
                            },
                            {
                              image: `${EXTRACT_CONTRACT.logo_company_base64}`,
                              width: 70,
                            },
                          ],
                        ],
                      },
                    },
                    {
                      // Título
                      style: 'title',
                      text: [
                        '\nFORMATO UNICO DE EXTRACTO DEL CONTRATO DEL SERVICIO PUBLICO DE\n\
                              TRANSPORTE TERRESTRE AUTOMOTOR ESPECIAL\n',

                        {
                          style: 'code',
                          text: `No ${EXTRACT_CONTRACT.code.replace(
                            /-/g,
                            ' ',
                          )}\n\n`,
                        },
                      ],
                    },
                    {
                      // Información General del FUEC
                      style: 'generalInfo',
                      text: [
                        {
                          text: `RAZON SOCIAL DE LA EMPRESA DE TRANSPORTE ESPECIAL: `,
                          style: 'negrita',
                        },
                        `${
                          (EXTRACT_CONTRACT.contract.company as CompanySchema)
                            .name
                        }.\n`,
                        {
                          text: `NIT: `,
                          style: 'negrita',
                        },
                        `${
                          (EXTRACT_CONTRACT.contract.company as CompanySchema)
                            .nit
                        }\n`,

                        {
                          text: `CONTRATO No: `,
                          style: 'negrita',
                        },
                        `${
                          (EXTRACT_CONTRACT.contract as ContractSchema)
                            .contract_number
                        }\n`,
                        {
                          text: `CONTRATANTE: `,
                          style: 'negrita',
                        },
                        `${
                          (
                            EXTRACT_CONTRACT.contract
                              .contractor as ContractorSchema
                          ).name
                        }\n`,
                        {
                          text: `NIT/CC: `,
                          style: 'negrita',
                        },
                        `${
                          (
                            EXTRACT_CONTRACT.contract
                              .contractor as ContractorSchema
                          ).document_number
                        }\n`,
                        {
                          text: `OBJETO CONTRATO: `,
                          style: 'negrita',
                        },
                        `${this.getContractObjet(EXTRACT_CONTRACT.contract)}\n`,
                        {
                          text: 'ORIGEN-DESTINO: ',
                          style: 'negrita',
                        },
                        `${(EXTRACT_CONTRACT.routes as RouteSchema[])
                          .map(
                            (r) =>
                              `${
                                (r.from_municipality as MunicipalitySchema).name
                              } (${
                                (
                                  (r.from_municipality as MunicipalitySchema)
                                    .department as DepartmentSchema
                                ).name
                              }) - ${
                                (r.to_municipality as MunicipalitySchema).name
                              } (${
                                (
                                  (r.to_municipality as MunicipalitySchema)
                                    .department as DepartmentSchema
                                ).name
                              })`,
                          )
                          .join(' ')} ${tripType}\n`,
                        {
                          text: `CONVENIO DE COLABORACION: `,
                          style: 'negrita',
                        },
                        `${this.getTransportAgreementType(
                          EXTRACT_CONTRACT.vehicle,
                        )}\n`,
                        {
                          text: this.getMonitoraData(EXTRACT_CONTRACT)
                            ? `MONITORA: `
                            : '',
                          style: 'negrita',
                        },
                        `${this.getMonitoraData(EXTRACT_CONTRACT)}\n`,
                      ],
                    },
                    {
                      // Título de la tabla de Vigencia
                      style: 'tableTitle',
                      text: ' VIGENCIA DEL CONTRATO ',
                    },
                    {
                      // Tabla de Vigencia
                      style: 'tableVigencia',
                      table: {
                        widths: ['*', '*', '*', '*'],
                        body: [
                          [
                            { style: 'tableTitle', border: [false], text: '' },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'Día',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'Mes',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'Año',
                            },
                          ],
                          [
                            { alignment: 'left', text: 'FECHA INICIAL' },
                            {
                              alignment: 'center',
                              text: `${getDate(
                                this.dateTimeService.getDate(
                                  EXTRACT_CONTRACT.start_date,
                                ),
                              )}`,
                            },
                            {
                              alignment: 'center',
                              text: `${format(
                                this.dateTimeService.getDate(
                                  EXTRACT_CONTRACT.start_date,
                                ),
                                'LLLL',
                                {
                                  locale: es,
                                },
                              ).replace(/^\w/, (c) => c.toUpperCase())}`,
                            },
                            {
                              alignment: 'center',
                              text: `${getYear(
                                this.dateTimeService.getDate(
                                  EXTRACT_CONTRACT.start_date,
                                ),
                              )}`,
                            },
                          ],
                          [
                            {
                              alignment: 'left',
                              text: 'FECHA VENCIMIENTO',
                            },
                            {
                              alignment: 'center',
                              text: `${getDate(
                                this.dateTimeService.getDate(
                                  EXTRACT_CONTRACT.end_date,
                                ),
                              )}`,
                            },
                            {
                              alignment: 'center',
                              text: `${format(
                                this.dateTimeService.getDate(
                                  EXTRACT_CONTRACT.end_date,
                                ),
                                'LLLL',
                                {
                                  locale: es,
                                },
                              ).replace(/^\w/, (c) => c.toUpperCase())}`,
                            },
                            {
                              alignment: 'center',
                              text: `${getYear(
                                this.dateTimeService.getDate(
                                  EXTRACT_CONTRACT.end_date,
                                ),
                              )}`,
                            },
                          ],
                        ],
                      },
                    },
                    {
                      // Título de la tabla de Características del Vehículo
                      style: 'tableTitle',
                      margin: [0, 7.5, 0, 0],
                      text: ' CARACTERÍSTICAS DEL VEHÍCULO ',
                    },
                    {
                      // Tabla de Características del Vehículo
                      style: 'tableVehiculo',
                      table: {
                        widths: ['*', '*', '*', '*'],
                        body: [
                          [
                            {
                              style: 'tableTitle',
                              border: [false],
                              text: 'Placa',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'Modelo',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'Marca',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'Clase',
                            },
                          ],
                          [
                            {
                              alignment: 'center',
                              text: `${
                                (EXTRACT_CONTRACT.vehicle as VehicleSchema)
                                  .plate
                              }`,
                            },
                            {
                              alignment: 'center',
                              text: `${
                                (EXTRACT_CONTRACT.vehicle as VehicleSchema)
                                  .year ?? ''
                              }`,
                            },
                            {
                              alignment: 'center',
                              text: `${
                                (EXTRACT_CONTRACT.vehicle as VehicleSchema)
                                  .brand
                              }`,
                            },
                            {
                              alignment: 'center',
                              text: `${
                                (EXTRACT_CONTRACT.vehicle as VehicleSchema).type
                              }`,
                            },
                          ],
                          [
                            {
                              margin: [0, 7.5, 0, 0],
                              border: [false, false, false, false],
                              style: 'tableTitle',
                              text: 'N° interno',
                              colSpan: 2,
                            },
                            {},
                            {
                              margin: [0, 7.5, 0, 0],
                              border: [false, false, false, false],
                              style: 'tableTitle',
                              text: 'N° de tarjeta de operación',
                              colSpan: 2,
                            },
                            {},
                          ],
                          [
                            {
                              text: `${
                                (EXTRACT_CONTRACT.vehicle as VehicleSchema)
                                  .vehicle_number
                              }`,
                              colSpan: 2,
                            },
                            {},
                            {
                              text: `${EXTRACT_CONTRACT.operation_card_number}`,
                              colSpan: 2,
                            },
                            {},
                          ],
                        ],
                      },
                    },
                    {
                      // Título de la tabla de CONDUCTORES
                      style: 'tableTitle',
                      margin: [0, 7.5, 0, 0],
                      text: '',
                    },
                    {
                      // Tabla de Conductores
                      style: 'tableDrivers',
                      table: {
                        widths: [60, 173, '*', '*', '*'],
                        body: [
                          [
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: '',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'Nombres y apellidos',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'N° de cédula',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'N° de licencia',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'Vigencia',
                            },
                          ],
                          [
                            {
                              alignment: 'left',
                              text: `CONDUCTOR 1`,
                            },
                            {
                              alignment: 'left',
                              text: this.getDriverFullName(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[0],
                              ),
                            },

                            {
                              alignment: 'center',
                              text: this.getDriverDocumentNumber(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[0],
                              ),
                            },
                            {
                              alignment: 'center',
                              text: this.getDriverLicenseNumber(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[0],
                              ),
                            },
                            {
                              alignment: 'center',
                              text: this.getDriverLicenseEndDate(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[0],
                              ),
                            },
                          ],
                          [
                            {
                              alignment: 'left',
                              text: `CONDUCTOR 2`,
                            },
                            {
                              alignment:
                                this.getDriverFullName(
                                  (
                                    EXTRACT_CONTRACT.drivers as DriverSchema[]
                                  )[1],
                                ) === this.NO_APLICA
                                  ? 'center'
                                  : 'left',
                              text: this.getDriverFullName(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[1],
                              ),
                            },
                            {
                              alignment: 'center',
                              text: this.getDriverDocumentNumber(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[1],
                              ),
                            },
                            {
                              alignment: 'center',
                              text: this.getDriverLicenseNumber(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[1],
                              ),
                            },
                            {
                              alignment: 'center',
                              text: this.getDriverLicenseEndDate(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[1],
                              ),
                            },
                          ],
                          [
                            {
                              alignment: 'left',
                              text: `CONDUCTOR 3`,
                            },
                            {
                              alignment:
                                this.getDriverFullName(
                                  (
                                    EXTRACT_CONTRACT.drivers as DriverSchema[]
                                  )[2],
                                ) === this.NO_APLICA
                                  ? 'center'
                                  : 'left',
                              text: this.getDriverFullName(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[2],
                              ),
                            },
                            {
                              alignment: 'center',
                              text: this.getDriverDocumentNumber(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[2],
                              ),
                            },
                            {
                              alignment: 'center',
                              text: this.getDriverLicenseNumber(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[2],
                              ),
                            },
                            {
                              alignment: 'center',
                              text: this.getDriverLicenseEndDate(
                                (EXTRACT_CONTRACT.drivers as DriverSchema[])[2],
                              ),
                            },
                          ],
                        ],
                      },
                    },
                    {
                      // Título de la tabla de RESPONSABLE DEL CONTRATANTE
                      style: 'tableTitle',
                      margin: [0, 7.5, 0, 0],
                      text: '',
                    },
                    {
                      // Tabla de RESPONSABLE DEL CONTRATANTE
                      style: 'tableDrivers',
                      table: {
                        widths: [60, 173, 55, 55, '*'],
                        body: [
                          [
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: '',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'Nombres y apellidos',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'N° de cédula',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'Teléfono',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: 'Dirección',
                            },
                          ],
                          [
                            {
                              alignment: 'left',
                              text: `Responsable del contratante`,
                            },
                            {
                              alignment: 'left',
                              text: `${
                                (
                                  EXTRACT_CONTRACT.contract
                                    .contractor as ContractorSchema
                                ).legal_representative_name ?? ''
                              }`,
                            },
                            {
                              alignment: 'center',
                              text: `${
                                (
                                  EXTRACT_CONTRACT.contract
                                    .contractor as ContractorSchema
                                ).legal_representative_document_number ?? ''
                              }`,
                            },
                            {
                              alignment: 'center',
                              text: `${
                                (
                                  EXTRACT_CONTRACT.contract
                                    .contractor as ContractorSchema
                                ).phone_number ?? ''
                              }`,
                            },
                            {
                              alignment: 'justify',
                              text: `${
                                (
                                  EXTRACT_CONTRACT.contract
                                    .contractor as ContractorSchema
                                ).address ?? ''
                              }`,
                            },
                          ],
                        ],
                      },
                    },
                    {
                      // Título de la tabla de Datos de empresa de transporte
                      style: 'tableTitle',
                      margin: [0, 0, 0, 0],
                      text: '',
                    },
                    {
                      // Tabla de Datos de empresa de transporte
                      style: 'tableCompany',
                      table: {
                        widths: ['*', '*'],
                        body: [
                          [
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: '',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: '',
                            },
                          ],
                          [
                            {
                              margin: [0, 20, 0, 20],
                              alignment: 'left',
                              text: [
                                {
                                  style: 'negrita',
                                  text: 'Dirección: ',
                                },
                                {
                                  text: `${
                                    (
                                      EXTRACT_CONTRACT.contract
                                        .company as CompanySchema
                                    ).address ?? ''
                                  }\n`,
                                },
                                {
                                  style: 'negrita',
                                  text: 'Teléfono: ',
                                },
                                {
                                  text: `${
                                    (
                                      EXTRACT_CONTRACT.contract
                                        .company as CompanySchema
                                    ).phone_number ?? ''
                                  }\n`,
                                },
                                {
                                  style: 'negrita',
                                  text: 'Email: ',
                                },
                                {
                                  text: `${
                                    (
                                      EXTRACT_CONTRACT.contract
                                        .company as CompanySchema
                                    ).email ?? ''
                                  }\n`,
                                },
                              ],
                            },
                            {
                              stack: DIGITAL_SIGNATURE_PDF,
                            },
                          ],
                        ],
                      },
                    },
                    {
                      // Tabla de QR
                      style: 'tableQR',
                      margin: [0, 0, 0, 5],
                      table: {
                        widths: [100, '*'],
                        body: [
                          [
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: '',
                            },
                            {
                              style: 'tableTitle',
                              border: [false],
                              alignment: 'center',
                              text: '',
                            },
                          ],
                          [
                            {
                              margin: [5, 5, 5, 5],
                              alignment: 'center',
                              qr: `${verifyDocumentURL}`,
                              fit: 75,
                            },
                            {
                              margin: [0, 30, 0, 0],
                              alignment: 'left',
                              // eslint-disable-next-line max-len
                              text: `Para verificar este documento, por favor leer el codigo QR por medio de la camara de su dispositivo y/o la aplicacion correspondiente.`,
                            },
                          ],
                        ],
                      },
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          style: 'textResolution',
          text: 'Formato según resolución 6652 del 27 de Diciembre del 2019',
        },
      ],
      footer: {
        // Tabla de Footer
        style: 'tableFooter',
        table: {
          widths: ['*'],
          body: [
            [
              {
                margin: [5, 5, 5, 5],
                text: [
                  'Este documento fue expedido por ',
                  { text: 'www.vizormovil.com', bold: true },
                  ', desarrollado por ',
                  { text: 'VIZOR.', bold: true },
                ],
              },
            ],
          ],
        },
      },
      styles: {
        tableContainer: {},
        tableHeader: {
          margin: [25, 5, 0, 0],
          alignment: 'center',
        },
        title: { fontSize: 11, bold: false, alignment: 'center' },
        code: {
          bold: true,
          alignment: 'center',
        },
        negrita: {
          bold: true,
        },
        generalInfo: {
          fontSize: 10,
          bold: false,
          alignment: 'left',
          lineHeight: 1.3,
          margin: [0, 3, 0, 0],
        },
        tableTitle: {
          fontSize: 8,
          bold: true,
          alignment: 'center',
        },
        tableVigencia: {
          fontSize: 8,
        },
        tableVehiculo: {
          fontSize: 8,
          alignment: 'center',
        },
        tableDrivers: {
          fontSize: 8,
          alignment: 'center',
        },
        tableCompany: {
          fontSize: 8,
          alignment: 'center',
        },
        tableQR: {
          fontSize: 8,
          alignment: 'center',
        },
        tableFooter: {
          fontSize: 8,
          bold: false,
          alignment: 'center',
          margin: [40, 0, 40, 0],
        },
        textResolution: {
          fontSize: 8,
          bold: true,
          alignment: 'center',
          margin: [0, 5, 0, 0],
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    return pdfMake.createPdf(pdfDefinition);
  }

  /**
   * Get image from URL
   *
   * @param imageURL
   * @return string
   * @memberof ExtractContractService
   */
  getImageFromURL(imageURL: string): Observable<Blob> {
    return this.http.get(imageURL, { responseType: 'blob' });
  }

  generateExtractContractCode(params: {
    territorialAddressCode: string;
    resolutionNumber: string;
    qualificationYear: string;
    contractCorrelative: string;
    extractContractCorrelative: string;
  }): string {
    // a) Tres dígitos de Código de la Dirección Territorial
    // b) Cuatro últimos dígitos del Número de Resolución
    // c) Dos últimos dígitos del Año de habilitación
    // d) Cuatro dígitos del Año actual
    // e) Cuatro últimos dígitos del Consecutivo de Contrato
    // f) Cuatro últimos dígitos del Consecutivo de Extracto de Contrato
    const currentYear = new Date().getFullYear().toString();
    let code = ('000' + params.territorialAddressCode).slice(-3);
    code += '-';
    code += ('0000' + params.resolutionNumber).slice(-4);
    code += '-';
    code += ('00' + params.qualificationYear).slice(-2);
    code += '-';
    code += ('0000' + currentYear).slice(-4);
    code += '-';
    code += ('0000' + params.contractCorrelative).slice(-4);
    code += '-';
    code += ('0000' + params.extractContractCorrelative).slice(-4);
    return code;
  }

  private getDriverFullName(driver: DriverSchema | undefined): string {
    if (driver) {
      return `${driver.firstname} ${driver.lastname}`;
    }
    return `${this.NO_APLICA}`;
  }

  private getDriverDocumentNumber(driver: DriverSchema | undefined): string {
    if (driver) {
      return `${driver.document_number}`;
    }
    return `${this.NO_APLICA}`;
  }

  private getDriverLicenseNumber(driver: DriverSchema | undefined): string {
    if (driver) {
      return `${
        this.getDriverLicenseDocument(
          driver.document_subjects as DocumentSubjectSchema[],
        ).document_number
      }`;
    }
    return `${this.NO_APLICA}`;
  }

  getDriverLicenseDocument(
    documents: DocumentSubjectSchema[],
  ): DocumentSubjectSchema {
    const licenses = documents.filter(
      (l) =>
        l.document_def.toString() === this.ID_LICENCIA_CONDUCTOR.toString(),
    );

    return licenses.sort((a, b) => {
      if (a.end_validity < b.end_validity) {
        return -1;
      } else if (a.end_validity > b.end_validity) {
        return 1;
      } else {
        return 0;
      }
    })[licenses.length - 1]; // Retorna el documento con fecha de fin de vigencia mas lejana
    // este será su licencia vigente
  }

  private getDriverLicenseEndDate(driver: DriverSchema | undefined): string {
    if (driver) {
      return format(
        new Date(
          new Date(
            this.getDriverLicenseDocument(
              driver.document_subjects as DocumentSubjectSchema[],
            ).end_validity,
          ).toLocaleString('en-US', {
            timeZone: 'UTC',
          }),
        ),
        'P',
        { locale: es },
      );
    }
    return `${this.NO_APLICA}`;
  }

  private getTransportAgreementType(
    vehicle: VehicleSchema | undefined,
  ): string {
    // TODO Esto debe revisarse ya que type_transport_agreement debería ser un
    // enum y está viniendo de API como un string. Se debe revisar API y
    // adecuar el modelo en front. También verificar donde se hagan asignaciones a
    // type_transport_agreement para asignar el enum y no un string.
    const typeTransportAgreement =
      vehicle.type_transport_agreement.toUpperCase() ===
      TransportAgreementTypeEnum.NINGUNO.toString().toUpperCase()
        ? '-'
        : (vehicle.transport_agreement as TransportAgreementSchema)
            .company_name;
    return `${typeTransportAgreement}`;
  }

  private getContractObjet(contract: ContractSchema | undefined): string {
    const observations = (contract as ContractSchema)?.observations
      ? `- ${(contract as ContractSchema)?.observations}`
      : '';

    return `${
      (contract.contract_object as ContractObjectSchema)?.description
    } ${observations}`;
  }

  private getMonitoraData(extractContract: ExtractContractFieldSchema): string {
    const monitoraName = extractContract?.monitora_name
      ? `${extractContract?.monitora_name} - ${extractContract?.monitora_cedula}`
      : '';
    return monitoraName;
  }
}
