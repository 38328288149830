import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const confirmedValidator: ValidatorFn = (
  control: FormGroup,
): ValidationErrors | null => {
  const password = control.get('fileUserPassword');
  const confirmPassword = control.get('fileUserPasswordRepeat');
  if (confirmPassword.errors && !confirmPassword.errors.confirmedValidator) {
    return;
  }
  if (password.value !== confirmPassword.value) {
    confirmPassword.setErrors({ confirmedValidator: true });
  } else {
    confirmPassword.setErrors(null);
  }
};
