import { Injectable } from '@angular/core';
import { RouteInfo } from './vertical-sidebar.metadata';
import { environment } from 'src/environments/environment';
import { PERMISSIONS } from '@shared/rbac';

@Injectable({
  providedIn: 'root',
})
export class VerticalMenuItemsService {
  readonly ROUTES: RouteInfo[] = [
    /*{
      path: '',
      title: 'Personal',
      icon: 'mdi mdi-dots-horizontal',
      class: 'nav-small-cap',
      extralink: true,
      submenu: []
    },
    {
      path: '/starter',
      title: 'Starter Page',
      icon: 'home',
      class: '',
      extralink: false,
      submenu: []
    },
    {
      path: '',
      title: 'UI Components',
      icon: 'mdi mdi-dots-horizontal',
      class: 'nav-small-cap',
      extralink: true,
      submenu: []
    },*/
    {
      path: '',
      title: 'Administración',
      icon: '../../assets/images/icon/ICONOS_Contacto_del_cliente.png',
      class: 'has-arrow',
      extralink: false,
      visible: true,
      permissionRequired: PERMISSIONS.ADMINISTRATION,
      submenu: [
        {
          path: '/administration/affiliates',
          title: 'Afiliados',
          icon: '../../assets/images/icon/ICONOS_empleados.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.ADMINISTRATION_AFFILIATES,
          submenu: [],
        },
        {
          path: '/administration/vehicles',
          title: 'Vehículos',
          icon: '../../assets/images/icon/ICONOS_unidades.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.ADMINISTRATION_VEHICLES,
          submenu: [],
        },
        {
          path: '/administration/drivers',
          title: 'Conductores',
          icon: '../../assets/images/icon/ICONOS_driver.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.ADMINISTRATION_DRIVERS,
          submenu: [],
        },
        {
          path: '/administration/transportagreements',
          title: 'Convenios de Transporte',
          icon: '../../assets/images/icon/ICONOS_clientes.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS,
          submenu: [],
        },
        {
          path: '/administration/bearings',
          title: 'Rodamientos',
          icon: '../../assets/images/icon/ICONOS_GENERAL_Pagos_de_clientes.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.ADMINISTRATION_BEARINGS,
          submenu: [],
        },
        {
          path: '/administration/insurances',
          title: 'Seguros',
          icon: '../../assets/images/icon/ICONOS_tipo_de_pago_de_clientes.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.ADMINISTRATION_INSURANCES,
          submenu: [],
        },
        {
          path: '/administration/municipalities',
          title: 'Municipios',
          icon: '../../assets/images/icon/ICONOS_municipalites.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES,
          submenu: [],
        },
        {
          path: '/administration/routes',
          title: 'Recorridos',
          icon: '../../assets/images/icon/ICONOS_rutas.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.ADMINISTRATION_ROUTES,
          submenu: [],
        },
        {
          path: '/administration/charges',
          title: 'Cargos',
          icon: '../../assets/images/icon/ICONOS_Customer_affiliates.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.ADMINISTRATION_POSITIONS,
          submenu: [],
        },
        {
          path: '/administration/employees',
          title: 'Empleados',
          icon: '../../assets/images/icon/ICONOS_cuenta_bancaria_de_clientes.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.ADMINISTRATION_EMPLOYEES,
          submenu: [],
        },
      ],
    },
    {
      path: '',
      title: 'Contratos',
      icon: '../../assets/images/icon/ICONOS_driver_cv.png',
      class: 'has-arrow',
      extralink: false,
      visible: true,
      permissionRequired: PERMISSIONS.CONTRACTS,
      submenu: [
        {
          path: '/contract/contractors',
          title: 'Contratantes (Clientes)',
          icon: '../../assets/images/icon/ICONOS_Contacto_del_cliente.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.CONTRACTS_CONTRACTORS,
          submenu: [],
        },
        {
          path: '/contract/contracts',
          title: 'Contratos',
          icon: '../../assets/images/icon/ICONOS_descripcion_notificacion.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.CONTRACTS_CONTRACTS,
          submenu: [],
        },
        {
          path: '/contract/contract-objects',
          title: 'Objetos de Contrato',
          icon: '../../assets/images/icon/ICONOS_direcciones.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS,
          submenu: [],
        },
      ],
    },
    {
      path: '',
      title: 'FUEC',
      icon: '../../assets/images/icon/ICONOS_driver_cv.png',
      class: 'has-arrow',
      extralink: false,
      visible: true,
      permissionRequired: PERMISSIONS.FUEC,
      submenu: [
        {
          path: '/fuec/extract-contract',
          title: 'Generar Extracto de Contrato',
          icon: '../../assets/images/icon/ICONOS_driver_cv.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.FUEC_GENERATE_FUEC_CREATE,
          submenu: [],
        },
        {
          path: '/fuec/history-extract-contract',
          title: 'Historial de Extractos de Contratos',
          icon: '../../assets/images/icon/ICONOS_driver_cv.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.FUEC_HISTORY_FUEC_VIEW,
          submenu: [],
        },
      ],
    },
    {
      path: '',
      title: 'Gestión Documental',
      icon: '../../assets/images/icon/ICONOS_tipo_de_servicio.png',
      class: 'has-arrow',
      extralink: false,
      visible: true,
      permissionRequired: PERMISSIONS.DOCUMENT_MANAGEMENT,
      submenu: [
        {
          path: '/document-management/documents',
          title: 'Documentos',
          icon: '../../assets/images/icon/ICONOS_tipo_de_servicio.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS,
          submenu: [],
        },
        {
          path: '/document-management/documents-requested',
          title: 'Asignación de Documentos',
          icon: '../../assets/images/icon/ICONOS_rates.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired:
            PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED,
          submenu: [],
        },
        {
          path: '/document-management/documents-types',
          title: 'Tipo de Documentos',
          icon: '../../assets/images/icon/ICONOS_formulario_notificacion.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES,
          submenu: [],
        },
        {
          path: '/document-management/documents-review',
          title: 'Revisión de Documentos',
          icon: '../../assets/images/icon/ICONOS_tipo_de_tarifas.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW,
          submenu: [],
        },
        {
          path: '/document-management/documents-alert',
          title: 'Documentos por Vencer',
          icon: '../../assets/images/icon/ICONOS_solicitud_de_servicio_al_cliente.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT,
          submenu: [],
        },
      ],
    },
    {
      path: '',
      title: 'Gestión de Empresas',
      icon: '../../assets/images/icon/ICONOS_personas.png',
      class: 'has-arrow',
      extralink: false,
      visible: true,
      permissionRequired: PERMISSIONS.COMPANIES_MANAGEMENT,
      submenu: [
        {
          path: '/companies-management/business',
          title: 'Mis Empresas',
          icon: '../../assets/images/icon/ICONOS_personas.png',
          class: '',
          extralink: false,
          visible: true,
          permissionRequired: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS,
          submenu: [],
        },
        {
          path: '',
          title: 'Gestión Documental',
          icon: '../../assets/images/icon/ICONOS_tipo_de_servicio.png',
          class: 'has-arrow',
          extralink: false,
          visible: true,
          permissionRequired:
            PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS,
          submenu: [
            {
              path: '/companies-management/companies-documents',
              title: 'Documentos',
              icon: '../../assets/images/icon/ICONOS_tipo_de_servicio.png',
              class: '',
              extralink: false,
              visible: true,
              permissionRequired:
                PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS,
              submenu: [],
            },
            {
              path: '/companies-management/companies-documents-requested',
              title: 'Asignación de Documentos',
              icon: '../../assets/images/icon/ICONOS_rates.png',
              class: '',
              extralink: false,
              visible: true,
              permissionRequired:
                PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED,
              submenu: [],
            },
            {
              path: '/companies-management/companies-documents-types',
              title: 'Tipo de Documentos',
              icon: '../../assets/images/icon/ICONOS_formulario_notificacion.png',
              class: '',
              extralink: false,
              visible: true,
              permissionRequired:
                PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES,
              submenu: [],
            },
            {
              path: '/companies-management/companies-documents-alert',
              title: 'Documentos por Vencer',
              icon: '../../assets/images/icon/ICONOS_solicitud_de_servicio_al_cliente.png',
              class: '',
              extralink: false,
              visible: true,
              permissionRequired:
                PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_ALERT,
              submenu: [],
            },
          ],
        },
      ],
    },
    {
      path: '',
      title: 'Seguridad',
      icon: '../../assets/images/icon/ICONOS_punto_de_parada.png',
      class: 'has-arrow',
      extralink: false,
      visible: true,
      permissionRequired: PERMISSIONS.SECURITY,
      submenu: [
        {
          path: '/security/user-management',
          title: 'Usuarios',
          icon: '../../assets/images/icon/ICONOS_empleados.png',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
          permissionRequired: PERMISSIONS.SECURITY_USERS,
        },
        {
          path: '/security/role-management',
          title: 'Roles',
          icon: '../../assets/images/icon/ICONOS_roles.png',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
          permissionRequired: PERMISSIONS.SECURITY_ROLES,
        },
        {
          path: '/security/message-management',
          title: 'Mensajes del sistema',
          icon: '../../assets/images/icon/ICONOS_roles.png',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
          permissionRequired: PERMISSIONS.SECURITY_MESSAGES,
        },
      ],
    },
    {
      path: '/starter',
      title: 'Menu principal',
      icon: 'fas fa-bullseye',
      class: 'has-arrow',
      extralink: false,
      visible: false,
      submenu: [
        {
          path: '/starter',
          title: 'Menu principal',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: false,
          submenu: [],
        },
      ],
    },

    {
      path: 'component',
      title: 'Component',
      icon: 'cpu',
      class: 'has-arrow',
      extralink: false,
      visible: environment.showWidgets,
      submenu: [
        {
          path: '/component/accordion',
          title: 'Accordion',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/alert',
          title: 'Alert',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/carousel',
          title: 'Carousel',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/dropdown',
          title: 'Dropdown',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/modal',
          title: 'Modal',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/pagination',
          title: 'Pagination',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/poptool',
          title: 'Popover & Tooltip',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/progressbar',
          title: 'Progressbar',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/rating',
          title: 'Ratings',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/tabs',
          title: 'Tabs',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/timepicker',
          title: 'Timepicker',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/buttons',
          title: 'Button',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/cards',
          title: 'Card',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
        {
          path: '/component/notifier',
          title: 'Notifier',
          icon: 'mdi mdi-adjust',
          class: '',
          extralink: false,
          visible: true,
          submenu: [],
        },
      ],
    },
  ];

  constructor() {}
}
