import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageTrayService {
  private messages = new BehaviorSubject<number>(0);
  messages$ = this.messages.asObservable();

  add(amount: number): void {
    this.messages.next(this.messages.value + amount);
  }

  clear(): void {
    this.messages.next(0);
  }
}
