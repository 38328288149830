import { IPermissionModule } from './permission-module.interface';
import { PERMISSIONS } from './permission.const';

export const PERMISSION_MODULES: IPermissionModule[] = [
  {
    id: PERMISSIONS.ADMINISTRATION,
    name: 'Administración',
    description: 'Módulo de administración',
    permission_required: PERMISSIONS.ADMINISTRATION,
    endpoints_required: [],
    visible: true,
    father: null,
    children: [
      {
        id: PERMISSIONS.ADMINISTRATION_AFFILIATES,
        name: 'Afiliados',
        description: 'Permiso de acceso para la administración de afiliados',
        permission_required: PERMISSIONS.ADMINISTRATION_AFFILIATES,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.ADMINISTRATION,
        children: [
          {
            id: PERMISSIONS.ADMINISTRATION_AFFILIATES_CREATE,
            name: 'Registro de afiliado',
            description: 'Permiso para el registro de afiliado',
            permission_required: PERMISSIONS.ADMINISTRATION_AFFILIATES_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-type',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'car-owner',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'car-owner',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_AFFILIATES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_AFFILIATES_VIEW,
            name: 'Ver afiliado',
            description: 'Permiso para visualizar un afiliado',
            permission_required: PERMISSIONS.ADMINISTRATION_AFFILIATES_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.ADMINISTRATION_AFFILIATES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_AFFILIATES_EDIT,
            name: 'Editar afiliado',
            description: 'Permiso para editar un afiliado',
            permission_required: PERMISSIONS.ADMINISTRATION_AFFILIATES_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-type',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'car-owner',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'car-owner',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'car-owner',
                action: 'update',
              },
              {
                module: 'users-permissions',
                controller: 'user',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_AFFILIATES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_AFFILIATES_LIST,
            name: 'Lista de afiliados',
            description: 'Permiso para visualizar la lista de afiliados',
            permission_required: PERMISSIONS.ADMINISTRATION_AFFILIATES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'car-owner',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'car-owner',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_AFFILIATES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_AFFILIATES_DELETE,
            name: 'Borrar un afiliado',
            description: 'Permiso para borrar un afiliado',
            permission_required: PERMISSIONS.ADMINISTRATION_AFFILIATES_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'car-owner',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'car-owner',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_AFFILIATES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_AFFILIATES_CREATE_ACCOUNT,
            name: 'Crear una cuenta de afiliado',
            description: 'Permiso para crear una cuenta de afiliado',
            permission_required:
              PERMISSIONS.ADMINISTRATION_AFFILIATES_CREATE_ACCOUNT,
            endpoints_required: [
              {
                module: 'users-permissions',
                controller: 'user',
                action: 'createAffiliate',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_AFFILIATES,
            children: [],
          },
        ],
      },
      {
        id: PERMISSIONS.ADMINISTRATION_VEHICLES,
        name: 'Vehículos',
        description: 'Permiso de acceso para la administración de vehículos',
        permission_required: PERMISSIONS.ADMINISTRATION_VEHICLES,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.ADMINISTRATION,
        children: [
          {
            id: PERMISSIONS.ADMINISTRATION_VEHICLES_CREATE,
            name: 'Registro de vehículo',
            description: 'Permiso para el registro de vehículo',
            permission_required: PERMISSIONS.ADMINISTRATION_VEHICLES_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'car-owner',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'transport-agreements',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'vehicle',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'vehicle',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_VEHICLES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_VEHICLES_VIEW,
            name: 'Ver vehículo',
            description: 'Permiso para visualizar un vehículo',
            permission_required: PERMISSIONS.ADMINISTRATION_VEHICLES_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.ADMINISTRATION_VEHICLES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_VEHICLES_EDIT,
            name: 'Editar vehículo',
            description: 'Permiso para editar un vehículo',
            permission_required: PERMISSIONS.ADMINISTRATION_VEHICLES_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'car-owner',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'transport-agreements',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'vehicle',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'vehicle',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'vehicle',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_VEHICLES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_VEHICLES_LIST,
            name: 'Lista de vehículos',
            description: 'Permiso para visualizar la lista de vehículos',
            permission_required: PERMISSIONS.ADMINISTRATION_VEHICLES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'vehicle',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_VEHICLES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_VEHICLES_DELETE,
            name: 'Borrar un vehículo',
            description: 'Permiso para borrar un vehículo',
            permission_required: PERMISSIONS.ADMINISTRATION_VEHICLES_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'vehicle',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'vehicle',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_VEHICLES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_VEHICLES_ASSOCIATE_DRIVER,
            name: 'Ver conductores asociados a vehículo',
            description:
              'Permiso para ver los conductores asociados a un vehículo',
            permission_required:
              PERMISSIONS.ADMINISTRATION_VEHICLES_ASSOCIATE_DRIVER,
            endpoints_required: [
              {
                module: 'application',
                controller: 'driver',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'vehicle',
                action: 'findOne',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_VEHICLES,
            children: [
              {
                id: PERMISSIONS.ADMINISTRATION_VEHICLES_ASSOCIATE_DRIVER_ADD,
                name: 'Asociar un conductor a vehículo',
                description: 'Permiso para asociar un conductor a un vehículo',
                permission_required:
                  PERMISSIONS.ADMINISTRATION_VEHICLES_ASSOCIATE_DRIVER_ADD,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'vehicle',
                    action: 'linkDrivers',
                  },
                ],
                visible: true,
                father: PERMISSIONS.ADMINISTRATION_VEHICLES_ASSOCIATE_DRIVER,
                children: [],
              },
              {
                id: PERMISSIONS.ADMINISTRATION_VEHICLES_DISASSOCIATE_DRIVER,
                name: 'Desasociar conductor a vehículo',
                description: 'Permiso para desasociar conductor a un vehículo',
                permission_required:
                  PERMISSIONS.ADMINISTRATION_VEHICLES_DISASSOCIATE_DRIVER,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'vehicle',
                    action: 'unlinkDrivers',
                  },
                ],
                visible: true,
                father: PERMISSIONS.ADMINISTRATION_VEHICLES_ASSOCIATE_DRIVER,
                children: [],
              },
            ],
          },
        ],
      },
      {
        // Conductores
        id: PERMISSIONS.ADMINISTRATION_DRIVERS,
        name: 'Conductores',
        description: 'Permiso de acceso para la administración de conductores',
        permission_required: PERMISSIONS.ADMINISTRATION_DRIVERS,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.ADMINISTRATION,
        children: [
          {
            id: PERMISSIONS.ADMINISTRATION_DRIVERS_CREATE,
            name: 'Registro de conductor',
            description: 'Permiso para el registro de conductores',
            permission_required: PERMISSIONS.ADMINISTRATION_DRIVERS_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-type',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'driver',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'driver',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_DRIVERS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_DRIVERS_VIEW,
            name: 'Ver conductor',
            description: 'Permiso para visualizar un conductor',
            permission_required: PERMISSIONS.ADMINISTRATION_DRIVERS_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.ADMINISTRATION_DRIVERS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_DRIVERS_EDIT,
            name: 'Editar conductor',
            description: 'Permiso para editar un conductor',
            permission_required: PERMISSIONS.ADMINISTRATION_DRIVERS_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-type',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'driver',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'driver',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'driver',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_DRIVERS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_DRIVERS_LIST,
            name: 'Lista de conductores',
            description: 'Permiso para visualizar la lista de conductores',
            permission_required: PERMISSIONS.ADMINISTRATION_DRIVERS_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'driver',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'driver',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_DRIVERS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_DRIVERS_DELETE,
            name: 'Borrar un conductor',
            description: 'Permiso para borrar un conductor',
            permission_required: PERMISSIONS.ADMINISTRATION_DRIVERS_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'driver',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'driver',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_DRIVERS,
            children: [],
          },
        ],
      },
      {
        // Convenios de Transporte
        id: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS,
        name: 'Convenios de transporte',
        description:
          'Permiso de acceso para la administración de convenios de transporte',
        permission_required: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.ADMINISTRATION,
        children: [
          {
            id: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS_CREATE,
            name: 'Registro de convenio de transporte',
            description: 'Permiso para el registro de convenio de transporte',
            permission_required:
              PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'transport-agreements',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'transport-agreements',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS_VIEW,
            name: 'Ver convenio de transporte',
            description: 'Permiso para visualizar un convenio de transporte',
            permission_required:
              PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS_EDIT,
            name: 'Editar convenio de transporte',
            description: 'Permiso para editar un convenio de transporte',
            permission_required:
              PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'transport-agreements',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'transport-agreements',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'transport-agreements',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS_LIST,
            name: 'Lista de convenios de transporte',
            description:
              'Permiso para visualizar la lista de convenios de transporte',
            permission_required:
              PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'transport-agreements',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'transport-agreements',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS_DELETE,
            name: 'Borrar un convenio de transporte',
            description: 'Permiso para borrar un convenio de transporte',
            permission_required:
              PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'transport-agreements',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'transport-agreements',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_TRANSPORT_AGREEMENTS,
            children: [],
          },
        ],
      },
      {
        // Administración / Rodamientos
        id: PERMISSIONS.ADMINISTRATION_BEARINGS,
        name: 'Rodamientos',
        description: 'Permiso de acceso para la administración de rodamientos',
        permission_required: PERMISSIONS.ADMINISTRATION_BEARINGS,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.ADMINISTRATION,
        children: [
          {
            id: PERMISSIONS.ADMINISTRATION_BEARINGS_CREATE,
            name: 'Registro de rodamiento',
            description: 'Permiso para el registro de rodamiento',
            permission_required: PERMISSIONS.ADMINISTRATION_BEARINGS_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'bearing',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_BEARINGS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_BEARINGS_VIEW,
            name: 'Ver rodamiento',
            description: 'Permiso para visualizar un rodamiento',
            permission_required: PERMISSIONS.ADMINISTRATION_BEARINGS_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.ADMINISTRATION_BEARINGS_VIEW,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_BEARINGS_EDIT,
            name: 'Editar rodamiento',
            description: 'Permiso para editar un rodamiento',
            permission_required: PERMISSIONS.ADMINISTRATION_BEARINGS_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'bearing',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'bearing',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_BEARINGS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_BEARINGS_LIST,
            name: 'Lista de rodamientos',
            description: 'Permiso para visualizar la lista de rodamientos',
            permission_required: PERMISSIONS.ADMINISTRATION_BEARINGS_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'bearing',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'bearing',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_BEARINGS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_BEARINGS_DELETE,
            name: 'Borrar un rodamiento',
            description: 'Permiso para borrar un rodamiento',
            permission_required: PERMISSIONS.ADMINISTRATION_BEARINGS_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'bearing',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_BEARINGS,
            children: [],
          },
        ],
      },
      {
        // Administración / Seguros
        id: PERMISSIONS.ADMINISTRATION_INSURANCES,
        name: 'Seguros',
        description: 'Permiso de acceso para la administración de seguros',
        permission_required: PERMISSIONS.ADMINISTRATION_INSURANCES,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.ADMINISTRATION,
        children: [
          {
            id: PERMISSIONS.ADMINISTRATION_INSURANCES_CREATE,
            name: 'Registro de seguro',
            description: 'Permiso para el registro de seguro',
            permission_required: PERMISSIONS.ADMINISTRATION_INSURANCES_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'assured',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_INSURANCES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_INSURANCES_VIEW,
            name: 'Ver seguro',
            description: 'Permiso para visualizar un seguro',
            permission_required: PERMISSIONS.ADMINISTRATION_INSURANCES_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.ADMINISTRATION_INSURANCES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_INSURANCES_EDIT,
            name: 'Editar seguro',
            description: 'Permiso para editar un seguro',
            permission_required: PERMISSIONS.ADMINISTRATION_INSURANCES_VIEW,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'assured',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'assured',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_INSURANCES_VIEW,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_INSURANCES_LIST,
            name: 'Lista de seguros',
            description: 'Permiso para visualizar la lista de seguros',
            permission_required: PERMISSIONS.ADMINISTRATION_INSURANCES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'assured',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'assured',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_INSURANCES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_INSURANCES_DELETE,
            name: 'Borrar un seguro',
            description: 'Permiso para borrar un seguro',
            permission_required: PERMISSIONS.ADMINISTRATION_INSURANCES_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'assured',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_INSURANCES,
            children: [],
          },
        ],
      },
      {
        // Administración / Municipios
        id: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES,
        name: 'Municipios',
        description: 'Permiso de acceso para la administración de municipios',
        permission_required: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.ADMINISTRATION,
        children: [
          {
            id: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES_CREATE,
            name: 'Registro de municipios',
            description: 'Permiso para el registro de municipios',
            permission_required:
              PERMISSIONS.ADMINISTRATION_MUNICIPALITIES_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'department',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'municipality',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'municipality',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES_VIEW,
            name: 'Ver municipio',
            description: 'Permiso para visualizar un municipio',
            permission_required: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES_EDIT,
            name: 'Editar municipio',
            description: 'Permiso para editar un municipio',
            permission_required: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'department',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'municipality',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'municipality',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'municipality',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES_LIST,
            name: 'Lista de municipios',
            description: 'Permiso para visualizar la lista de municipios',
            permission_required: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'municipality',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'municipality',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES_DELETE,
            name: 'Borrar un municipio',
            description: 'Permiso para borrar un municipio',
            permission_required:
              PERMISSIONS.ADMINISTRATION_MUNICIPALITIES_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'municipality',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'municipality',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_MUNICIPALITIES,
            children: [],
          },
        ],
      },
      {
        // Administración / Recorridos
        id: PERMISSIONS.ADMINISTRATION_ROUTES,
        name: 'Recorridos',
        description: 'Permiso de acceso para la administración de recorridos',
        permission_required: PERMISSIONS.ADMINISTRATION_ROUTES,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.ADMINISTRATION,
        children: [
          {
            id: PERMISSIONS.ADMINISTRATION_ROUTES_CREATE,
            name: 'Registro de recorrido',
            description: 'Permiso para el registro de recorrido',
            permission_required: PERMISSIONS.ADMINISTRATION_ROUTES_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'municipality',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'route',
                action: 'create',
              },
              {
                module: 'application',
                controller: 'department',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_ROUTES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_ROUTES_VIEW,
            name: 'Ver recorrido',
            description: 'Permiso para visualizar un recorrido',
            permission_required: PERMISSIONS.ADMINISTRATION_ROUTES_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.ADMINISTRATION_ROUTES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_ROUTES_EDIT,
            name: 'Editar recorrido',
            description: 'Permiso para editar un recorrido',
            permission_required: PERMISSIONS.ADMINISTRATION_ROUTES_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'municipality',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'route',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'route',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_ROUTES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_ROUTES_LIST,
            name: 'Lista de recorridos',
            description: 'Permiso para visualizar la lista de recorridos',
            permission_required: PERMISSIONS.ADMINISTRATION_ROUTES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'route',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'route',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_ROUTES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_ROUTES_DELETE,
            name: 'Borrar un recorrido',
            description: 'Permiso para borrar un recorrido',
            permission_required: PERMISSIONS.ADMINISTRATION_ROUTES_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'route',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'route',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_ROUTES,
            children: [],
          },
        ],
      },
      {
        // Administración / Cargos
        id: PERMISSIONS.ADMINISTRATION_POSITIONS,
        name: 'Cargos',
        description: 'Permiso de acceso para la administración de cargos',
        permission_required: PERMISSIONS.ADMINISTRATION_POSITIONS,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.ADMINISTRATION,
        children: [
          {
            id: PERMISSIONS.ADMINISTRATION_POSITIONS_CREATE,
            name: 'Registro de cargo',
            description: 'Permiso para el registro de cargo',
            permission_required: PERMISSIONS.ADMINISTRATION_POSITIONS_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'position',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_POSITIONS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_POSITIONS_VIEW,
            name: 'Ver cargo',
            description: 'Permiso para visualizar un cargo',
            permission_required: PERMISSIONS.ADMINISTRATION_POSITIONS_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.ADMINISTRATION_POSITIONS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_POSITIONS_EDIT,
            name: 'Editar cargo',
            description: 'Permiso para editar un cargo',
            permission_required: PERMISSIONS.ADMINISTRATION_POSITIONS_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'position',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'position',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_POSITIONS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_POSITIONS_LIST,
            name: 'Lista de cargos',
            description: 'Permiso para visualizar la lista de cargos',
            permission_required: PERMISSIONS.ADMINISTRATION_POSITIONS_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'position',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'position',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_POSITIONS,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_POSITIONS_DELETE,
            name: 'Borrar un cargo',
            description: 'Permiso para borrar un cargo',
            permission_required: PERMISSIONS.ADMINISTRATION_POSITIONS_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'position',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'position',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_POSITIONS,
            children: [],
          },
        ],
      },
      {
        id: PERMISSIONS.ADMINISTRATION_EMPLOYEES,
        name: 'Empleados',
        description: 'Permiso de acceso para la administración de empleados',
        permission_required: PERMISSIONS.ADMINISTRATION_EMPLOYEES,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.ADMINISTRATION,
        children: [
          {
            id: PERMISSIONS.ADMINISTRATION_EMPLOYEES_CREATE,
            name: 'Registro de empleado',
            description: 'Permiso para el registro de empleado',
            permission_required: PERMISSIONS.ADMINISTRATION_EMPLOYEES_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'position',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'employee',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'employee',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_EMPLOYEES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_EMPLOYEES_VIEW,
            name: 'Ver empleado',
            description: 'Permiso para visualizar un empleado',
            permission_required: PERMISSIONS.ADMINISTRATION_EMPLOYEES_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.ADMINISTRATION_EMPLOYEES,
            children: [],
          },
          {
            // Administración / Empleados
            id: PERMISSIONS.ADMINISTRATION_EMPLOYEES_EDIT,
            name: 'Editar empleado',
            description: 'Permiso para editar un empleado',
            permission_required: PERMISSIONS.ADMINISTRATION_EMPLOYEES_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'position',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'employee',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'employee',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'employee',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_EMPLOYEES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_EMPLOYEES_LIST,
            name: 'Lista de empleados',
            description: 'Permiso para visualizar la lista de empleados',
            permission_required: PERMISSIONS.ADMINISTRATION_EMPLOYEES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'employee',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'employee',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_EMPLOYEES,
            children: [],
          },
          {
            id: PERMISSIONS.ADMINISTRATION_EMPLOYEES_DELETE,
            name: 'Borrar un empleado',
            description: 'Permiso para borrar un empleado',
            permission_required: PERMISSIONS.ADMINISTRATION_EMPLOYEES_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'employee',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.ADMINISTRATION_EMPLOYEES,
            children: [],
          },
        ],
      },
    ],
  },
  {
    // Contratos
    id: PERMISSIONS.CONTRACTS,
    name: 'Contratos',
    description: 'Módulo de contratos',
    permission_required: PERMISSIONS.CONTRACTS,
    endpoints_required: [],
    visible: true,
    father: null,
    children: [
      {
        // Contratos / Contratantes
        id: PERMISSIONS.CONTRACTS_CONTRACTORS,
        name: 'Contratantes (Clientes)',
        description:
          'Permiso de acceso para la administración de contratantes (Clientes)',
        permission_required: PERMISSIONS.CONTRACTS_CONTRACTORS,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.CONTRACTS,
        children: [
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTORS_CREATE,
            name: 'Registro de contratante',
            description: 'Permiso para el registro de contratante',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACTORS_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'contractor',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-type',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contractor',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTORS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTORS_VIEW,
            name: 'Ver contratante',
            description: 'Permiso para visualizar un contratante',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACTORS_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.CONTRACTS_CONTRACTORS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTORS_EDIT,
            name: 'Editar contratante',
            description: 'Permiso para editar un contratante',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACTORS_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'contractor',
                action: 'update',
              },
              {
                module: 'application',
                controller: 'contractor',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'contractor',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-type',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTORS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTORS_LIST,
            name: 'Lista de contratantes',
            description: 'Permiso para visualizar la lista de contratantes',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACTORS_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'contractor',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contractor',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTORS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTORS_DELETE,
            name: 'Borrar un contratante',
            description: 'Permiso para borrar un contratante',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACTORS_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'contractor',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'contractor',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTORS,
            children: [],
          },
        ],
      },
      {
        // Contratos / Contratos
        id: PERMISSIONS.CONTRACTS_CONTRACTS,
        name: 'Contratos',
        description: 'Permiso de acceso para la administración de contratos',
        permission_required: PERMISSIONS.CONTRACTS_CONTRACTS,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.CONTRACTS,
        children: [
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTS_CREATE,
            name: 'Registro de contrato',
            description: 'Permiso para el registro de contrato',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACTS_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contract-objects',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contractor',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contract',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contract',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTS_VIEW,
            name: 'Ver contrato',
            description: 'Permiso para visualizar un contrato',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACTS_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.CONTRACTS_CONTRACTS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTS_EDIT,
            name: 'Editar contrato',
            description: 'Permiso para editar un contrato',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACTS_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contract-objects',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contractor',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contract',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'contract',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contract',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTS_LIST,
            name: 'Lista de contratos',
            description: 'Permiso para visualizar la lista de contratos',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACTS_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'contract',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contract',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTS_DELETE,
            name: 'Borrar un contrato',
            description: 'Permiso para borrar un contrato',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACTS_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'extract-contract',
                action: 'find',
              },

              {
                module: 'application',
                controller: 'contract',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_VEHICLE,
            name: 'Ver vehículos asociados a contrato',
            description: 'Permiso para ver vehículos asociados a un contrato',
            permission_required:
              PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_VEHICLE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'vehicle',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contract',
                action: 'findOne',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTS,
            children: [
              {
                id: PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_VEHICLE_ADD,
                name: 'Asociar vehículo a contrato',
                description: 'Permiso para asociar un vehículo a un contrato',
                permission_required:
                  PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_VEHICLE,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'contract',
                    action: 'update',
                  },
                ],
                visible: true,
                father: PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_VEHICLE,
                children: [],
              },
              {
                id: PERMISSIONS.CONTRACTS_CONTRACTS_DISASSOCIATE_VEHICLE,
                name: 'Desasociar vehículo a contrato',
                description:
                  'Permiso para desasociar un vehículo a un contrato',
                permission_required:
                  PERMISSIONS.CONTRACTS_CONTRACTS_DISASSOCIATE_VEHICLE,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'contract',
                    action: 'update',
                  },
                ],
                visible: true,
                father: PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_VEHICLE,
                children: [],
              },
            ],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_ROUTE,
            name: 'Ver recorridos asociados a un contrato',
            description:
              'Permiso para ver los recorridos asociados a un contrato',
            permission_required:
              PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_ROUTE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'contract',
                action: 'findOne',
              },

              {
                module: 'application',
                controller: 'municipality',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'route',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'department',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTS,
            children: [
              {
                id: PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_ROUTE_ADD,
                name: 'Asociar recorrido a contrato',
                description: 'Permiso para asociar un recorrido a un contrato',
                permission_required:
                  PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_ROUTE_ADD,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'contract',
                    action: 'update',
                  },
                ],
                visible: true,
                father: PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_ROUTE,
                children: [],
              },
              {
                id: PERMISSIONS.CONTRACTS_CONTRACTS_DISASSOCIATE_ROUTE,
                name: 'Desasociar recorrido a contrato',
                description:
                  'Permiso para desasociar un recorrido a un contrato',
                permission_required:
                  PERMISSIONS.CONTRACTS_CONTRACTS_DISASSOCIATE_ROUTE,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'contract',
                    action: 'update',
                  },
                ],
                visible: true,
                father: PERMISSIONS.CONTRACTS_CONTRACTS_ASSOCIATE_ROUTE,
                children: [],
              },
            ],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTS_VIEW_CONTRACT_FILE,
            name: 'Ver archivo de contrato',
            description: 'Permiso para ver archivo de contrato',
            permission_required:
              PERMISSIONS.CONTRACTS_CONTRACTS_VIEW_CONTRACT_FILE,
            endpoints_required: [],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACTS_UPLOAD_CONTRACT_FILE,
            name: 'Adjuntar el archivo del contrato',
            description: 'Permiso para adjuntar el archivo del contrato',
            permission_required:
              PERMISSIONS.CONTRACTS_CONTRACTS_UPLOAD_CONTRACT_FILE,
            endpoints_required: [],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACTS,
            children: [],
          },
        ],
      },
      {
        // Contratos / Objeto de contratos
        id: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS,
        name: 'Objetos de contrato',
        description:
          'Permiso de acceso para la administración de objetos de contrato',
        permission_required: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.CONTRACTS,
        children: [
          {
            id: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS_CREATE,
            name: 'Registro de objeto de contrato',
            description: 'Permiso para el registro de objeto de contrato',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'contract-objects',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS_VIEW,
            name: 'Ver objeto de contrato',
            description: 'Permiso para visualizar un objeto de contrato',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS_EDIT,
            name: 'Editar objeto de contrato',
            description: 'Permiso para editar un objeto de contrato',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'contract-objects',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'contract-objects',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS_LIST,
            name: 'Lista de objetos de contrato',
            description:
              'Permiso para visualizar la lista de objetos de contrato',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'contract-objects',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'contract-objects',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS,
            children: [],
          },
          {
            id: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS_DELETE,
            name: 'Borrar un objeto de contrato',
            description: 'Permiso para borrar un objeto de contrato',
            permission_required: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'contract-objects',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'contract-objects',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.CONTRACTS_CONTRACT_OBJECTS,
            children: [],
          },
        ],
      },
    ],
  },
  {
    // FUEC
    id: PERMISSIONS.FUEC,
    name: 'FUEC',
    description: 'Módulo de FUEC',
    permission_required: PERMISSIONS.FUEC,
    endpoints_required: [],
    visible: true,
    father: null,
    children: [
      // FUEC / Generar extracto
      {
        id: PERMISSIONS.FUEC_GENERATE_FUEC_CREATE,
        name: 'Generar extracto de contrato',
        description:
          'Permiso de acceso para la generación de extractos de contrato',
        permission_required: PERMISSIONS.FUEC_GENERATE_FUEC_CREATE,
        endpoints_required: [
          {
            module: 'application',
            controller: 'extract-contract',
            action: 'create',
          },
          {
            module: 'application',
            controller: 'extract-contract',
            action: 'find',
          },
          {
            module: 'application',
            controller: 'car-owner',
            action: 'find',
          },
          {
            module: 'application',
            controller: 'driver',
            action: 'find',
          },
          {
            module: 'application',
            controller: 'contractor',
            action: 'find',
          },
          {
            module: 'application',
            controller: 'vehicle',
            action: 'find',
          },
          {
            module: 'application',
            controller: 'document-subject',
            action: 'find',
          },
          {
            module: 'application',
            controller: 'document-requested',
            action: 'find',
          },
          {
            module: 'application',
            controller: 'contract',
            action: 'find',
          },
          {
            module: 'application',
            controller: 'route',
            action: 'find',
          },
          {
            module: 'application',
            controller: 'company',
            action: 'update',
          },
          {
            module: 'application',
            controller: 'department',
            action: 'find',
          },
        ],
        visible: true,
        father: PERMISSIONS.FUEC,
        children: [],
      },
      {
        // FUEC / Historial de extractos de contrato
        id: PERMISSIONS.FUEC_HISTORY_FUEC_VIEW,
        name: 'Historial de extractos de contrato',
        description:
          'Permiso de acceso para visualizar el historial de extractos de contrato',
        permission_required: PERMISSIONS.FUEC_HISTORY_FUEC_VIEW,
        endpoints_required: [
          {
            module: 'application',
            controller: 'extract-contract',
            action: 'findWithOutFile',
          },
          {
            module: 'application',
            controller: 'extract-contract',
            action: 'count',
          },
        ],
        visible: true,
        father: PERMISSIONS.FUEC,
        children: [],
      },
    ],
  },
  {
    // Gestión Documental
    id: PERMISSIONS.DOCUMENT_MANAGEMENT,
    name: 'Gestión documental',
    description: 'Módulo de gestión documental',
    permission_required: PERMISSIONS.DOCUMENT_MANAGEMENT,
    endpoints_required: [],
    visible: true,
    father: null,
    children: [
      {
        // Gestión documental / Documentos
        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS,
        name: 'Documentos',
        description: 'Permiso de acceso para la administración de documentos',
        permission_required: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.DOCUMENT_MANAGEMENT,
        children: [
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST,
            name: 'Ver lista de afiliados',
            description: 'Permiso para visualizar la lista de afiliados',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'car-owner',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-requested',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST,
                name: 'Ver lista de documentos de afiliado',
                description:
                  'Permiso para visualizar la lista de documentos de un afiliado',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'car-owner',
                    action: 'findOne',
                  },
                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'find',
                  },
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'find',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST,
                children: [
                  {
                    id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT,
                    name: 'Ver documento de afiliado',
                    description:
                      'Permiso para visualizar el documento de un afiliado',
                    permission_required:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT,
                    endpoints_required: [],
                    visible: true,
                    father:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST,
                    children: [],
                  },
                  {
                    id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                    name: 'Ver documento cargado de afiliado',
                    description:
                      'Permiso para visualizar el documento cargado de un afiliado',
                    permission_required:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                    endpoints_required: [
                      {
                        module: 'application',
                        controller: 'document-subject',
                        action: 'findOne',
                      },
                      {
                        module: 'application',
                        controller: 'document-subject',
                        action: 'find',
                      },
                    ],
                    visible: true,
                    father:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST,
                    children: [
                      {
                        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT,
                        name: 'Agregar documento de afiliado',
                        description:
                          'Permiso para agregar el documento de un afiliado',
                        permission_required:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT,
                        endpoints_required: [
                          {
                            module: 'application',
                            controller: 'document-subject',
                            action: 'create',
                          },
                        ],
                        visible: true,
                        father:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                        children: [],
                      },
                      {
                        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT,
                        name: 'Editar documento de afiliado',
                        description:
                          'Permiso para editar el documento de un afiliado',
                        permission_required:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT,
                        endpoints_required: [
                          {
                            module: 'application',
                            controller: 'document-subject',
                            action: 'update',
                          },
                        ],
                        visible: true,
                        father:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                        children: [],
                      },
                      {
                        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT,
                        name: 'Eliminar documento de afiliado',
                        description:
                          'Permiso para eliminar el documento de un afiliado',
                        permission_required:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT,
                        endpoints_required: [
                          {
                            module: 'application',
                            controller: 'document-subject',
                            action: 'delete',
                          },
                        ],
                        visible: true,
                        father:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_AFFILIATES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST,
            name: 'Ver lista de vehículos',
            description: 'Permiso para visualizar la lista de vehículos',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'vehicle',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'vehicle',
                action: 'count',
              },
              {
                module: 'application',
                controller: 'document-requested',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST,
                name: 'Ver lista de documentos de vehículo',
                description:
                  'Permiso para visualizar la lista de documentos de un vehículo',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'vehicle',
                    action: 'findOne',
                  },
                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'find',
                  },
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'find',
                  },
                ],
                visible: true,
                father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST,
                children: [
                  {
                    id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT,
                    name: 'Ver documento de vehículo',
                    description:
                      'Permiso para visualizar el documento de un vehículo',
                    permission_required:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT,
                    endpoints_required: [],
                    visible: true,
                    father:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST,
                    children: [],
                  },
                  {
                    id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                    name: 'Ver documento cargado de vehículo',
                    description:
                      'Permiso para visualizar el documento cargado de un vehículo',
                    permission_required:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                    endpoints_required: [
                      {
                        module: 'application',
                        controller: 'document-subject',
                        action: 'findOne',
                      },
                      {
                        module: 'application',
                        controller: 'document-subject',
                        action: 'find',
                      },
                    ],
                    visible: true,
                    father:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST,
                    children: [
                      {
                        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT,
                        name: 'Agregar documento de vehículo',
                        description:
                          'Permiso para agregar el documento de un vehículo',
                        permission_required:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT,
                        endpoints_required: [
                          {
                            module: 'application',
                            controller: 'document-subject',
                            action: 'create',
                          },
                        ],
                        visible: true,
                        father:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                        children: [],
                      },
                      {
                        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT,
                        name: 'Editar documento de vehículo',
                        description:
                          'Permiso para editar el documento de un vehículo',
                        permission_required:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT,
                        endpoints_required: [
                          {
                            module: 'application',
                            controller: 'document-subject',
                            action: 'update',
                          },
                        ],
                        visible: true,
                        father:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                        children: [],
                      },
                      {
                        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT,
                        name: 'Eliminar documento de vehículo',
                        description:
                          'Permiso para eliminar el documento de un vehículo',
                        permission_required:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT,
                        endpoints_required: [
                          {
                            module: 'application',
                            controller: 'document-subject',
                            action: 'delete',
                          },
                        ],
                        visible: true,
                        father:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VEHICLES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST,
            name: 'Ver lista de conductores',
            description: 'Permiso para visualizar la lista de conductores',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'driver',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-requested',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST,
                name: 'Ver lista de documentos de conductor',
                description:
                  'Permiso para visualizar la lista de documentos de un conductor',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'driver',
                    action: 'findOne',
                  },
                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'find',
                  },
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'find',
                  },
                ],
                visible: true,
                father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST,
                children: [
                  {
                    id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT,
                    name: 'Ver documento de conductor',
                    description:
                      'Permiso para visualizar el documento de un conductor',
                    permission_required:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT,
                    endpoints_required: [],
                    visible: true,
                    father:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST,
                    children: [],
                  },
                  {
                    id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                    name: 'Ver documento cargado de conductor',
                    description:
                      'Permiso para visualizar el documento cargado de un conductor',
                    permission_required:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                    endpoints_required: [
                      {
                        module: 'application',
                        controller: 'document-subject',
                        action: 'findOne',
                      },
                      {
                        module: 'application',
                        controller: 'document-subject',
                        action: 'find',
                      },
                    ],
                    visible: true,
                    father:
                      PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST,
                    children: [
                      {
                        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT,
                        name: 'Agregar documento de conductor',
                        description:
                          'Permiso para agregar el documento de un conductor',
                        permission_required:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT,
                        endpoints_required: [
                          {
                            module: 'application',
                            controller: 'document-subject',
                            action: 'create',
                          },
                        ],
                        visible: true,
                        father:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                        children: [],
                      },
                      {
                        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT,
                        name: 'Editar documento de conductor',
                        description:
                          'Permiso para editar el documento de un conductor',
                        permission_required:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT,
                        endpoints_required: [
                          {
                            module: 'application',
                            controller: 'document-subject',
                            action: 'update',
                          },
                        ],
                        visible: true,
                        father:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                        children: [],
                      },
                      {
                        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT,
                        name: 'Eliminar documento de conductor',
                        description:
                          'Permiso para eliminar el documento de un conductor',
                        permission_required:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT,
                        endpoints_required: [
                          {
                            module: 'application',
                            controller: 'document-subject',
                            action: 'delete',
                          },
                        ],
                        visible: true,
                        father:
                          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_DRIVERS_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VERIFY_DOCUMENTS_AUTOMATICALLY,
            name: 'Verificar documentos automáticamente',
            description:
              'Permiso para verificar los documentos automáticamente',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_VERIFY_DOCUMENTS_AUTOMATICALLY,
            endpoints_required: [],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS,
            children: [],
          },
        ],
      },
      {
        // Gestión documental / Asignación de documentos
        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED,
        name: 'Asignación de documentos',
        description:
          'Permiso de acceso para la administración de documentos asignados',
        permission_required:
          PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.DOCUMENT_MANAGEMENT,
        children: [
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_AFFILIATE_LIST,
            name: 'Ver lista de documentos asignados a afiliado',
            description:
              'Permiso para visualizar la lista de documentos asignados a afiliado',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_AFFILIATE_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-requested',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-def',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_AFFILIATE_LIST_ASSIGN,
                name: 'Asignar documento a afiliado',
                description: 'Permiso para asignar un documento a afiliado',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_AFFILIATE_LIST_ASSIGN,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_AFFILIATE_LIST,
                children: [],
              },
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_AFFILIATE_LIST_UNASSIGN,
                name: 'Desasignar documento a afiliado',
                description: 'Permiso para desasignar un documento a afiliado',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_AFFILIATE_LIST_UNASSIGN,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'find',
                  },

                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_AFFILIATE_LIST,
                children: [],
              },
            ],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_VEHICLE_LIST,
            name: 'Ver lista de documentos asignados a vehículo',
            description:
              'Permiso para visualizar la lista de documentos asignados a vehículo',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_VEHICLE_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-requested',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-def',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_VEHICLE_LIST_ASSIGN,
                name: 'Asignar documento a vehículo',
                description: 'Permiso para asignar un documento a vehículo',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_VEHICLE_LIST_ASSIGN,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_VEHICLE_LIST,
                children: [],
              },
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_VEHICLE_LIST_UNASSIGN,
                name: 'Desasignar documento a vehículo',
                description: 'Permiso para desasignar un documento a vehículo',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_VEHICLE_LIST_UNASSIGN,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'find',
                  },

                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_VEHICLE_LIST,
                children: [],
              },
            ],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_DRIVER_LIST,
            name: 'Ver lista de documentos asignados a conductor',
            description:
              'Permiso para visualizar la lista de documentos asignados a conductor',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_DRIVER_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-requested',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-def',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_DRIVER_LIST_ASSIGN,
                name: 'Asignar documento a conductor',
                description: 'Permiso para asignar un documento a conductor',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_DRIVER_LIST_ASSIGN,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_DRIVER_LIST,
                children: [],
              },
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_DRIVER_LIST_UNASSIGN,
                name: 'Desasignar documento a conductor',
                description: 'Permiso para desasignar un documento a conductor',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_DRIVER_LIST_UNASSIGN,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'find',
                  },

                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REQUESTED_DOCUMENTS_DRIVER_LIST,
                children: [],
              },
            ],
          },
        ],
      },
      {
        // Gestión documental / Tipo de documentos
        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES,
        name: 'Tipos de documentos',
        description:
          'Permiso de acceso para la administración de tipos de documentos',
        permission_required: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.DOCUMENT_MANAGEMENT,
        children: [
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_CREATE,
            name: 'Registro de tipo de documento',
            description: 'Permiso  para el registro de tipo de documento',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-def',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES,
            children: [],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_VIEW,
            name: 'Ver tipo de documento',
            description: 'Permiso para visualizar un tipo de documento',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES,
            children: [],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_EDIT,
            name: 'Editar tipo de documento',
            description: 'Permiso para editar un tipo de documento',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-def',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'document-def',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES,
            children: [],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_LIST,
            name: 'Lista de tipos de documentos',
            description:
              'Permiso para visualizar la lista de tipos de documentos',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-def',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-def',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES,
            children: [],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_DELETE,
            name: 'Borrar tipo de documento',
            description: 'Permiso para borrar un tipo de documento',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-subject',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-def',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENT_TYPES,
            children: [],
          },
        ],
      },
      {
        // Gestión documental / Revisión de documentos
        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW,
        name: 'Revisión de documentos',
        description: 'Permiso de acceso para la revisión de documentos',
        permission_required: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW,
        endpoints_required: [],
        visible: true,
        father: null,
        children: [
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_AFFILIATES_LIST,
            name: 'Ver lista de afiliados',
            description: 'Permiso para visualizar la lista de afiliados',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_AFFILIATES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-subject',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-subject',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_AFFILIATES_LIST_VIEW_DOCUMENT,
                name: 'Ver documento de afiliado',
                description: 'Permiso para ver documento de afiliado',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_AFFILIATES_LIST_VIEW_DOCUMENT,
                endpoints_required: [],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_AFFILIATES_LIST,
                children: [],
              },
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_AFFILIATES_LIST_VERIFY_DOCUMENTS,
                name: 'Verificar / Rechazar documentos de afiliados',
                description:
                  'Permiso para verificar / rechazar documentos de afiliados',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_AFFILIATES_LIST_VERIFY_DOCUMENTS,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_AFFILIATES_LIST,
                children: [],
              },
            ],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_VEHICLES_LIST,
            name: 'Ver lista de vehículos',
            description: 'Permiso para visualizar la lista de vehículos',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_VEHICLES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-subject',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-subject',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_VEHICLES_LIST_VIEW_DOCUMENT,
                name: 'Ver documento de vehículo',
                description: 'Permiso para ver documento de vehículo',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_VEHICLES_LIST_VIEW_DOCUMENT,
                endpoints_required: [],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_VEHICLES_LIST,
                children: [],
              },
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_VEHICLES_LIST_VERIFY_DOCUMENTS,
                name: 'Verificar / Rechazar documentos de vehiculos',
                description:
                  'Permiso para verificar / rechazar documentos de vehículos',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_VEHICLES_LIST_VERIFY_DOCUMENTS,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_VEHICLES_LIST,
                children: [],
              },
            ],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_DRIVERS_LIST,
            name: 'Ver lista de conductores',
            description: 'Permiso para visualizar la lista de conductores',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_DRIVERS_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-subject',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-subject',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_DRIVERS_LIST_VIEW_DOCUMENT,
                name: 'Ver documento de conductor',
                description: 'Permiso para ver documento de conductor',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_DRIVERS_LIST_VIEW_DOCUMENT,
                endpoints_required: [],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_DRIVERS_LIST,
                children: [],
              },
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_DRIVERS_LIST_VERIFY_DOCUMENTS,
                name: 'Verificar / Rechazar documentos de conductores',
                description:
                  'Permiso para verificar / rechazar documentos de conductores',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_DRIVERS_LIST_VERIFY_DOCUMENTS,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_REVIEW_DRIVERS_LIST,
                children: [],
              },
            ],
          },
        ],
      },
      {
        // Gestión documental / Documentos por vencer
        id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT,
        name: 'Documentos por vencer',
        description:
          'Permiso de acceso para visualizar los documentos por vencer',
        permission_required: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT,
        endpoints_required: [],
        visible: true,
        father: null,
        children: [
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_AFFILIATES_LIST,
            name: 'Ver lista de documentos de afiliados',
            description:
              'Permiso para visualizar la lista de documentos de afiliados',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_AFFILIATES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-subject',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'car-owner',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-requested',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_AFFILIATES_LIST_VIEW_DOCUMENT,
                name: 'Ver documento de afiliado',
                description: 'Permiso para ver documento de afiliado',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_AFFILIATES_LIST_VIEW_DOCUMENT,
                endpoints_required: [],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_AFFILIATES_LIST,
                children: [],
              },
            ],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_VEHICLES_LIST,
            name: 'Ver lista de documentos de vehículos',
            description:
              'Permiso para visualizar la lista de documentos de vehículos',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_VEHICLES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-subject',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'vehicle',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-requested',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_VEHICLES_LIST_VIEW_DOCUMENT,
                name: 'Ver documento de vehículo',
                description: 'Permiso para ver documento de vehículo',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_VEHICLES_LIST_VIEW_DOCUMENT,
                endpoints_required: [],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_VEHICLES_LIST,
                children: [],
              },
            ],
          },
          {
            id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_DRIVERS_LIST,
            name: 'Ver lista de documentos de conductores',
            description:
              'Permiso para visualizar la lista de documentos de conductores',
            permission_required:
              PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_DRIVERS_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'document-subject',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'driver',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'document-requested',
                action: 'find',
              },
            ],
            visible: true,
            father: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT,
            children: [
              {
                id: PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_DRIVERS_LIST_VIEW_DOCUMENT,
                name: 'Ver documento de conductor',
                description: 'Permiso para ver documento de conductor',
                permission_required:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_DRIVERS_LIST_VIEW_DOCUMENT,
                endpoints_required: [],
                visible: true,
                father:
                  PERMISSIONS.DOCUMENT_MANAGEMENT_DOCUMENTS_ALERT_DRIVERS_LIST,
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    // Gestión de empresas
    id: PERMISSIONS.COMPANIES_MANAGEMENT,
    name: 'Gestión de Empresas',
    description: 'Permiso de acceso para la administración de empresas',
    permission_required: PERMISSIONS.COMPANIES_MANAGEMENT,
    endpoints_required: [],
    visible: true,
    father: null,
    children: [
      {
        // Gestión de empresas / Mis empresas
        id: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS,
        name: 'Mis empresas',
        description: 'Permiso de acceso para la administración de mis empresas',
        permission_required: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.COMPANIES_MANAGEMENT,
        children: [
          {
            id: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS_CREATE,
            name: 'Registro de empresa',
            description: 'Permiso para el registro de empresa',
            permission_required:
              PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS_CREATE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'company',
                action: 'create',
              },
            ],
            visible: true,
            father: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS,
            children: [],
          },
          {
            id: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS_VIEW,
            name: 'Ver empresa',
            description: 'Permiso para visualizar una empresa',
            permission_required: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS,
            children: [],
          },
          {
            id: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS_EDIT,
            name: 'Editar empresa',
            description: 'Permiso para editar una empresa',
            permission_required: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS_EDIT,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'company',
                action: 'update',
              },
            ],
            visible: true,
            father: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS,
            children: [],
          },
          {
            id: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS_LIST,
            name: 'Lista de empresas',
            description: 'Permiso para visualizar la lista de empresas',
            permission_required: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'company',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS,
            children: [],
          },
          {
            id: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS_DELETE,
            name: 'Borrar empresa',
            description: 'Permiso para borrar una empresa',
            permission_required:
              PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS_DELETE,
            endpoints_required: [
              {
                module: 'application',
                controller: 'company',
                action: 'findOne',
              },
              {
                module: 'application',
                controller: 'company',
                action: 'delete',
              },
            ],
            visible: true,
            father: PERMISSIONS.COMPANIES_MANAGEMENT_BUSINESS,
            children: [],
          },
        ],
      },
      {
        // Gestión de empresas / Gestión documental
        id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS,
        name: 'Getión documental de empresas',
        description:
          'Permiso de acceso para la administración de documentos de empresas',
        permission_required:
          PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.COMPANIES_MANAGEMENT,
        children: [
          {
            // Gestión de empresas / Gestión documental / Documentos
            id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS,
            name: 'Documentos de empresas',
            description:
              'Permiso de acceso para la administración de documentos de empresas',
            permission_required:
              PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS,
            endpoints_required: [],
            visible: true,
            father: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS,
            children: [
              {
                id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST,
                name: 'Ver lista de empresas',
                description: 'Permiso para visualizar la lista de empresas',
                permission_required:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'company',
                    action: 'find',
                  },
                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'find',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS,
                children: [
                  {
                    id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST,
                    name: 'Ver lista de documentos de empresas',
                    description:
                      'Permiso para visualizar la lista de documentos de una empresa',
                    permission_required:
                      PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST,
                    endpoints_required: [
                      {
                        module: 'application',
                        controller: 'company',
                        action: 'findOne',
                      },
                      {
                        module: 'application',
                        controller: 'document-requested',
                        action: 'find',
                      },
                      {
                        module: 'application',
                        controller: 'document-subject',
                        action: 'find',
                      },
                    ],
                    visible: true,
                    father:
                      PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST,
                    children: [
                      {
                        id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT,
                        name: 'Ver documento de empresa',
                        description:
                          'Permiso para visualizar el documento de una empresa',
                        permission_required:
                          PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_VIEW_DOCUMENT,
                        endpoints_required: [],
                        visible: true,
                        father:
                          PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST,
                        children: [],
                      },
                      {
                        id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                        name: 'Ver documento cargado de empresa',
                        description:
                          'Permiso para visualizar documento cargardo de una empresa',
                        permission_required:
                          PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                        endpoints_required: [
                          {
                            module: 'application',
                            controller: 'document-subject',
                            action: 'findOne',
                          },
                          {
                            module: 'application',
                            controller: 'document-subject',
                            action: 'find',
                          },
                        ],
                        visible: true,
                        father:
                          PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST,
                        children: [
                          {
                            id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT,
                            name: 'Agregar documento de empresa',
                            description:
                              'Permiso para agregar el documento de una empresa',
                            permission_required:
                              PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_ADD_DOCUMENT,
                            endpoints_required: [
                              {
                                module: 'application',
                                controller: 'document-subject',
                                action: 'create',
                              },
                            ],
                            visible: true,
                            father:
                              PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                            children: [],
                          },
                          {
                            id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT,
                            name: 'Editar documento de empresa',
                            description:
                              'Permiso para editar el documento de una empresa',
                            permission_required:
                              PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_EDIT_DOCUMENT,
                            endpoints_required: [
                              {
                                module: 'application',
                                controller: 'document-subject',
                                action: 'update',
                              },
                            ],
                            visible: true,
                            father:
                              PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                            children: [],
                          },
                          {
                            id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT,
                            name: 'Eliminar documento de empresa',
                            description:
                              'Permiso para eliminar el documento de una empresa',
                            permission_required:
                              PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_DELETE_DOCUMENT,
                            endpoints_required: [
                              {
                                module: 'application',
                                controller: 'document-subject',
                                action: 'delete',
                              },
                            ],
                            visible: true,
                            father:
                              PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_COMPANIES_LIST_VIEW_DOCUMENTS_LIST_LOAD_DOCUMENT,
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            // Gestión de empresas / Gestión documental / Asignación de documentos
            id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED,
            name: 'Asignación de documentos de empresas',
            description:
              'Permiso de acceso para la administración de documentos asignados de empresas',
            permission_required:
              PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED,
            endpoints_required: [],
            visible: true,
            father: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS,
            children: [
              {
                id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED_DOCUMENTS_COMPANIES_LIST_ASSIGN,
                name: 'Asignar documento a empresa',
                description: 'Permiso para asignar un documento a una empresa',
                permission_required:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED_DOCUMENTS_COMPANIES_LIST_ASSIGN,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-def',
                    action: 'find',
                  },
                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'find',
                  },
                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED,
                children: [],
              },
              {
                id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED_DOCUMENTS_COMPANIES_LIST_UNASSIGN,
                name: 'Desasignar documento a empresa',
                description:
                  'Permiso para desasignar un documento a una empresa',
                permission_required:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED_DOCUMENTS_COMPANIES_LIST_UNASSIGN,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'find',
                  },
                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_REQUESTED,
                children: [],
              },
            ],
          },
          {
            // Gestión de empresas / Gestión documental / Tipo de documentos
            id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES,
            name: 'Tipos de documentos de empresas',
            description:
              'Permiso de acceso para la administración de tipos de documentos de empresas',
            permission_required:
              PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES,
            endpoints_required: [],
            visible: true,
            father: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS,
            children: [
              {
                id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_CREATE,
                name: 'Registro de tipo de documento de empresa',
                description:
                  'Permiso  para el registro de tipo de documento de empresa',
                permission_required:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_CREATE,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-def',
                    action: 'create',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES,
                children: [],
              },
              {
                id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_VIEW,
                name: 'Ver tipo de documento de empresa',
                description:
                  'Permiso para visualizar un tipo de documento de empresa',
                permission_required:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_VIEW,
                endpoints_required: [],
                visible: false,
                father:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES,
                children: [],
              },
              {
                id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_EDIT,
                name: 'Editar tipo de documento de empresa',
                description:
                  'Permiso para editar un tipo de documento de empresa',
                permission_required:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_EDIT,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-def',
                    action: 'findOne',
                  },
                  {
                    module: 'application',
                    controller: 'document-def',
                    action: 'update',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES,
                children: [],
              },
              {
                id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_LIST,
                name: 'Lista de tipos de documentos de empresa',
                description:
                  'Permiso para visualizar la lista de tipos de documentos de empresa',
                permission_required:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_LIST,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-def',
                    action: 'find',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES,
                children: [],
              },
              {
                id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_DELETE,
                name: 'Borrar tipo de documento de empresa',
                description:
                  'Permiso para borrar un tipo de documento de empresa',
                permission_required:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES_DELETE,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'find',
                  },
                  {
                    module: 'application',
                    controller: 'document-def',
                    action: 'delete',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENT_TYPES,
                children: [],
              },
            ],
          },
          {
            // Gestión de empresas / Gestión documental / Documentos por vencer
            id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_ALERT,
            name: 'Documentos por vencer de empresas',
            description:
              'Permiso de acceso para visualizar los documentos por vencer de empresas',
            permission_required:
              PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_ALERT,
            endpoints_required: [],
            visible: true,
            father: null,
            children: [
              {
                id: PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_ALERT_COMPANY_LIST_VIEW_DOCUMENT,
                name: 'Ver documento de empresa',
                description: 'Permiso para ver documento de empresa',
                permission_required:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_ALERT_COMPANY_LIST_VIEW_DOCUMENT,
                endpoints_required: [
                  {
                    module: 'application',
                    controller: 'document-subject',
                    action: 'find',
                  },
                  {
                    module: 'application',
                    controller: 'company',
                    action: 'find',
                  },
                  {
                    module: 'application',
                    controller: 'document-requested',
                    action: 'find',
                  },
                ],
                visible: true,
                father:
                  PERMISSIONS.COMPANIES_MANAGEMENT_COMPANIES_DOCUMENTS_DOCUMENTS_ALERT,
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    // Seguridad
    id: PERMISSIONS.SECURITY,
    name: 'Seguridad',
    description: 'Módulo de seguridad',
    permission_required: PERMISSIONS.SECURITY,
    endpoints_required: [],
    visible: true,
    father: null,
    children: [
      // Seguridad / Usuarios
      {
        id: PERMISSIONS.SECURITY_USERS,
        name: 'Usuarios',
        description: 'Permiso de acceso para la administración de usuarios',
        permission_required: PERMISSIONS.SECURITY_USERS,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.SECURITY,
        children: [
          {
            id: PERMISSIONS.SECURITY_USERS_CREATE,
            name: 'Registro de usuario',
            description: 'Permiso para el registro de usuario',
            permission_required: PERMISSIONS.SECURITY_USERS_CREATE,
            endpoints_required: [
              {
                module: 'users-permissions',
                controller: 'user',
                action: 'create',
              },
              {
                module: 'users-permissions',
                controller: 'userspermissions',
                action: 'getRoles',
              },
            ],
            visible: true,
            father: PERMISSIONS.SECURITY_USERS,
            children: [],
          },
          {
            id: PERMISSIONS.SECURITY_USERS_VIEW,
            name: 'Ver usuario',
            description: 'Permiso para visualizar un usuario',
            permission_required: PERMISSIONS.SECURITY_USERS_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.SECURITY_USERS,
            children: [],
          },
          {
            id: PERMISSIONS.SECURITY_USERS_EDIT,
            name: 'Editar usuario',
            description: 'Permiso para editar un usuario',
            permission_required: PERMISSIONS.SECURITY_USERS_EDIT,
            endpoints_required: [
              {
                module: 'users-permissions',
                controller: 'user',
                action: 'update',
              },
              {
                module: 'users-permissions',
                controller: 'user',
                action: 'findOne',
              },
              {
                module: 'users-permissions',
                controller: 'userspermissions',
                action: 'getRoles',
              },
            ],
            visible: true,
            father: PERMISSIONS.SECURITY_USERS,
            children: [],
          },
          {
            id: PERMISSIONS.SECURITY_USERS_LIST,
            name: 'Lista de usuarios',
            description: 'Permiso para visualizar la lista de usuarios',
            permission_required: PERMISSIONS.SECURITY_USERS_LIST,
            endpoints_required: [
              {
                module: 'users-permissions',
                controller: 'user',
                action: 'find',
              },
              {
                module: 'users-permissions',
                controller: 'user',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.SECURITY_USERS,
            children: [],
          },
        ],
      },
      // Seguridad / Roles
      {
        id: PERMISSIONS.SECURITY_ROLES,
        name: 'Roles',
        description: 'Permiso de acceso para la administración de roles',
        permission_required: PERMISSIONS.SECURITY_ROLES,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.SECURITY,
        children: [
          {
            id: PERMISSIONS.SECURITY_ROLES_CREATE,
            name: 'Registro de rol',
            description: 'Permiso para el registro de rol',
            permission_required: PERMISSIONS.SECURITY_ROLES_CREATE,
            endpoints_required: [
              {
                module: 'users-permissions',
                controller: 'userspermissions',
                action: 'createRole',
              },
              {
                module: 'users-permissions',
                controller: 'userspermissions',
                action: 'getPermissions',
              },
            ],
            visible: true,
            father: PERMISSIONS.SECURITY_ROLES,
            children: [],
          },
          {
            id: PERMISSIONS.SECURITY_ROLES_VIEW,
            name: 'Ver rol',
            description: 'Permiso para visualizar un rol',
            permission_required: PERMISSIONS.SECURITY_ROLES_VIEW,
            endpoints_required: [],
            visible: false,
            father: PERMISSIONS.SECURITY_ROLES,
            children: [],
          },
          {
            id: PERMISSIONS.SECURITY_ROLES_EDIT,
            name: 'Editar rol',
            description: 'Permiso para editar un rol',
            permission_required: PERMISSIONS.SECURITY_ROLES_EDIT,
            endpoints_required: [
              {
                module: 'users-permissions',
                controller: 'userspermissions',
                action: 'updateRole',
              },
              {
                module: 'users-permissions',
                controller: 'userspermissions',
                action: 'getRole',
              },
              {
                module: 'users-permissions',
                controller: 'userspermissions',
                action: 'getPermissions',
              },
            ],
            visible: true,
            father: PERMISSIONS.SECURITY_ROLES,
            children: [],
          },
          {
            id: PERMISSIONS.SECURITY_ROLES_LIST,
            name: 'Lista de roles',
            description: 'Permiso para visualizar la lista de roles',
            permission_required: PERMISSIONS.SECURITY_ROLES_LIST,
            endpoints_required: [
              {
                module: 'users-permissions',
                controller: 'userspermissions',
                action: 'getRoles',
              },
            ],
            visible: true,
            father: PERMISSIONS.SECURITY_ROLES,
            children: [],
          },
          {
            id: PERMISSIONS.SECURITY_ROLES_DELETE,
            name: 'Borrar un rol',
            description: 'Permiso para borrar un rol',
            permission_required: PERMISSIONS.SECURITY_ROLES_DELETE,
            endpoints_required: [
              {
                module: 'users-permissions',
                controller: 'userspermissions',
                action: 'deleteRole',
              },
            ],
            visible: true,
            father: PERMISSIONS.SECURITY_ROLES,
            children: [],
          },
        ],
      },
      // Seguridad / Mensajes del sistema
      {
        id: PERMISSIONS.SECURITY_MESSAGES,
        name: 'Mensajes del sistema',
        description: 'Permiso de acceso a los mensajes del sistema',
        permission_required: PERMISSIONS.SECURITY_MESSAGES,
        endpoints_required: [],
        visible: true,
        father: PERMISSIONS.SECURITY,
        children: [
          {
            id: PERMISSIONS.SECURITY_MESSAGES_VIEW,
            name: 'Ver mensajes del sistema',
            description: 'Permiso para ver los mensajes del sistema.',
            permission_required: PERMISSIONS.SECURITY_MESSAGES_VIEW,
            endpoints_required: [
              {
                module: 'application',
                controller: 'message',
                action: 'findOne',
              },
            ],
            visible: false,
            father: PERMISSIONS.SECURITY_ROLES,
            children: [],
          },
          {
            id: PERMISSIONS.SECURITY_MESSAGES_LIST,
            name: 'Lista de mensajes',
            description:
              'Permiso para visualizar la lista de mensajes del sistema. Para recibir notificación de mensajes debe activar este permiso',
            permission_required: PERMISSIONS.SECURITY_MESSAGES_LIST,
            endpoints_required: [
              {
                module: 'application',
                controller: 'message',
                action: 'find',
              },
              {
                module: 'application',
                controller: 'message',
                action: 'count',
              },
            ],
            visible: true,
            father: PERMISSIONS.SECURITY_MESSAGES,
            children: [],
          },
        ],
      },
    ],
  },
  {
    //  El usuario solo tiene acceso a sus propios registros
    id: PERMISSIONS.ACCESS_ONLY_OWN_RECORDS,
    name: 'Solo sus propios registros',
    description: 'El usuario solo tendrá acceso a sus propios registros',
    permission_required: PERMISSIONS.ACCESS_ONLY_OWN_RECORDS,
    endpoints_required: [],
    visible: true,
    father: null,
    children: [],
  },
];

/**
 * Get permission modules in plain array
 *
 * @param {IPermissionModule[]} permissionModule
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getPermissionModulesArray(
  permissionModuleArray: IPermissionModule[],
  permissionModule: IPermissionModule[] = PERMISSION_MODULES,
): void {
  if (Array.isArray(permissionModule)) {
    permissionModule.forEach((pm) => {
      permissionModuleArray.push(pm);
      if (pm.children.length > 0) {
        getPermissionModulesArray(permissionModuleArray, pm.children);
      }
    });
  }
}
