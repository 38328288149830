import { BaseSchema, CompanySchema } from '..';

export class BearingSchema extends BaseSchema {
  vehicle_type: string;
  start_date: string;
  end_date: string;
  value: number;
  company: CompanySchema | string;

  constructor(params: {
    vehicle_type: string;
    start_date: string;
    end_date: string;
    value: number;
    company: CompanySchema | string;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.vehicle_type = params.vehicle_type;
    this.start_date = params.start_date;
    this.end_date = params.end_date;
    this.value = params.value;
    this.company = params.company;
  }
}
