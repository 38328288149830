import { Injectable } from '@angular/core';

import {
  ERROR_USER_EMAIL_EXISTS,
  ERROR_USER_EMAIL_NOT_EXISTS,
  ERROR_USER_PASSWORD_NOT_MATCHING,
  ERROR_USER_INVALID_CODE,
} from '@shared/consts';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  /**
   * random password
   * @returns password
   */
  generatePasswordRand() {
    const characters =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let pass = '';
    for (let i = 0; i < 10; i++) {
      pass += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return pass;
  }
  /**
   * check if the email account exists
   * @param err
   * @returns true o false
   */
  ErrorUserEmailExists(err) {
    return err.error.data[0].messages[0].id === ERROR_USER_EMAIL_EXISTS
      ? true
      : false;
  }

  /**
   * Email does not exist in the data
   * @param err
   * @returns true o false
   */
  ErrorUserEmailNotExists(err) {
    return err.error.data[0].messages[0].id === ERROR_USER_EMAIL_NOT_EXISTS
      ? true
      : false;
  }

  /**
   * Passwords do not match
   * @param err
   * @returns true o false
   */
  ErrorUserPasswordNotMatching(err) {
    return err.error.data[0].messages[0].id === ERROR_USER_PASSWORD_NOT_MATCHING
      ? true
      : false;
  }

  /**
   * Incorrect code provided
   * @param err
   * @returns true o false
   */
  ErrorUserInvalidCode(err) {
    return err.error.data[0].messages[0].id === ERROR_USER_INVALID_CODE
      ? true
      : false;
  }

  /**
   * clear form fields
   * @param textString
   * @returns String with no blanks
   */
  cleanFields(textString: string) {
    return textString.replace(/\s{2,}/g, ' ').trim();
  }
}
