import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import { DepartmentSchema } from '@schemas/core';
import { IToSelect } from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  find(): Observable<DepartmentSchema[]> {
    return this.http.get<DepartmentSchema[]>(
      `${this.API_URL}/departments/?_limit=-1`,
    );
  }

  toSelect(department: DepartmentSchema[]): IToSelect[] {
    return department.map((a) => ({ id: a.id, description: a.name }));
  }
}
