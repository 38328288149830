import {
  BaseSchema,
  ContractorSchema,
  ContractObjectSchema,
  CompanySchema,
  VehicleSchema,
  RouteSchema,
} from '../';

export class ContractSchema extends BaseSchema {
  contract_number: string;
  observations: string;
  start_date: string;
  end_date: string;
  file_contract?: any;
  all_vehicles: boolean;
  all_routes: boolean;
  short_contract_name: string;
  responsible_identification: string;
  responsible_name: string;
  responsible_phone: string;
  responsible_address: string;
  contractor: string | ContractorSchema;
  contract_object: string | ContractObjectSchema;
  company: string | CompanySchema;
  vehicles: string[] | VehicleSchema[];
  routes: string[] | RouteSchema[];

  constructor(params: {
    contract_number: string;
    observations: string;
    start_date: string;
    end_date: string;
    file_contract?: any;
    all_vehicles: boolean;
    all_routes: boolean;
    short_contract_name: string;
    responsible_identification: string;
    responsible_name: string;
    responsible_phone: string;
    responsible_address: string;
    contractor: string | ContractorSchema;
    contract_object: string | ContractObjectSchema;
    company: string | CompanySchema;
    vehicles?: string[] | VehicleSchema[];
    routes?: string[] | RouteSchema[];
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.contract_number = params.contract_number;
    this.observations = params.observations;
    this.start_date = params.start_date;
    this.end_date = params.end_date;
    this.file_contract = params.file_contract;
    this.all_vehicles = params.all_vehicles;
    this.all_routes = params.all_routes;
    this.short_contract_name = params.short_contract_name;
    this.responsible_identification = params.responsible_identification;
    this.responsible_name = params.responsible_name;
    this.responsible_phone = params.responsible_phone;
    this.responsible_address = params.responsible_address;
    this.contractor = params.contractor;
    this.contract_object = params.contract_object;
    this.company = params.company;
    this.vehicles = params.vehicles;
    this.routes = params.routes;
  }
}
