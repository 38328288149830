import { BaseSchema, ContractSchema } from '../';

export class ContractObjectSchema extends BaseSchema {
  description: string;
  contracts: Array<ContractSchema> | Array<string>;

  constructor(params: {
    description: string;
    contracts?: Array<ContractSchema> | Array<string>;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.description = params.description;
    this.contracts = params.contracts;
  }
}
