import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';

import { BaseService } from '../base/base.service';
import { CompanySchema, DocumentSubjectSchema } from '@schemas/core';
import { ICompanyDatatable, IToSelect } from '@shared/interfaces';
import { map } from 'rxjs/operators';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService {
  constructor(protected http: HttpClient, private datePipe: DatePipe) {
    super(http);
  }

  /**
   * Find companies
   *
   * To load relations send relation name on populate array
   * Example: To load extract_contracts send string "extract_contracts"
   * on array populate
   *
   * @param {(string[] | null)} [populate=null]
   * @return {*}  {Observable<CompanySchema[]>}
   * @memberof CompanyService
   */

  find(
    populate: string[] | null = null,
    stateDataTable: IStateDataTable | null = null,
  ): Observable<CompanySchema[]> {
    let params = new HttpParams().set('_sort', 'name:ASC').set('_limit', -1);

    params = populate
      ? params.set('populate', JSON.stringify(populate))
      : params;

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<CompanySchema[]>(`${this.API_URL}/companies/`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams();

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/companies/count`, {
      params,
    });
  }

  findOne(id: string): Observable<CompanySchema> {
    return this.http.get<CompanySchema>(`${this.API_URL}/companies/${id}`);
  }

  /**
   * Find duplicate company by nit.
   * Can exclude registers for find duplicate
   * Return array boolean on this order nit
   *
   */
  findDuplicate(
    data: {
      nit: string;
    },
    exclude: string[],
  ): Observable<Array<boolean>> {
    return combineLatest([
      this.findDuplicateField('nit', data.nit, exclude),
    ]).pipe(map(([nit]) => [nit]));
  }

  /**
   * Return true if the field is duplicate
   * Can exclude registers for find duplicate
   *
   * En la actualidad Strapi no tiene una forma de buscar
   * un campo igual que no sea case sensitive, por eso se usa contains.
   *
   */
  private findDuplicateField(
    field: string,
    data: string,
    exclude: string[],
  ): Observable<boolean> {
    return this.http
      .get<CompanySchema[]>(
        `${this.API_URL}/companies?${field}_contains=${data}`,
      )
      .pipe(
        map((r) =>
          r.find(
            (x) =>
              !exclude.includes(x.id.toString()) &&
              x[field].toUpperCase() === data.toUpperCase(),
          )
            ? true
            : false,
        ),
      );
  }

  create(company: CompanySchema): Observable<CompanySchema> {
    return this.http.post<CompanySchema>(`${this.API_URL}/companies`, company);
  }

  createWithFormData(formData: FormData): Observable<CompanySchema> {
    return this.http.post<CompanySchema>(`${this.API_URL}/companies`, formData);
  }

  update(company: CompanySchema): Observable<CompanySchema> {
    return this.http.put<CompanySchema>(
      `${this.API_URL}/companies/${company?.id}`,
      company,
    );
  }

  updateWithFormData(
    id: string,
    formData: FormData,
  ): Observable<CompanySchema> {
    return this.http.put<CompanySchema>(
      `${this.API_URL}/companies/${id}`,
      formData,
    );
  }

  updateCorrelativeWithIncrement(
    company: CompanySchema,
  ): Observable<CompanySchema> {
    return this.http.put<CompanySchema>(
      `${this.API_URL}/companies/${company?.id}`,
      {
        correlative_extract_contract:
          parseInt(company.correlative_extract_contract.toString(), 10) + 1,
      },
    );
  }

  delete(id: string): Observable<CompanySchema> {
    return this.http.delete<CompanySchema>(`${this.API_URL}/companies/${id}`);
  }

  toDataTable(companies: Array<CompanySchema>): Array<ICompanyDatatable> {
    return companies.map((c) => ({
      nit: c.nit,
      name: c.name,
      qualification_year: this.datePipe.transform(
        c.qualification_year,
        'dd-MM-yyyy',
      ),
      resolution_number: c.resolution_number,
      territorial_address_code: c.territorial_address_code,
      id: c.id,
      documentsSubject: c.document_subjects as DocumentSubjectSchema[],
    }));
  }

  getTableHead(): Observable<string[]> {
    return of([
      'NIT',
      'Nombre',
      'Año de Habilitación',
      'Número de Resolución',
      'Código de Dirección Territorial',
    ]);
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'nit_contains',
        field: 'NIT',
        default: false,
      },
      {
        APIField: 'name_contains',
        field: 'Nombre',
        default: true,
      },
      {
        APIField: 'resolution_number_contains',
        field: 'Número de resolución',
        default: false,
      },
      {
        APIField: 'territorial_address_code_contains',
        field: 'Código de dirección territorial',
        default: false,
      },
    ]);
  }

  toSelect(companies: CompanySchema[]): IToSelect[] {
    return companies.map((a) => ({ id: a.id, description: a.name }));
  }
}
