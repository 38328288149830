import { BaseSchema } from '../';
import { DocumentDefTypeEnum } from '@shared/enums';

export class DocumentDefSchema extends BaseSchema {
  name: string;
  // Período de vigencia del documento en meses
  period: number;
  // Tiempo en días para alertar vencimiento del documento
  days_to_alert_expiration: number;
  validate_in_contract_extract: boolean;
  type: DocumentDefTypeEnum;
  order?: number;

  constructor(params: {
    name: string;
    period: number;
    days_to_alert_expiration: number;
    validate_in_contract_extract: boolean;
    type: DocumentDefTypeEnum;
    published_at: Date;
    order?: number;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.name = params.name;
    this.period = params.period;
    this.days_to_alert_expiration = params.days_to_alert_expiration;
    this.validate_in_contract_extract = params.validate_in_contract_extract;
    this.type = params.type;
    this.order = params.order;
  }
}
