import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RouteInfo } from './vertical-sidebar.metadata';
import { VerticalMenuItemsService } from './vertical-menu-items.service';

@Injectable({
  providedIn: 'root',
})
export class VerticalSidebarService {
  public screenWidth: any;
  public collapseSidebar = false;
  public fullScreen = false;

  MENUITEMS: RouteInfo[] = this.verticalMenuItemsService.ROUTES;

  items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);

  expandMenuItem: EventEmitter<string> = new EventEmitter<string>();

  constructor(private verticalMenuItemsService: VerticalMenuItemsService) {}
}
