import {
  BaseSchema,
  AffiliateSchema,
  TransportAgreementSchema,
  DriverSchema,
  ContractSchema,
  DocumentSubjectSchema,
} from '../';

export class VehicleSchema extends BaseSchema {
  contract_number: string;
  plate: string;
  transit_license_number: string;
  date_vehicle_registration: string;
  brand: string;
  type: string;
  passenger_qty: number;
  year: number;
  color: string;
  chassis_serial: string;
  motor_number: string;
  cylinders_number: number;
  city_registration: string;
  fuel_type: string;
  front_photo?: any;
  side_foto?: any;
  active: boolean;
  affiliate: AffiliateSchema | string;
  type_transport_agreement: string;
  transport_agreement: string | TransportAgreementSchema;
  vehicle_number: string;
  drivers: string[] | DriverSchema[];
  contracts: string[] | ContractSchema[];
  document_subjects?: Array<DocumentSubjectSchema> | Array<string>;

  constructor(params: {
    contract_number: string;
    plate: string;
    transit_license_number: string;
    date_vehicle_registration: string;
    brand: string;
    type: string;
    passenger_qty: number;
    year: number;
    color: string;
    chassis_serial: string;
    motor_number: string;
    cylinders_number: number;
    city_registration: string;
    fuel_type: string;
    front_photo?: any;
    side_foto?: any;
    active: boolean;
    affiliate: AffiliateSchema | string;
    type_transport_agreement: string;
    transport_agreement: string | TransportAgreementSchema;
    vehicle_number: string;
    drivers?: string[] | DriverSchema[];
    contracts?: string[] | ContractSchema[];
    document_subjects?: Array<DocumentSubjectSchema> | Array<string>;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.contract_number = params.contract_number;
    this.plate = params.plate;
    this.transit_license_number = params.transit_license_number;
    this.date_vehicle_registration = params.date_vehicle_registration;
    this.brand = params.brand;
    this.type = params.type;
    this.passenger_qty = params.passenger_qty;
    this.year = params.year;
    this.color = params.color;
    this.chassis_serial = params.chassis_serial;
    this.motor_number = params.motor_number;
    this.cylinders_number = params.cylinders_number;
    this.city_registration = params.city_registration;
    this.fuel_type = params.fuel_type;
    this.front_photo = params.front_photo;
    this.side_foto = params.side_foto;
    this.active = params.active;
    this.affiliate = params.affiliate;
    this.type_transport_agreement = params.type_transport_agreement;
    this.transport_agreement = params.transport_agreement;
    this.vehicle_number = params.vehicle_number;
    this.drivers = params.drivers;
    this.contracts = params.contracts;
    this.document_subjects = params.document_subjects;
  }
}
