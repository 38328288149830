import { BaseSchema } from '..';

export class DepartmentSchema extends BaseSchema {
    code:	string
    name:	string
    municipalities: Array<string>;
    
  constructor(params: {
    code: string;
    name: string;
    municipalities: Array<string>;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.name = params.name;
    this.code = params.code;
    this.municipalities = params.municipalities;
  }
}
