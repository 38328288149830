import { Component, OnInit, AfterViewInit } from '@angular/core';

import { ExtractContractService } from 'src/app/services/extract_contract/extract-contract.service';
import { ActivatedRoute } from '@angular/router';
import { ExtractContractSchema } from '@schemas/core';
import { DateTimeService } from '@services/core';

@Component({
  selector: 'app-extract-contract-pdf',
  templateUrl: './extract-contract-pdf.component.html',
  styleUrls: ['./extract-contract-pdf.component.css'],
})
export class ExtractContractPdfComponent implements OnInit, AfterViewInit {
  documentExist: boolean | null = null;

  /**
   * Code of extract contract to find
   *
   * @memberof ExtractContractPdfComponent
   */
  code = '';

  constructor(
    private extractContractService: ExtractContractService,
    private route: ActivatedRoute,
    private dateTimeService: DateTimeService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.code = this.route.snapshot.params.code;

    this.extractContractService.findOneByCode(this.code).subscribe({
      next: (e) => {
        if (e) {
          this.createDownloadPdfLink(e);
          this.documentExist = true;
          return;
        }
        this.documentExist = false;
      },
      error: () => {
        this.documentExist = false;
      },
    });
  }

  private createDownloadPdfLink(extractContract: ExtractContractSchema): void {
    this.extractContractService.createPdf(extractContract).open({}, window);
  }
}
