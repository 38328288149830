import { BaseSchema } from '../';

export class PositionSchema extends BaseSchema {
  name: string;
  code: string;
  employees: Array<string>;

  constructor(params: {
    name: string;
    code: string;
    employees?: Array<string>;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.name = params.name;
    this.code = params.code;
    this.employees = params.employees;
  }
}
