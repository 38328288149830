import {
  BaseSchema,
  AffiliateSchema,
  VehicleSchema,
  DriverSchema,
  CompanySchema,
  DocumentDefSchema,
} from '../';
import { DocumentSubjectStatusEnum } from '@shared/enums';

export class DocumentSubjectSchema extends BaseSchema {
  document_number: string;
  start_validity: string;
  end_validity: string;
  status: DocumentSubjectStatusEnum;
  file?: any;
  affiliate?: AffiliateSchema | string;
  vehicle?: VehicleSchema | string;
  driver?: DriverSchema | string;
  company?: CompanySchema | string;
  document_def: DocumentDefSchema | string;

  constructor(params: {
    document_number: string;
    start_validity: string;
    end_validity: string;
    status: DocumentSubjectStatusEnum;
    file?: any;
    affiliate?: AffiliateSchema | string;
    vehicle?: VehicleSchema | string;
    driver?: DriverSchema | string;
    company?: CompanySchema | string;
    document_def: DocumentDefSchema | string;
    published_at: Date;
    id?: string;
    created_by?: string;
    updated_by?: string;
  }) {
    super(params.published_at, params.id, params.created_by, params.updated_by);
    this.document_number = params.document_number;
    this.start_validity = params.start_validity;
    this.end_validity = params.end_validity;
    this.status = params.status;
    this.file = params.file;
    this.affiliate = params.affiliate;
    this.vehicle = params.vehicle;
    this.driver = params.driver;
    this.company = params.company;
    this.document_def = params.document_def;
  }
}
