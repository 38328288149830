<div
  class="menu-card"
  (click)="addItemMenu(menu.title)"
  style="cursor: pointer"
>
  <img
    [src]="menu?.icon"
    [alt]="menu?.title"
    width="60"
    height="60"
    style="display: block; margin: 2px auto"
  />
  <h4 class="card-header text-white text-center">{{ menu?.title }}</h4>
</div>
