import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RoleSchema, UserNewSchema, UserSchema } from '@schemas/core';
import {
  RoleService,
  UserService,
  NotifierService,
  UtilService,
} from '@services/core';
import { IToSelect } from '@shared/interfaces';
import { map, Observable } from 'rxjs';
import { SIZE_FILE_AVATAR_MEGAS } from '@shared/consts';
import { confirmedValidator } from '@shared/form-validators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input() pageEdit = '';
  @Input() loading = false;
  @Output() dataUser = new EventEmitter<UserNewSchema>();
  @Output() dataUserPerfil = new EventEmitter<UserNewSchema>();

  FIELD_FIRST_NAME = 'fieldFirstName';
  FIELD_LAST_NAME = 'fieldLastName';
  FIELD_EMAIL = 'fieldUserEmail';
  FIELD_AVATAR = 'fieldPhotographyUserProfile';
  FIELD_CELL_PHONE_NUMBER = 'fieldUserCellPhone';
  FIELD_POSITION = 'fieldUserPosition';
  FIELD_ROLE = 'fieldRole';
  FIELD_PASSWORD = 'fileUserPassword';
  FIELD_PASSWORD_REPEAT = 'fileUserPasswordRepeat';
  FIELD_ACTIVE = 'fielActive';

  @ViewChild('fileUpload') el: ElementRef;
  form: FormGroup;
  id: string;
  roles$: Observable<IToSelect[]> = new Observable();
  dropDownRequiredField = false;
  avatarFile: File;
  avatarFileName = '';
  imgAvatarBase64 = null;
  imgUrlAvatar = false;
  avatarFileTooLarge = false;
  isReadonly = false;
  firstname: string;
  lastname: string;
  users = false;
  isDropdownDisabled = false;
  showPassword = false;
  showPasswordRepeat = false;
  readonly SIZE_FILE_AVATAR_MEGAS = SIZE_FILE_AVATAR_MEGAS;

  constructor(
    private fb: FormBuilder,
    private roleService: RoleService,
    private route: ActivatedRoute,
    private userService: UserService,
    private notifier: NotifierService,
    public util: UtilService,
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.getRoles();
    this.formBuilder();
    this.loadUser();
  }

  private formBuilder(): void {
    const group = {};

    group[this.FIELD_FIRST_NAME] = [null];
    group[this.FIELD_LAST_NAME] = [null];
    group[this.FIELD_EMAIL] = [null, Validators.required];
    group[this.FIELD_AVATAR] = [null];
    group[this.FIELD_CELL_PHONE_NUMBER] = [null];
    group[this.FIELD_POSITION] = [null];
    group[this.FIELD_ROLE] = [null, Validators.required];
    group[this.FIELD_PASSWORD] = [null];
    group[this.FIELD_PASSWORD_REPEAT] = [null];
    group[this.FIELD_ACTIVE] = [true];

    this.form = this.fb.group(group, {
      validators: confirmedValidator,
    });
  }

  onChangeAvatar(event: Event) {
    const control = event.target as HTMLInputElement;
    this.avatarFile = control.files[0];
    this.avatarFileName = this.avatarFile.name;

    if (control.files && this.avatarFile) {
      var reader = new FileReader();
      reader.readAsDataURL(this.avatarFile);
      reader.onload = (_event) => {
        this.imgAvatarBase64 = reader.result.toString();
      };
    }

    this.avatarFileTooLarge = false;
    this.imgUrlAvatar = true;
  }

  deleteAvatar() {
    this.imgAvatarBase64 = null;
    this.imgUrlAvatar = false;
    this.avatarFileTooLarge = false;
  }

  clickfileUpload() {
    if (!this.users) {
      this.el.nativeElement.click();
    }
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  showHidePasswordRepeat() {
    this.showPasswordRepeat = !this.showPasswordRepeat;
  }

  private loadUser(): void {
    if (this.pageEdit === 'editUsers') {
      this.isReadonly = true;
      this.users = true;
      this.userService.findOne(this.id).subscribe({
        next: (t) => {
          this.loadForm(t);
        },
      });
    } else {
      this.isDropdownDisabled = true;
      this.userService.me().subscribe({
        next: (t) => {
          this.loadForm(t);
        },
      });
    }
  }

  private loadForm(user: UserSchema) {
    const data = {};
    const role = user.role as RoleSchema;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    data[this.FIELD_FIRST_NAME] = user.firstname;
    data[this.FIELD_LAST_NAME] = user.lastname;
    data[this.FIELD_EMAIL] = user.email;
    if (user.avatar_b64 !== null) {
      this.imgAvatarBase64 = user.avatar_b64;
      this.imgUrlAvatar = true;
    }
    data[this.FIELD_CELL_PHONE_NUMBER] = user.cell_phone_number;
    data[this.FIELD_POSITION] = user.position;
    data[this.FIELD_ROLE] = [
      {
        id: role.id,
        description: role.name,
      },
    ];

    data[this.FIELD_ACTIVE] = !user.blocked;
    data[this.FIELD_PASSWORD] = '';
    data[this.FIELD_PASSWORD_REPEAT] = '';

    this.form.patchValue(data, { onlySelf: false, emitEvent: true });
    this.form.updateValueAndValidity();
  }

  private getRoles(): void {
    this.roles$ = this.roleService
      .find()
      .pipe(map((c) => this.roleService.toSelect(c)));
  }

  onDropDownClose(): void {
    this.dropDownRequiredField = true;
  }

  onSubmit(): void {
    this.loading = true;
    const values = this.form.getRawValue();

    if (this.pageEdit === 'editUsers') {
      const user = new UserNewSchema();
      user.role = values[this.FIELD_ROLE][0]?.id;
      user.blocked = !values[this.FIELD_ACTIVE];
      user.id = parseInt(this.id, 10);
      this.dataUser.emit(user);
    } else {
      const userPerfil = new UserNewSchema();
      userPerfil.firstname = values[this.FIELD_FIRST_NAME];
      userPerfil.lastname = values[this.FIELD_LAST_NAME];

      if (this.avatarFile) {
        if (this.avatarFile.size <= 1024 * 1024 * 5) {
          userPerfil.avatar_b64 = this.imgAvatarBase64;
        } else {
          this.loading = false;
          this.avatarFileTooLarge = true;
        }
      } else {
        userPerfil.avatar_b64 = this.imgAvatarBase64;
      }

      userPerfil.cell_phone_number = values[this.FIELD_CELL_PHONE_NUMBER];
      userPerfil.position = values[this.FIELD_POSITION];
      if (values[this.FIELD_PASSWORD] !== '') {
        userPerfil.password = values[this.FIELD_PASSWORD];
      }
      if (this.avatarFileTooLarge === false) {
        this.dataUserPerfil.emit(userPerfil);
      } else {
        this.loading = false;
        this.notifier.saveDataError();
      }
    }
  }
}
