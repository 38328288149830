<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Confirmación</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss(modalButtonsStateEnum.CLOSE)"
  >
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <p>¿Estás seguro que quieres eliminar el registro?</p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="activeModal.close(modalButtonsStateEnum.NO)"
  >
    No
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="activeModal.close(modalButtonsStateEnum.YES)"
  >
    Sí
  </button>
</div>
