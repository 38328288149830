import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NotifierService, UserService, UtilService } from '@services/core';

import { UserNewSchema } from '@schemas/core';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  FIELD_EMAIL = 'fieldEmail';

  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private notifier: NotifierService,

    private userService: UserService,
    private util: UtilService,
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }
  buildForm(): void {
    this.form = this.fb.group({
      [this.FIELD_EMAIL]: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit(): void {
    const values = this.form.getRawValue();
    const user = new UserNewSchema();
    user.email = values[this.FIELD_EMAIL];
    this.userService.forgotPassword(user).subscribe({
      complete: () => {
        this.notifier.sendDataSuccess();
      },
      error: (err) => {
        if (this.util.ErrorUserEmailNotExists(err)) {
          this.notifier.sendDataErrorUserEmailNotExists();
        } else {
          this.notifier.sendDataError();
        }
      },
    });
  }
}
