<div
  class="auth-wrapper d-flex no-block justify-content-center align-items-center background-start"
>
  <div>
    <div class="auth-box">
      <div id="loginform" [ngClass]="{ 'd-none': recoverform }">
        <div class="logo m-b-20">
          <span class="db">
            <img src="assets/images/logo-light-text.svg" alt="logo" />
          </span>
          <!-- <h5 class="text-login m-b-20">Iniciar sesión</h5> -->
        </div>
        <!-- Form -->
        <div class="row">
          <div class="offset-1 col-10">
            <form
              class="form-horizontal m-t-20"
              id="loginform"
              [formGroup]="form"
              (ngSubmit)="onSubmit()"
            >
              <div class="mb-3">
                <!-- <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="ti-user"></i>
                  </span>
                </div> -->
                <input
                  type="email"
                  class="form-control form-control-lg text-box"
                  placeholder="Correo electrónico"
                  aria-label="Email"
                  aria-describedby="basic-addon1"
                  [formControlName]="FIELD_USERNAME"
                  [ngClass]="{
                    'login-error':
                      (form.controls[FIELD_USERNAME].dirty ||
                        form.controls[FIELD_USERNAME].touched) &&
                      form.controls[FIELD_USERNAME].errors &&
                      form.controls[FIELD_USERNAME].errors?.required
                  }"
                />
              </div>
              <div class="mb-3">
                <div class="input-group">
                  <input
                    [type]="showPassword ? 'text' : 'password'"
                    class="form-control form-control-lg text-box"
                    placeholder="Contraseña"
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                    [formControlName]="FIELD_PASSWORD"
                    [ngClass]="{
                      'login-error':
                        (form.controls[FIELD_PASSWORD].dirty ||
                          form.controls[FIELD_PASSWORD].touched) &&
                        form.controls[FIELD_PASSWORD].errors &&
                        form.controls[FIELD_PASSWORD].errors?.required
                    }"
                    [controlErrorAnchor]="anchorPassword"
                    controlErrorsClass="tailor-error"
                  />
                  <div class="input-group-append">
                    <span class="icon-inside-noborder">
                      <i
                        class="fas fa-eye cursor-pointer"
                        (click)="showPassword = !showPassword"
                        title="Ver contraseña"
                      ></i>
                    </span>
                  </div>
                </div>
                <ng-template
                  controlErrorAnchor
                  #anchorPassword="controlErrorAnchor"
                ></ng-template>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <!-- <div class="custom-control custom-checkbox"> -->
                  <div class="text-center">
                    <!-- <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customCheck1"
                    />
                    <label class="custom-control-label" for="customCheck1">
                      Remember me
                     </label> -->
                    <a
                      [routerLink]="['/forgot-password']"
                      href="javascript:void(0)"
                      id="to-recover"
                      class="text-box"
                    >
                      <i class="fa fa-lock m-r-5"></i>
                      ¿Olvidó su contraseña?
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group text-center">
                <div class="col-xs-12 p-b-20">
                  <button
                    class="btn btn-lg btn-login"
                    type="submit"
                    [disabled]="loading === true"
                  >
                    <span
                      [ngClass]="{
                        'spinner-border spinner-border-sm': loading === true
                      }"
                      aria-hidden="true"
                    >
                      {{ loading === false ? 'INGRESAR' : '' }}
                    </span>
                  </button>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">
                  <div class="social">
                    <a
                      href="javascript:void(0)"
                      class="btn btn-facebook"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Login with Facebook"
                    >
                      <i aria-hidden="true" class="fab fa-facebook"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="btn btn-googleplus ml-2"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Login with Google"
                    >
                      <i aria-hidden="true" class="fab fa-google-plus"></i>
                    </a>
                  </div>
                </div>
              </div> -->
              <!-- <div class="form-group m-b-0 m-t-10">
                <div class="col-sm-12 text-center">
                  Don't have an account?
                  <a href="#/authentication/signup" class="text-info m-l-5">
                    <b>Sign Up</b>
                  </a>
                </div>
              </div> -->
            </form>
          </div>
        </div>
      </div>
      <app-environment-info></app-environment-info>
      <div id="recoverform" [ngClass]="{ 'd-block': recoverform }">
        <div class="logo">
          <span class="db">
            <img src="assets/images/logo-icon.png" alt="logo" />
          </span>
          <h5 class="font-medium m-b-20">Recover Password</h5>
          <span>Enter your Email and instructions will be sent to you!</span>
        </div>
        <div class="row m-t-20">
          <!-- Form -->
          <form class="col-12">
            <!-- email -->
            <div class="form-group row">
              <div class="col-12">
                <input
                  class="form-control form-control-lg"
                  type="email"
                  required=""
                  placeholder="Username"
                />
              </div>
            </div>
            <!-- pwd -->
            <div class="row m-t-20">
              <div class="col-12">
                <button
                  class="btn btn-block btn-lg btn-danger"
                  type="submit"
                  name="action"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
