import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import {
  AuthService,
  NotifierService,
  UserService,
  UtilService,
} from '@services/core';
import { UserNewSchema } from '@schemas/core';
import { confirmedValidator } from '@shared/form-validators';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  FIELD_PASSWORD = 'fileUserPassword';
  FIELD_PASSWORD_REPEAT = 'fileUserPasswordRepeat';

  form: FormGroup;
  code: string;
  showPassword = false;
  showRepeatPassword = false;

  constructor(
    private fb: FormBuilder,
    private notifier: NotifierService,
    private userService: UserService,
    private route: ActivatedRoute,
    private util: UtilService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.code = this.route.snapshot.params.code;
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.fb.group(
      {
        [this.FIELD_PASSWORD]: ['', Validators.required],
        [this.FIELD_PASSWORD_REPEAT]: ['', Validators.required],
      },
      {
        validators: confirmedValidator,
      },
    );
  }

  onSubmit(): void {
    const values = this.form.getRawValue();
    const user = new UserNewSchema();
    user.password = values[this.FIELD_PASSWORD];
    user.passwordConfirmation = values[this.FIELD_PASSWORD_REPEAT];
    user.code = this.code;

    this.userService.resetPassword(user).subscribe({
      complete: () => {
        this.notifier.saveDataSuccess();
        this.authService.redirectToLogin();
      },
      error: (err) => {
        if (this.util.ErrorUserPasswordNotMatching(err)) {
          this.notifier.passwordNotMatchs();
        } else if (this.util.ErrorUserInvalidCode(err)) {
          this.notifier.saveDataErrorUserCode();
        } else {
          this.notifier.saveDataError();
        }
      },
    });
  }
}
