import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import { DocumentDefSchema } from '@schemas/core';
import { IDocumentDefDatatable, IToSelect } from '@shared/interfaces';
import { DocumentDefTypeEnum } from '@shared/enums';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class DocumentDefService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<DocumentDefSchema[]> {
    let params = new HttpParams()
      .set('type', DocumentDefTypeEnum.DOCUMENT_MANAGEMENT)
      .set('_sort', 'order:ASC,name:ASC')
      .set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<DocumentDefSchema[]>(`${this.API_URL}/document-defs`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams()
      .set('type', DocumentDefTypeEnum.DOCUMENT_MANAGEMENT)
      .set('_sort', 'name:ASC');

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/document-defs/count`, {
      params,
    });
  }

  findByTypeCompanies(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<DocumentDefSchema[]> {
    let params = new HttpParams()
      .set('type', DocumentDefTypeEnum.COMPANY)
      .set('_sort', 'name:ASC')
      .set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<DocumentDefSchema[]>(`${this.API_URL}/document-defs`, {
      params,
    });
  }

  countByTypeCompanies(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<number> {
    let params = new HttpParams()
      .set('type', DocumentDefTypeEnum.COMPANY)
      .set('_sort', 'name:ASC');

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/document-defs/count`, {
      params,
    });
  }

  findOne(id: string): Observable<DocumentDefSchema> {
    return this.http.get<DocumentDefSchema>(
      `${this.API_URL}/document-defs/${id}`,
    );
  }

  create(documentDef: DocumentDefSchema): Observable<DocumentDefSchema> {
    return this.http.post<DocumentDefSchema>(
      `${this.API_URL}/document-defs`,
      documentDef,
    );
  }

  update(documentDef: DocumentDefSchema): Observable<DocumentDefSchema> {
    return this.http.put<DocumentDefSchema>(
      `${this.API_URL}/document-defs/${documentDef?.id}`,
      documentDef,
    );
  }

  delete(id: string): Observable<DocumentDefSchema> {
    return this.http.delete<DocumentDefSchema>(
      `${this.API_URL}/document-defs/${id}`,
    );
  }

  toDataTable(
    documentDef: Array<DocumentDefSchema>,
  ): Array<IDocumentDefDatatable> {
    return documentDef.map((d) => ({
      name: d.name,
      period: d.period,
      validate_in_contract_extract:
        d.validate_in_contract_extract === true ? 'Si' : 'No',
      id: d.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'name_contains',
        field: 'Nombre',
        default: true,
      },
      {
        APIField: 'period_contains',
        field: 'Periodo de Vigencia',
        default: false,
      },
    ]);
  }

  getTableFilterCompanies(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'name_contains',
        field: 'Nombre',
        default: true,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of([
      'Nombre',
      'Periodo de Vigencia',
      'Valida en extracto de contrato',
    ]);
  }

  getTableHeadCompanies(): Observable<string[]> {
    return of(['Nombre']);
  }

  toSelect(documentDefs: DocumentDefSchema[]): IToSelect[] {
    return documentDefs.map((a) => ({ id: a.id, description: a.name }));
  }

  orderDocumentDefByOrderAndName(
    documentsDef: DocumentDefSchema[],
  ): DocumentDefSchema[] {
    return documentsDef?.sort((a, b) => {
      const exclude = [null, 0];

      if (exclude.includes(a.order)) {
        a.order = 1000;
      }

      if (exclude.includes(b.order)) {
        b.order = 1000;
      }

      if (a.order === b.order) {
        return a.name < b.name ? -1 : 1;
      } else {
        return a.order < b.order ? -1 : 1;
      }
    });
  }
}
