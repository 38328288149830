import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import { EmployeeSchema, CompanySchema, PositionSchema } from '@schemas/core';
import { IEmployeeDatatable } from '@shared/interfaces';
import { map } from 'rxjs/operators';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<EmployeeSchema[]> {
    let params = new HttpParams().set('_sort', 'name:ASC').set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<EmployeeSchema[]>(`${this.API_URL}/employees`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams().set('_sort', 'name:ASC');

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/employees/count`, {
      params,
    });
  }

  findOne(id: string): Observable<EmployeeSchema> {
    return this.http.get<EmployeeSchema>(`${this.API_URL}/employees/${id}`);
  }

  /**
   * Find duplicate employee by identification.
   * Can exclude registers for find duplicate
   * Return array boolean
   *
   */
  findDuplicate(
    data: {
      identification: string;
    },
    exclude: string[],
  ): Observable<Array<boolean>> {
    return combineLatest([
      this.findDuplicateField('identification', data.identification, exclude),
    ]).pipe(map(([identification]) => [identification]));
  }

  /**
   * Return true if the field is duplicate
   * Can exclude registers for find duplicate
   *
   * En la actualidad Strapi no tiene una forma de buscar
   * un campo igual que no sea case sensitive, por eso se usa contains.
   *
   */
  private findDuplicateField(
    field: string,
    data: string,
    exclude: string[],
  ): Observable<boolean> {
    return this.http
      .get<EmployeeSchema[]>(
        `${this.API_URL}/employees?${field}_contains=${data}`,
      )
      .pipe(
        map((r) =>
          r.find(
            (x) =>
              !exclude.includes(x.id.toString()) &&
              x[field].toUpperCase() === data.toUpperCase(),
          )
            ? true
            : false,
        ),
      );
  }

  create(employee: EmployeeSchema): Observable<EmployeeSchema> {
    return this.http.post<EmployeeSchema>(
      `${this.API_URL}/employees`,
      employee,
    );
  }

  update(employee: EmployeeSchema): Observable<EmployeeSchema> {
    return this.http.put<EmployeeSchema>(
      `${this.API_URL}/employees/${employee?.id}`,
      employee,
    );
  }

  delete(id: string): Observable<EmployeeSchema> {
    return this.http.delete<EmployeeSchema>(`${this.API_URL}/employees/${id}`);
  }

  toDataTable(employee: Array<EmployeeSchema>): Array<IEmployeeDatatable> {
    return employee.map((e) => ({
      name: e.name,
      identification: e.identification,
      company: (e.company as CompanySchema).name,
      position: (e.position as PositionSchema).name,
      id: e.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'name_contains',
        field: 'Nombre',
        default: true,
      },
      {
        APIField: 'identification_contains',
        field: 'Identifación',
        default: false,
      },
      {
        APIField: 'company.name_contains',
        field: 'Empresa',
        default: false,
      },
      {
        APIField: 'position.name_contains',
        field: 'Cargo',
        default: false,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of(['Nombre', 'Identificación', 'Empresa', 'Cargo']);
  }
}
