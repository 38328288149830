import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';

import { MessageSchema } from '@schemas/core';
import { BaseService } from '../base/base.service';
import { IMessageDatatable } from '@shared/interfaces';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({ providedIn: 'root' })
export class MessageService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<MessageSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'created_at:DESC')
      .set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<MessageSchema[]>(`${this.API_URL}/messages`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams().set('_sort', 'created_at:ASC');

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/messages/count`, {
      params,
    });
  }

  findLast(): Observable<MessageSchema[]> {
    const params = new HttpParams()
      .set('_sort', 'created_at:DESC')
      .set('_limit', 3);

    return this.http.get<MessageSchema[]>(`${this.API_URL}/messages`, {
      params,
    });
  }

  create(
    message: Pick<
      MessageSchema,
      'type' | 'title' | 'subject' | 'icon' | 'action_url'
    >,
  ): Observable<MessageSchema> {
    return this.http.post<MessageSchema>(`${this.API_URL}/messages`, message);
  }

  getTableHead(): Observable<string[]> {
    return of(['Título', 'Descripción']);
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'subject_contains',
        field: 'Descripción',
        default: true,
      },
      {
        APIField: 'title_contains',
        field: 'Titulo',
        default: false,
      },
    ]);
  }

  toDataTable(messages: MessageSchema[]): IMessageDatatable[] {
    return messages.map((m) => ({
      title: m.title,
      description: m.subject,
      action_url: m.action_url,
      id: m.id,
    }));
  }
}
