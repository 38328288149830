import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { RouteInfo } from './vertical-sidebar.metadata';
import { VerticalSidebarService } from './vertical-sidebar.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
declare var $: any;

import { environment } from 'src/environments/environment';
import { StarterService } from '@services/core';

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
})
export class VerticalSidebarComponent implements OnDestroy {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: RouteInfo[] = [];
  path = '';
  readonly env = environment;

  @Input() showClass = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  subscriptions = new Subscription();

  handleNotify(title: string): void {
    // For hide menu on mobile
    this.notify.emit(!this.showClass);

    // For show starter page menu cards
    this.starterService.starterMenuItem.emit(title);
  }

  constructor(
    private menuService: VerticalSidebarService,
    private router: Router,
    private starterService: StarterService,
  ) {
    this.subscriptions.add(
      this.menuService.items.subscribe((menuItems) => {
        this.sidebarnavItems = menuItems.filter((m) => m.visible);

        // Active menu
        this.sidebarnavItems.filter((m) =>
          m.submenu.filter((s) => {
            if (s.path === this.router.url) {
              this.path = m.title;
            }
          }),
        );
        this.addExpandClass(this.path);
      }),
    );

    this.subscriptions.add(
      this.menuService.expandMenuItem.subscribe({
        next: (title: string) => {
          this.showMenu = title;
        },
      }),
    );
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  goHome(): void {
    this.showMenu = '0';
    this.handleNotify('home');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
