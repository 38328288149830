import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalButtonsStateEnum } from '@shared/enums';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css'],
})
export class DeleteDialogComponent implements OnInit {
  modalButtonsStateEnum = ModalButtonsStateEnum;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
