import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { BaseService } from '../base/base.service';
import { DriverSchema, DocumentSubjectSchema } from '@schemas/core';
import { IDriverDatatable, IToSelect } from '@shared/interfaces';
import { DriverRelationTypeEnum } from '@shared/enums';
import { map } from 'rxjs/operators';
import { DateTimeService } from '../datetime/datetime.service';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';

@Injectable({
  providedIn: 'root',
})
export class DriverService extends BaseService {
  constructor(
    protected http: HttpClient,
    private dateTimeService: DateTimeService,
  ) {
    super(http);
  }

  find(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<DriverSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'firstname:ASC,lastname:ASC')
      .set('_limit', -1);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.set(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.set('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.set(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<DriverSchema[]>(`${this.API_URL}/drivers`, {
      params,
    });
  }

  count(stateDataTable: IStateDataTable | null = null): Observable<number> {
    let params = new HttpParams().set('_sort', 'firstname:ASC,lastname:ASC');

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/drivers/count`, {
      params,
    });
  }

  findOne(id: string): Observable<DriverSchema> {
    return this.http.get<DriverSchema>(`${this.API_URL}/drivers/${id}`);
  }

  /**
   * Find duplicate driver by document_number, or license_number.
   * Can exclude registers for find duplicate
   * Return array boolean on this order document_number, license_number
   *
   */
  findDuplicate(
    data: {
      document_number: string;
      license_number: string;
    },
    exclude: string[],
  ): Observable<Array<boolean>> {
    return combineLatest([
      this.findDuplicateField('document_number', data.document_number, exclude),
      this.findDuplicateField('license_number', data.license_number, exclude),
    ]).pipe(
      map(([document_number, license_number]) => [
        document_number,
        license_number,
      ]),
    );
  }

  /**
   * Return true if the field is duplicate
   * Can exclude registers for find duplicate
   *
   * En la actualidad Strapi no tiene una forma de buscar
   * un campo igual que no sea case sensitive, por eso se usa contains.
   *
   */
  private findDuplicateField(
    field: string,
    data: string,
    exclude: string[],
  ): Observable<boolean> {
    return this.http
      .get<DriverSchema[]>(`${this.API_URL}/drivers?${field}_contains=${data}`)
      .pipe(
        map((r) =>
          r.find(
            (x) =>
              !exclude.includes(x.id.toString()) &&
              x[field].toUpperCase() === data.toUpperCase(),
          )
            ? true
            : false,
        ),
      );
  }
  /**
   * Busca los conductores activos por un vehículo específico
   * @param id
   * @returns Observable<DriverSchema[]>
   */
  findByVehicleId(id: string): Observable<DriverSchema[]> {
    return this.http.get<DriverSchema[]>(
      `${this.API_URL}/drivers?vehicles_in=${id}&active=true&active=true&_sort=firstname:ASC,lastname:ASC`,
    );
  }

  /**
   * Busca conductores según el array de ids que se pasa
   * por parámetro
   *
   * @return Observable<AffiliateSchema[]>
   * @memberof AffiliateService
   */
  findByIds(ids: string[]): Observable<DriverSchema[]> {
    const query = ids.map((i) => `id_in=${i}`).join('&');
    return this.http.get<DriverSchema[]>(`${this.API_URL}/drivers/?${query}`);
  }

  create(driver: DriverSchema): Observable<DriverSchema> {
    return this.http.post<DriverSchema>(`${this.API_URL}/drivers`, driver);
  }

  update(driver: DriverSchema): Observable<DriverSchema> {
    return this.http.put<DriverSchema>(
      `${this.API_URL}/drivers/${driver?.id}`,
      driver,
    );
  }

  delete(id: string): Observable<DriverSchema> {
    return this.http.delete<DriverSchema>(`${this.API_URL}/drivers/${id}`);
  }

  toDataTable(drivers: Array<DriverSchema>): Array<IDriverDatatable> {
    return drivers.map((d) => ({
      document_number: d.document_number,
      name: `${d.firstname} ${d.lastname}`,
      license_number: d.license_number,
      license_expedition_place: d.license_expedition_place,
      license_end_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        d.license_end_date,
      ),
      license_category: d.license_category,
      documentsSubject: d.document_subjects as DocumentSubjectSchema[],
      id: d.id,
    }));
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'document_number_contains',
        field: 'Documento',
        default: false,
      },
      {
        APIField: 'firstname_contains',
        field: 'Nombres',
        default: false,
      },
      {
        APIField: 'lastname_contains',
        field: 'Apellidos',
        default: false,
      },
      {
        APIField: 'license_number_contains',
        field: 'Número de licencia',
        default: true,
      },
      {
        APIField: 'license_expedition_place_contains',
        field: 'Lugar de expedición',
        default: false,
      },
      {
        APIField: 'license_category_contains',
        field: 'Categoría',
        default: false,
      },
    ]);
  }

  getTableHead(): Observable<string[]> {
    return of([
      'Documento',
      'Nombre',
      'Número de Licencia',
      'Lugar de Expedición',
      'Fecha de Licencia',
      'Categoría',
    ]);
  }

  getRelationTypeSelect(): Observable<IToSelect[]> {
    const driverRelationType = Object.keys(DriverRelationTypeEnum).map(
      (dr) => ({
        id: dr,
        description: DriverRelationTypeEnum[dr],
      }),
    );
    driverRelationType.shift();
    driverRelationType.sort((a, b) =>
      a.description.localeCompare(b.description),
    );
    driverRelationType.unshift({
      id: Object.keys(DriverRelationTypeEnum)[
        DriverRelationTypeEnum.NO_APLICA.indexOf(
          DriverRelationTypeEnum.NO_APLICA,
        )
      ],
      description: DriverRelationTypeEnum.NO_APLICA,
    });

    return of(driverRelationType);
  }

  toSelect(drivers: DriverSchema[]): IToSelect[] {
    return drivers.map((d) => ({
      id: d.id,
      description: `${d.document_number} - ${d.firstname} ${d.lastname}`,
    }));
  }
}
