import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { BaseService } from '../base/base.service';
import {
  AffiliateSchema,
  DocumentDefSchema,
  DocumentSubjectSchema,
  DriverSchema,
  VehicleSchema,
  CompanySchema,
} from '@schemas/core';
import {
  IDocumentDefReviewDatatable,
  IDocumentSubjectAffiliateAlertDatatable,
  IDocumentSubjectDriverAlertDatatable,
  IDocumentSubjectVehicleAlertDatatable,
  IDocumentSubjectCompanyAlertDatatable,
  IVehicleDocumentsUpdated,
  IAffiliateDocumentsUpdated,
  IDriverDocumentsUpdated,
} from '@shared/interfaces';
import {
  DocumentSubjectStatusTextEnum,
  DocumentSubjectStatusEnum,
} from '@shared/enums';
import {
  AffiliateService,
  DocumentRequestedService,
  DriverService,
  VehicleService,
  CompanyService,
} from '@services/core';

import { DateTimeService } from '../datetime/datetime.service';
import {
  IFilterDataTable,
  IStateDataTable,
} from '@shared/data-table-v2/state.data-table.interface';
import { DEFAULT_DAYS_TO_ALERT_DOCUMENT_EXPIRATION } from '@shared/consts';

@Injectable({
  providedIn: 'root',
})
export class DocumentSubjectService extends BaseService {
  constructor(
    protected http: HttpClient,
    private affiliateService: AffiliateService,
    private vehicleService: VehicleService,
    private driverService: DriverService,
    private companyService: CompanyService,
    private documentRequestedService: DocumentRequestedService,
    private dateTimeService: DateTimeService,
  ) {
    super(http);
  }

  findByAffiliates(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<DocumentSubjectSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'created_at:DESC,status:ASC')
      .set('affiliate_null', false)
      .set('status', DocumentSubjectStatusEnum.NO_VERIFIED);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.append(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.append('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<DocumentSubjectSchema[]>(
      `${this.API_URL}/document-subjects`,
      { params },
    );
  }

  countByAffiliates(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<number> {
    let params = new HttpParams()
      .set('affiliate_null', false)
      .set('status', DocumentSubjectStatusEnum.NO_VERIFIED);

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/document-subjects/count`, {
      params,
    });
  }

  findByDrivers(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<DocumentSubjectSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'created_at:DESC,status:ASC')
      .set('driver_null', false)
      .set('status', DocumentSubjectStatusEnum.NO_VERIFIED);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.append(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.append('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;
    return this.http.get<DocumentSubjectSchema[]>(
      `${this.API_URL}/document-subjects`,
      { params },
    );
  }

  countByDrivers(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<number> {
    let params = new HttpParams()
      .set('driver_null', false)
      .set('status', DocumentSubjectStatusEnum.NO_VERIFIED);

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/document-subjects/count`, {
      params,
    });
  }

  findByVehicles(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<DocumentSubjectSchema[]> {
    let params = new HttpParams()
      .set('_sort', 'created_at:DESC,status:ASC')
      .set('vehicle_null', false)
      .set('status', DocumentSubjectStatusEnum.NO_VERIFIED);

    // Paginación y ordenamiento
    params = stateDataTable?.page
      ? params.append(
          '_start',
          (stateDataTable.page - 1) * stateDataTable.pageSize,
        )
      : params;
    params = stateDataTable?.pageSize
      ? params.append('_limit', stateDataTable.pageSize)
      : params;

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;
    return this.http.get<DocumentSubjectSchema[]>(
      `${this.API_URL}/document-subjects`,
      { params },
    );
  }

  countByVehicles(
    stateDataTable: IStateDataTable | null = null,
  ): Observable<number> {
    let params = new HttpParams()
      .set('vehicle_null', false)
      .set('status', DocumentSubjectStatusEnum.NO_VERIFIED);

    params = stateDataTable?.searchTerm
      ? params.append(
          stateDataTable?.searchTerm?.APIField,
          stateDataTable?.searchTerm?.term,
        )
      : params;

    return this.http.get<number>(`${this.API_URL}/document-subjects/count`, {
      params,
    });
  }

  findByAffiliateIdAndDocumentDefId(params: {
    affiliate_id: string;
    document_def_id: string;
  }): Observable<DocumentSubjectSchema[]> {
    return this.http.get<DocumentSubjectSchema[]>(
      // eslint-disable-next-line max-len
      `${this.API_URL}/document-subjects?affiliate.id=${params.affiliate_id}&document_def.id=${params.document_def_id}&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=start_validity:DESC`,
    );
  }

  findByVehicleIdAndDocumentDefId(params: {
    vehicle_id: string;
    document_def_id: string;
  }): Observable<DocumentSubjectSchema[]> {
    return this.http.get<DocumentSubjectSchema[]>(
      // eslint-disable-next-line max-len
      `${this.API_URL}/document-subjects?vehicle.id=${params.vehicle_id}&document_def.id=${params.document_def_id}&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=start_validity:DESC`,
    );
  }

  findByDriverIdAndDocumentDefId(params: {
    driver_id: string;
    document_def_id: string;
  }): Observable<DocumentSubjectSchema[]> {
    return this.http.get<DocumentSubjectSchema[]>(
      // eslint-disable-next-line max-len
      `${this.API_URL}/document-subjects?driver.id=${params.driver_id}&document_def.id=${params.document_def_id}&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=start_validity:DESC`,
    );
  }

  findByCompanyIdAndDocumentDefId(params: {
    company_id: string;
    document_def_id: string;
  }): Observable<DocumentSubjectSchema[]> {
    return this.http.get<DocumentSubjectSchema[]>(
      // eslint-disable-next-line max-len
      `${this.API_URL}/document-subjects?company.id=${params.company_id}&document_def.id=${params.document_def_id}&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=start_validity:DESC`,
    );
  }

  findByDocumentDefId(params: {
    document_def_id: string;
  }): Observable<DocumentSubjectSchema[]> {
    return this.http.get<DocumentSubjectSchema[]>(
      `${this.API_URL}/document-subjects?document_def.id=${params.document_def_id}`,
    );
  }

  findByDocumentDefSubjectId(params: {
    document_def_id: string;
    subject: string;
  }): Observable<DocumentSubjectSchema[]> {
    return this.http.get<DocumentSubjectSchema[]>(
      `${this.API_URL}/document-subjects?document_def.id=${
        params.document_def_id
      }&${params.subject.toLowerCase()}_null=false`,
    );
  }

  findOne(id: string): Observable<DocumentSubjectSchema> {
    return this.http.get<DocumentSubjectSchema>(
      `${this.API_URL}/document-subjects/${id}`,
    );
  }

  /**
   * Busca el tipo de documento del afiliado con la fecha mas lejana para vencerse y status Validado
   * Este será el documento actual válido para el afiliado
   *
   * @param string document_def_id
   * @returns Observable<DocumentSubjectSchema>
   * @memberof DocumentSubjectService
   */
  findCurrentDocumentByAffiliateIdAndDocumentDefId(params: {
    affiliate_id: string;
    document_def_id: string;
  }): Observable<DocumentSubjectSchema> {
    return this.http
      .get<DocumentSubjectSchema[]>(
        // eslint-disable-next-line max-len
        `${this.API_URL}/document-subjects?affiliate.id=${params.affiliate_id}&document_def.id=${params.document_def_id}&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=end_validity:DESC`,
      )
      .pipe(map((d) => d[0]));
  }

  /**
   * Busca el tipo de documento del vehiculo con la fecha mas lejana para vencerse y status Validado
   * Este será el documento actual válido para el vehiculo
   *
   * @param string document_def_id
   * @returns Observable<DocumentSubjectSchema>
   * @memberof DocumentSubjectService
   */
  findCurrentDocumentByVehicleIdAndDocumentDefId(params: {
    vehicle_id: string;
    document_def_id: string;
  }): Observable<DocumentSubjectSchema> {
    return this.http
      .get<DocumentSubjectSchema[]>(
        // eslint-disable-next-line max-len
        `${this.API_URL}/document-subjects?vehicle.id=${params.vehicle_id}&document_def.id=${params.document_def_id}&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=end_validity:DESC`,
      )
      .pipe(map((d) => d[0]));
  }

  /**
   * Busca el tipo de documento del driver con la fecha mas lejana para vencerse y status Validado
   * Este será el documento actual válido para el driver
   *
   * @param string document_def_id
   * @returns Observable<DocumentSubjectSchema>
   * @memberof DocumentSubjectService
   */
  findCurrentDocumentByDriverIdAndDocumentDefId(params: {
    driver_id: string;
    document_def_id: string;
  }): Observable<DocumentSubjectSchema> {
    return this.http
      .get<DocumentSubjectSchema[]>(
        // eslint-disable-next-line max-len
        `${this.API_URL}/document-subjects?driver.id=${params.driver_id}&document_def.id=${params.document_def_id}&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=end_validity:DESC`,
      )
      .pipe(map((d) => d[0]));
  }

  /**
   * Busca el tipo de documento de la empresa con la fecha mas lejana para vencerse y status Validado
   * Este será el documento actual válido para la empresa
   *
   * @param string document_def_id
   * @returns Observable<DocumentSubjectSchema>
   * @memberof DocumentSubjectService
   */
  findCurrentDocumentByCompanyIdAndDocumentDefId(params: {
    company_id: string;
    document_def_id: string;
  }): Observable<DocumentSubjectSchema> {
    return this.http
      .get<DocumentSubjectSchema[]>(
        // eslint-disable-next-line max-len
        `${this.API_URL}/document-subjects?company.id=${params.company_id}&document_def.id=${params.document_def_id}&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=end_validity:DESC`,
      )
      .pipe(map((d) => d[0]));
  }

  /**
   * Busca los tipos de documentos para todos los afiliados con la fecha mas lejana para vencerse y status Validado
   * Estos serán los documentos actuales válidos para cada uno de los afiliados
   *
   * @returns Observable<DocumentSubjectSchema[]>
   * @memberof DocumentSubjectService
   */
  findCurrentDocumentsByAffiliates(): Observable<DocumentSubjectSchema[]> {
    const documentSubjects$ = this.http.get<DocumentSubjectSchema[]>(
      // eslint-disable-next-line max-len
      `${this.API_URL}/document-subjects?affiliate_null=false&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=affiliate.id:DESC,document_def.id:DESC,end_validity:DESC&_limit=-1`,
    );

    return combineLatest([
      this.affiliateService.find(),
      this.documentRequestedService.findByAffiliate(),
      documentSubjects$,
    ]).pipe(
      map(([affiliates, documentRequested, documentSubjects]) => {
        const ret: Array<DocumentSubjectSchema> = [];
        const documentDefs = documentRequested[0]
          .document_defs as DocumentDefSchema[];
        affiliates?.forEach((a) => {
          documentDefs?.forEach((dd) => {
            const ds =
              documentSubjects?.filter(
                (d) =>
                  (d.affiliate as AffiliateSchema)?.id === a?.id &&
                  (d.document_def as DocumentDefSchema)?.id === dd?.id,
              )[0] ?? null;

            if (ds) {
              // Se toma la fecha de vencimiento del documento definida en document_def
              const daysToAlertExpiration =
                dd.days_to_alert_expiration ??
                DEFAULT_DAYS_TO_ALERT_DOCUMENT_EXPIRATION;
              // A la fecha actual se suman los días para alertar vencimiento del documento
              const endValidity =
                this.dateTimeService.getStringFormatISOFromDate(
                  this.dateTimeService.getDateAddDays(
                    this.dateTimeService.getDate(),
                    daysToAlertExpiration,
                  ),
                );
              // Si el documento tiene fecha menor, entonces se alerta el vencimiento
              if (
                this.dateTimeService.getDate(ds?.end_validity) <
                this.dateTimeService.getDate(endValidity)
              ) {
                ret.push(ds);
              }
            } else {
              ret.push({
                affiliate: a,
                document_def: dd,
              } as DocumentSubjectSchema);
            }
          });
        });
        return ret;
      }),
    );
  }

  /**
   * Busca los tipos de documentos para todos los vehiculos con la fecha mas lejana para vencerse y status Validado
   * Estos serán los documentos actuales válidos para cada uno de los vehiculos
   *
   * @returns Observable<DocumentSubjectSchema[]>
   * @memberof DocumentSubjectService
   */
  findCurrentDocumentsByVehicles(): Observable<DocumentSubjectSchema[]> {
    const documentSubjects$ = this.http.get<DocumentSubjectSchema[]>(
      // eslint-disable-next-line max-len
      `${this.API_URL}/document-subjects?vehicle_null=false&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=vehicle.id:DESC,document_def.id:DESC,end_validity:DESC&_limit=-1`,
    );

    return combineLatest([
      this.vehicleService.find(),
      this.documentRequestedService.findByVehicle(),
      documentSubjects$,
    ]).pipe(
      map(([vehicles, documentRequested, documentSubjects]) => {
        const ret: Array<DocumentSubjectSchema> = [];
        const documentDefs = documentRequested[0]
          .document_defs as DocumentDefSchema[];
        vehicles?.forEach((v) => {
          documentDefs?.forEach((dd) => {
            const ds =
              documentSubjects?.filter(
                (d) =>
                  (d.vehicle as VehicleSchema)?.id === v?.id &&
                  (d.document_def as DocumentDefSchema)?.id === dd?.id,
              )[0] ?? null;

            if (ds) {
              // Se toma la fecha de vencimiento del documento definida en document_def
              const daysToAlertExpiration =
                dd.days_to_alert_expiration ??
                DEFAULT_DAYS_TO_ALERT_DOCUMENT_EXPIRATION;
              // A la fecha actual se suman los días para alertar vencimiento del documento
              const endValidity =
                this.dateTimeService.getStringFormatISOFromDate(
                  this.dateTimeService.getDateAddDays(
                    this.dateTimeService.getDate(),
                    daysToAlertExpiration,
                  ),
                );
              // Si el documento tiene fecha menor, entonces se alerta el vencimiento
              if (
                this.dateTimeService.getDate(ds?.end_validity) <
                this.dateTimeService.getDate(endValidity)
              ) {
                // Se sobreescribe la información de vehiculo ya que la que
                // viene del array de vehicles trae la información del afiliado,
                // el vehículo que viene en ds no trae el afiliado sino el id
                ret.push({ ...ds, vehicle: v });
              }
            } else {
              ret.push({
                vehicle: v,
                document_def: dd,
              } as DocumentSubjectSchema);
            }
          });
        });
        return ret;
      }),
    );
  }

  /**
   * Busca los tipos de documentos para todos los conductores con la fecha mas lejana para vencerse y status Validado
   * Estos serán los documentos actuales válidos para cada uno de los conductores
   *
   * @returns Observable<DocumentSubjectSchema[]>
   * @memberof DocumentSubjectService
   */
  findCurrentDocumentsByDrivers(): Observable<DocumentSubjectSchema[]> {
    const documentSubjects$ = this.http.get<DocumentSubjectSchema[]>(
      // eslint-disable-next-line max-len
      `${this.API_URL}/document-subjects?driver_null=false&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=driver.id:DESC,document_def.id:DESC,end_validity:DESC&_limit=-1`,
    );

    return combineLatest([
      this.driverService.find(),
      this.documentRequestedService.findByDriver(),
      documentSubjects$,
    ]).pipe(
      map(([drivers, documentRequested, documentSubjects]) => {
        const ret: Array<DocumentSubjectSchema> = [];
        const documentDefs = documentRequested[0]
          .document_defs as DocumentDefSchema[];
        drivers?.forEach((drv) => {
          documentDefs?.forEach((dd) => {
            const ds =
              documentSubjects?.filter(
                (d) =>
                  (d.driver as DriverSchema)?.id === drv?.id &&
                  (d.document_def as DocumentDefSchema)?.id === dd?.id,
              )[0] ?? null;

            if (ds) {
              // Se toma la fecha de vencimiento del documento definida en document_def
              const daysToAlertExpiration =
                dd.days_to_alert_expiration ??
                DEFAULT_DAYS_TO_ALERT_DOCUMENT_EXPIRATION;
              // A la fecha actual se suman los días para alertar vencimiento del documento
              const endValidity =
                this.dateTimeService.getStringFormatISOFromDate(
                  this.dateTimeService.getDateAddDays(
                    this.dateTimeService.getDate(),
                    daysToAlertExpiration,
                  ),
                );
              // Si el documento tiene fecha menor, entonces se alerta el vencimiento
              if (
                this.dateTimeService.getDate(ds?.end_validity) <
                this.dateTimeService.getDate(endValidity)
              ) {
                ret.push(ds);
              }
            } else {
              ret.push({
                driver: drv,
                document_def: dd,
              } as DocumentSubjectSchema);
            }
          });
        });
        return ret;
      }),
    );
  }

  /**
   * Busca los tipos de documentos para todas las empresas con la fecha mas lejana para vencerse y status Validado
   * Estos serán los documentos actuales válidos para cada una de las empresas
   *
   * @returns Observable<DocumentSubjectSchema[]>
   * @memberof DocumentSubjectService
   */
  findCurrentDocumentsByCompanies(): Observable<DocumentSubjectSchema[]> {
    const documentSubjects$ = this.http.get<DocumentSubjectSchema[]>(
      // eslint-disable-next-line max-len
      `${this.API_URL}/document-subjects?company_null=false&status=${DocumentSubjectStatusEnum.VERIFIED}&_sort=company.id:DESC,document_def.id:DESC,end_validity:DESC`,
    );

    return combineLatest([
      this.companyService.find(),
      this.documentRequestedService.findByCompany(),
      documentSubjects$,
    ]).pipe(
      map(([companies, documentRequested, documentSubjects]) => {
        const ret: Array<DocumentSubjectSchema> = [];
        const documentDefs = documentRequested[0]
          .document_defs as DocumentDefSchema[];
        companies?.forEach((c) => {
          documentDefs?.forEach((dd) => {
            const ds =
              documentSubjects?.filter(
                (d) =>
                  (d.company as CompanySchema)?.id === c?.id &&
                  (d.document_def as DocumentDefSchema)?.id === dd?.id,
              )[0] ?? null;

            if (ds) {
              // Se toma la fecha de vencimiento del documento definida en document_def
              const daysToAlertExpiration =
                dd.days_to_alert_expiration ??
                DEFAULT_DAYS_TO_ALERT_DOCUMENT_EXPIRATION;
              // A la fecha actual se suman los días para alertar vencimiento del documento
              const endValidity =
                this.dateTimeService.getStringFormatISOFromDate(
                  this.dateTimeService.getDateAddDays(
                    this.dateTimeService.getDate(),
                    daysToAlertExpiration,
                  ),
                );
              // Si el documento tiene fecha menor, entonces se alerta el vencimiento
              if (
                this.dateTimeService.getDate(ds?.end_validity) <
                this.dateTimeService.getDate(endValidity)
              ) {
                ret.push(ds);
              }
            } else {
              ret.push({
                company: c,
                document_def: dd,
              } as DocumentSubjectSchema);
            }
          });
        });
        return ret;
      }),
    );
  }

  /**
   * Busca los tipos de documentos para los vehiculos especificados en array de ids
   * y se verifica que estén actualizados.
   * Se toman solo los tipos de documentos marcados para validar en extracto de contrato (FUEC)
   * Si se encuentra algún documento desactualizado, el vehículo se marca con documentación desactualizada.
   *
   * @returns Observable<IVehicleDocumentsUpdated[]>
   * @memberof DocumentSubjectService
   */
  findVehiclesWithFullUpdatedDocumentsByIds(
    vehicleIds: string[],
    endValidity: string,
  ): Observable<IVehicleDocumentsUpdated[]> {
    const ids = vehicleIds.map((i) => `vehicle.id_in=${i}`).join('&');
    const documentSubjects$ = this.http.get<DocumentSubjectSchema[]>(
      // Busca los documentos solicitados para los id de los vehículos, los documentos deben estar verificados
      // y tener fecha de vencimiento mayor a la fecha dada, además deben estar marcados para validar en extracto de contrato (FUEC)
      // eslint-disable-next-line max-len
      `${this.API_URL}/document-subjects?${ids}&status=${DocumentSubjectStatusEnum.VERIFIED}&end_validity_gte=${endValidity}&document_def.validate_in_contract_extract=true&_sort=vehicle.id:DESC,document_def.id:DESC,end_validity:DESC&_limit=-1`,
    );

    return combineLatest([
      this.vehicleService.findByIds(vehicleIds),
      this.documentRequestedService.findByVehicle(),
      documentSubjects$,
    ]).pipe(
      map(([vehicles, documentRequested, documentSubjects]) => {
        const ret: IVehicleDocumentsUpdated[] = [];
        // Se toman solo los documentos que están marcados para validar en extracto de contrato
        const documentDefs = (
          documentRequested[0].document_defs as DocumentDefSchema[]
        )?.filter((dd) => dd.validate_in_contract_extract === true);
        vehicles?.forEach((v) => {
          const vehicleDocStatus: IVehicleDocumentsUpdated = {
            vehicle: v,
            // Por defecto se toma como actualizados los documentos
            // ya que puede no tener tipo de documento para verificar
            docUpdated: true,
          };
          // Se toma cada definición de documento
          // y se verifica si hay un document subject actualizado
          // Se rompe cuando algún document subject no existe,
          // lo que indica que no está actualizado.
          documentDefs?.some((dd: DocumentDefSchema) => {
            const ds =
              documentSubjects?.filter(
                (d) =>
                  (d.vehicle as VehicleSchema)?.id === v?.id &&
                  (d.document_def as DocumentDefSchema)?.id === dd?.id,
              )[0] ?? null;

            // Si existe un document subject es porque es un documento actualizado
            // Se mantiene como actualizada la doc del vehículo y se va a verificar el
            // próximo documento, si hay.
            // Si no existe el document subject es porque no es un documento actualizado
            // No se buscan más documentos ya que aquí importa es saber si toda la
            // documentación está actualizada
            vehicleDocStatus.docUpdated = ds ? true : false;
            return vehicleDocStatus.docUpdated === false;
          });
          ret.push(vehicleDocStatus);
        });
        return ret;
      }),
    );
  }

  /**
   * Busca los tipos de documentos para los afiliados especificados en array de ids
   * y se verifica que estén actualizados.
   * Se toman solo los tipos de documentos marcados para validar en extracto de contrato (FUEC)
   * Si se encuentra algún documento desactualizado, el afiliado se marca con documentación desactualizada.
   *
   * @returns Observable<IAffiliateDocumentsUpdated[]>
   * @memberof DocumentSubjectService
   */
  findAffiliatesWithFullUpdatedDocumentsByIds(
    affiliateIds: string[],
    endValidity: string,
  ): Observable<IAffiliateDocumentsUpdated[]> {
    const ids = affiliateIds.map((i) => `affiliate.id_in=${i}`).join('&');
    const documentSubjects$ = this.http.get<DocumentSubjectSchema[]>(
      // Busca los documentos solicitados para los id de los afiliados, los documentos deben estar verificados
      // y tener fecha de vencimiento mayor a la fecha dada, además deben estar marcados para validar en extracto de contrato (FUEC)
      // eslint-disable-next-line max-len
      `${this.API_URL}/document-subjects?${ids}&status=${DocumentSubjectStatusEnum.VERIFIED}&end_validity_gte=${endValidity}&document_def.validate_in_contract_extract=true&_sort=affiliate.id:DESC,document_def.id:DESC,end_validity:DESC&_limit=-1`,
    );

    return combineLatest([
      this.affiliateService.findByIds(affiliateIds),
      this.documentRequestedService.findByAffiliate(),
      documentSubjects$,
    ]).pipe(
      map(([affiliates, documentRequested, documentSubjects]) => {
        const ret: IAffiliateDocumentsUpdated[] = [];
        // Se toman solo los documentos que están marcados para validar en extracto de contrato
        const documentDefs = (
          documentRequested[0].document_defs as DocumentDefSchema[]
        )?.filter((dd) => dd.validate_in_contract_extract === true);
        affiliates?.forEach((affiliate) => {
          const affiliateDocStatus: IAffiliateDocumentsUpdated = {
            affiliate,
            // Por defecto se toma como actualizados los documentos
            // ya que puede no tener tipo de documento para verificar
            docUpdated: true,
          };
          // Se toma cada definición de documento
          // y se verifica si hay un document subject actualizado
          // Se rompe cuando algún document subject no existe,
          // lo que indica que no está actualizado.
          documentDefs?.some((dd: DocumentDefSchema) => {
            const ds =
              documentSubjects?.filter(
                (d) =>
                  (d.affiliate as AffiliateSchema)?.id === affiliate?.id &&
                  (d.document_def as DocumentDefSchema)?.id === dd?.id,
              )[0] ?? null;

            // Si existe un document subject es porque es un documento actualizado
            // Se mantiene como actualizada la doc del afiliado y se va a verificar el
            // próximo documento, si hay.
            // Si no existe el document subject es porque no es un documento actualizado
            // No se buscan más documentos ya que aquí importa es saber si toda la
            // documentación está actualizada
            affiliateDocStatus.docUpdated = ds ? true : false;
            return affiliateDocStatus.docUpdated === false;
          });
          ret.push(affiliateDocStatus);
        });
        return ret;
      }),
    );
  }

  /**
   * Busca los tipos de documentos para los afiliados especificados en array de ids
   * y se verifica que estén actualizados.
   * Se toman solo los tipos de documentos marcados para validar en extracto de contrato (FUEC)
   * Si se encuentra algún documento desactualizado, el afiliado se marca con documentación desactualizada.
   *
   * @returns Observable<IAffiliateDocumentsUpdated[]>
   * @memberof DocumentSubjectService
   */
  findDriversWithFullUpdatedDocumentsByIds(
    driversIds: string[],
    endValidity: string,
  ): Observable<IDriverDocumentsUpdated[]> {
    const ids = driversIds.map((i) => `driver.id_in=${i}`).join('&');
    const documentSubjects$ = this.http.get<DocumentSubjectSchema[]>(
      // Busca los documentos solicitados para los id de los afiliados, los documentos deben estar verificados
      // y tener fecha de vencimiento mayor a la fecha dada, además deben estar marcados para validar en extracto de contrato (FUEC)
      // eslint-disable-next-line max-len
      `${this.API_URL}/document-subjects?${ids}&status=${DocumentSubjectStatusEnum.VERIFIED}&end_validity_gte=${endValidity}&document_def.validate_in_contract_extract=true&_sort=driver.id:DESC,document_def.id:DESC,end_validity:DESC&_limit=-1`,
    );

    return combineLatest([
      this.driverService.findByIds(driversIds),
      this.documentRequestedService.findByDriver(),
      documentSubjects$,
    ]).pipe(
      map(([drivers, documentRequested, documentSubjects]) => {
        const ret: IDriverDocumentsUpdated[] = [];
        // Se toman solo los documentos que están marcados para validar en extracto de contrato
        const documentDefs = (
          documentRequested[0].document_defs as DocumentDefSchema[]
        )?.filter((dd) => dd.validate_in_contract_extract === true);
        drivers?.forEach((driver) => {
          const driverDocStatus: IDriverDocumentsUpdated = {
            driver,
            // Por defecto se toma como actualizados los documentos
            // ya que puede no tener tipo de documento para verificar
            docUpdated: true,
          };
          // Se toma cada definición de documento
          // y se verifica si hay un document subject actualizado
          // Se rompe cuando algún document subject no existe,
          // lo que indica que no está actualizado.
          documentDefs?.some((dd: DocumentDefSchema) => {
            const ds =
              documentSubjects?.filter(
                (d) =>
                  (d.driver as DriverSchema)?.id === driver?.id &&
                  (d.document_def as DocumentDefSchema)?.id === dd?.id,
              )[0] ?? null;

            // Si existe un document subject es porque es un documento actualizado
            // Se mantiene como actualizada la doc del afiliado y se va a verificar el
            // próximo documento, si hay.
            // Si no existe el document subject es porque no es un documento actualizado
            // No se buscan más documentos ya que aquí importa es saber si toda la
            // documentación está actualizada
            driverDocStatus.docUpdated = ds ? true : false;
            return driverDocStatus.docUpdated === false;
          });
          ret.push(driverDocStatus);
        });
        return ret;
      }),
    );
  }

  create(
    documentSubject: DocumentSubjectSchema,
  ): Observable<DocumentSubjectSchema> {
    return this.http.post<DocumentSubjectSchema>(
      `${this.API_URL}/document-subjects`,
      documentSubject,
    );
  }

  createWithFormData(formData: FormData): Observable<DocumentSubjectSchema> {
    return this.http.post<DocumentSubjectSchema>(
      `${this.API_URL}/document-subjects`,
      formData,
    );
  }
  updateWithFormData(
    id: string,
    formData: FormData,
  ): Observable<DocumentSubjectSchema> {
    return this.http.put<DocumentSubjectSchema>(
      `${this.API_URL}/document-subjects/${id}`,
      formData,
    );
  }

  updateStatus(
    id: string,
    status: DocumentSubjectStatusEnum,
  ): Observable<DocumentSubjectStatusEnum> {
    return this.http.put<DocumentSubjectStatusEnum>(
      `${this.API_URL}/document-subjects/${id}`,
      { status: DocumentSubjectStatusEnum[status] },
    );
  }

  delete(id: string): Observable<DocumentSubjectSchema> {
    return this.http.delete<DocumentSubjectSchema>(
      `${this.API_URL}/document-subjects/${id}`,
    );
  }

  toDataTable(
    documentSubject: Array<DocumentSubjectSchema>,
  ): Array<IDocumentDefReviewDatatable> {
    return documentSubject.map((d) => ({
      name: d.affiliate
        ? (d.affiliate as AffiliateSchema).company_name
          ? `${(d.affiliate as AffiliateSchema).company_name}`
          : `${(d.affiliate as AffiliateSchema).firstname} ${
              (d.affiliate as AffiliateSchema).lastname
            }`
        : d.driver
        ? `${(d.driver as DriverSchema).firstname} ${
            (d.driver as DriverSchema).lastname
          }`
        : (d.vehicle as VehicleSchema).plate,
      document_type: (d.document_def as DocumentDefSchema).name,
      start_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        d.start_validity,
      ),
      end_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        d.end_validity,
      ),

      status: DocumentSubjectStatusTextEnum[d.status],
      id: d?.id,
      document_file_url: d?.file?.url ?? '',
    }));
  }

  getTableHead(): Observable<string[]> {
    return of([
      'Nombre',
      'Tipo de Documento',
      'Inicio de Vigencia',
      'Fin de Vigencia',
      'Estado',
    ]);
  }

  getTableFilter(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'affiliate.firstname_contains',
        field: 'Nombres',
        default: false,
      },
      {
        APIField: 'affiliate.lastname_contains',
        field: 'Apellidos',
        default: false,
      },
      {
        APIField: 'document_def.name_contains',
        field: 'Tipo de Documento',
        default: true,
      },
      // {
      //   APIField: 'start_validity_contains',
      //   field: 'Inicio de Vigencia',
      //   default: false,
      // },
      // {
      //   APIField: 'end_validity_contains',
      //   field: 'Fin de Vigencia',
      //   default: false,
      // },
    ]);
  }

  getTableHeadVehicles(): Observable<string[]> {
    return of([
      'Placa',
      'Tipo de Documento',
      'Inicio de Vigencia',
      'Fin de Vigencia',
      'Estado',
    ]);
  }

  getTableFilterVehicles(): Observable<IFilterDataTable[]> {
    return of([
      {
        APIField: 'vehicle.plate_contains',
        field: 'Placa',
        default: false,
      },
      {
        APIField: 'document_def.name_contains',
        field: 'Tipo de Documento',
        default: true,
      },
    ]);
  }

  getTableHeadAffiliateAlert(): Observable<string[]> {
    return of([
      'Nombre',
      'Identificación',
      'Tipo de Documento',
      'Inicio de Vigencia',
      'Fin de Vigencia',
      'Vencimiento',
    ]);
  }

  getTableHeadVehicleAlert(): Observable<string[]> {
    return of([
      'Placa',
      'Afiliado',
      'Tipo de Documento',
      'Inicio de Vigencia',
      'Fin de Vigencia',
      'Vencimiento',
    ]);
  }

  getTableHeadDriverAlert(): Observable<string[]> {
    return of([
      'Nombre',
      'Identificación',
      'Tipo de Documento',
      'Inicio de Vigencia',
      'Fin de Vigencia',
      'Vencimiento',
    ]);
  }

  getTableHeadCompanyAlert(): Observable<string[]> {
    return of([
      'Nombre',
      'Nit',
      'Tipo de Documento',
      'Inicio de Vigencia',
      'Fin de Vigencia',
      'Vencimiento',
    ]);
  }

  toDataTableAffiliateAlert(
    documentSubject: Array<DocumentSubjectSchema>,
  ): Array<IDocumentSubjectAffiliateAlertDatatable> {
    return documentSubject.map((d) => ({
      name: (d.affiliate as AffiliateSchema)?.company_name
        ? `${(d.affiliate as AffiliateSchema)?.company_name}`
        : `${(d.affiliate as AffiliateSchema)?.firstname} ${
            (d.affiliate as AffiliateSchema)?.lastname
          }`,
      identification_number: (d.affiliate as AffiliateSchema)?.document_number,
      document_type: (d.document_def as DocumentDefSchema)?.name,
      start_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        d?.start_validity,
      ),
      end_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        d?.end_validity,
      ),
      status: this.statusAlert(
        d?.end_validity ? this.dateTimeService.getDate(d?.end_validity) : null,
      ),
      affiliate_id: (d.affiliate as AffiliateSchema)?.id,
      document_def_id: (d?.document_def as DocumentDefSchema)?.id,
      document_file_url: d?.file?.url ?? '',
      id: d?.id,
    }));
  }

  toDataTableVehicleAlert(
    documentSubject: Array<DocumentSubjectSchema>,
  ): Array<IDocumentSubjectVehicleAlertDatatable> {
    return documentSubject.map((d) => ({
      plate: (d?.vehicle as VehicleSchema)?.plate,
      affiliate: ((d?.vehicle as VehicleSchema).affiliate as AffiliateSchema)
        ?.company_name
        ? `${
            ((d?.vehicle as VehicleSchema).affiliate as AffiliateSchema)
              ?.company_name
          }`
        : `${
            ((d?.vehicle as VehicleSchema).affiliate as AffiliateSchema)
              ?.firstname
          } ${
            ((d?.vehicle as VehicleSchema).affiliate as AffiliateSchema)
              ?.lastname
          }`,
      document_type: (d.document_def as DocumentDefSchema)?.name,
      start_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        d?.start_validity,
      ),
      end_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        d?.end_validity,
      ),
      status: this.statusAlert(
        d?.end_validity ? this.dateTimeService.getDate(d?.end_validity) : null,
      ),
      vehicle_id: (d.vehicle as VehicleSchema)?.id,
      document_def_id: (d?.document_def as DocumentDefSchema)?.id,
      document_file_url: d?.file?.url ?? '',
      id: d?.id,
    }));
  }

  toDataTableDriverAlert(
    documentSubject: Array<DocumentSubjectSchema>,
  ): Array<IDocumentSubjectDriverAlertDatatable> {
    return documentSubject.map((d) => ({
      name: `${(d.driver as DriverSchema)?.firstname} ${
        (d.driver as DriverSchema)?.lastname
      }`,
      identification_number: (d.driver as DriverSchema)?.document_number,
      document_type: (d.document_def as DocumentDefSchema)?.name,
      start_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        d?.start_validity,
      ),
      end_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        d?.end_validity,
      ),
      status: this.statusAlert(
        d?.end_validity ? this.dateTimeService.getDate(d?.end_validity) : null,
      ),
      driver_id: (d.driver as DriverSchema)?.id,
      document_def_id: (d?.document_def as DocumentDefSchema)?.id,
      document_file_url: d?.file?.url ?? '',
      id: d?.id,
    }));
  }

  toDataTableCompanyAlert(
    documentSubject: Array<DocumentSubjectSchema>,
  ): Array<IDocumentSubjectCompanyAlertDatatable> {
    return documentSubject.map((d) => ({
      name: `${(d.company as CompanySchema)?.name}`,
      nit: (d.company as CompanySchema)?.nit,
      document_type: (d.document_def as DocumentDefSchema)?.name,
      start_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        d?.start_validity,
      ),
      end_date: this.dateTimeService.getDataTableCellDateFromAPIDate(
        d?.end_validity,
      ),
      status: this.statusAlert(
        d?.end_validity ? this.dateTimeService.getDate(d?.end_validity) : null,
      ),
      company_id: (d.company as CompanySchema)?.id,
      document_def_id: (d?.document_def as DocumentDefSchema)?.id,
      document_file_url: d?.file?.url ?? '',
      id: d?.id,
    }));
  }

  private statusAlert(date: Date | null): string {
    let ret = '';
    const date1 = date;
    const date2 = this.dateTimeService.getNow();
    const qtdays = this.countdownDays(date);

    if (!date || (date && date1 < date2)) {
      ret =
        '<span class="badge rounded-pill bg-danger text-white">Desactualizado</span>';
    } else if (qtdays > '1') {
      ret = `<span class="badge rounded-pill bg-warning text-white">Vence en ${this.countdownDays(
        date,
      )} días</span>`;
    } else if (qtdays === '1') {
      ret = `<span class="badge rounded-pill bg-warning text-white">Vence en ${this.countdownDays(
        date,
      )} día</span>`;
    } else if (qtdays === '0') {
      ret = `<span class="badge rounded-pill bg-warning text-white">Vence en menos de 24 horas</span>`;
    }

    return ret;
  }

  private countdownDays(date: Date): string {
    let qtdays = '';

    const dateN = moment(new Date()).format('YYYY-MM-DD');
    const date1 = moment(date);
    const date2 = moment(dateN);
    if (date && date1 >= date2) {
      const calculateDays = Math.abs(date1.diff(date2, 'days'));
      qtdays = calculateDays.toString();
    }

    return qtdays;
  }
}
