import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ngbDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    const zeroDay = date?.day < 10 ? '0' : '';
    const zeroMonth = date?.month < 10 ? '0' : '';
    return date
      ? `${zeroDay}${date.day}${this.DELIMITER}${zeroMonth}${date.month}${this.DELIMITER}${date.year}`
      : '';
  }
}
