export interface IPermissionModule {
  id: number;
  name: string;
  description: string;
  permission_required: number;
  endpoints_required: IPermissionEdnpoint[];
  visible: boolean;
  father: null | number;
  children: IPermissionModule[];
}

export interface IPermissionEdnpoint {
  module: string;
  controller: string;
  action: string;
}
